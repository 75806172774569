/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER,
  FETCH_NF_AUTHREP_AUTHORIZED_REP,
} from './actionTypes';

import {
  fetchNfAuthrepExistsPendingAppForUserUtil,
  fetchNfAuthrepAuthorizedRepUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFApplicationPending slice of the store.
 *
 * @namespace NFApplicationPendingActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_authrep/exists_pending_app_for_user
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFApplicationPendingActions
   */
  static fetchNfAuthrepExistsPendingAppForUser = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfAuthrepExistsPendingAppForUserUtil,
      FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfAuthrepExistsPendingAppForUser.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFApplicationPendingActions
   */
  static resetFetchNfAuthrepExistsPendingAppForUser = (dispatch, data) => {
    const json = {
      type: FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfAuthrepExistsPendingAppForUser.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFApplicationPendingActions
   */
  static resetFetchNfAuthrepExistsPendingAppForUserError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_authrep/authorized_rep
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFApplicationPendingActions
   */
  static fetchNfAuthrepAuthorizedRep = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfAuthrepAuthorizedRepUtil,
      FETCH_NF_AUTHREP_AUTHORIZED_REP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfAuthrepAuthorizedRep.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFApplicationPendingActions
   */
  static resetFetchNfAuthrepAuthorizedRep = (dispatch, data) => {
    const json = {
      type: FETCH_NF_AUTHREP_AUTHORIZED_REP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfAuthrepAuthorizedRep.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFApplicationPendingActions
   */
  static resetFetchNfAuthrepAuthorizedRepError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_AUTHREP_AUTHORIZED_REP.failureAction);
  };
}
