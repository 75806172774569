/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot re-loadable.
 */

import SampleModuleReducers from './SampleModule/reducers';

import NFProfileReducers from './NFProfile/reducers';

import NFUnifiedMotivationReducers from './NFUnifiedMotivation/reducers';

import NFMotivationStandaloneRDIPReducers from './NFMotivationStandaloneRDIP/reducers';

import NFCaseworkerInformationReducers from './NFCaseworkerInformation/reducers';

import NFPresumptiveMotivationReducers from './NFPresumptiveMotivation/reducers';

import NFCocMotivationReducers from './NFCocMotivation/reducers';

import NFMFASessionReducers from './NFMFASession/reducers';

import NFDocUploadBannerMsgsReducers from './NFDocUploadBannerMsgs/reducers';

import NFApplicationPendingReducers from './NFApplicationPending/reducers';

import NFeNoticesReducers from './NFeNotices/reducers';

import NFDocumentUploadReducers from './NFDocumentUpload/reducers';

import NFMassCommunicationReducers from './NFMassCommunication/reducers';

import NFYourInformationReducers from './NFYourInformation/reducers';

import NFCovidTestingMotivationReducers from './NFCovidTestingMotivation/reducers';

import NFLIEAPModuleReducers from './NFLIEAPModule/reducers';

import NFDSNAPReducers from './NFDSNAP/reducers';

import NFEnergyReducers from './NFEnergy/reducers';

import NFDeemedNewBornMotivationReducers from './NFDeemedNewBornMotivation/reducers';

import NFOrganizationVoterRegistrationReducers from './NFOrganizationVoterRegistration/reducers';

import NFSecureMessagesInfoReducers from './NFSecureMessagesInfo/reducers';

import NFSecuredMessagesReducers from './NFSecuredMessages/reducers';

import NFFnsOrSnapRecertMotivationReducers from './NFFnsOrSnapRecertMotivation/reducers';

import NFSNAPRecertReducers from './NFSNAPRecert/reducers';

import NFFNSRenewBenefitsReducers from './NFFNSRenewBenefits/reducers';

import NFAppealsRequestReducers from './NFAppealsRequest/reducers';

/**
 * Creates the main reducer which combines all the reducers from the modules
 * and any provided reducers supplied from the wrapping application.
 *
 * Provided reducers are ignored where there is a clash in naming.
 *
 * @namespace UAReduxReducers
 */
export default {
  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UASampleModule: SampleModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFProfile: NFProfileReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFUnifiedMotivation: NFUnifiedMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFMotivationStandaloneRDIP: NFMotivationStandaloneRDIPReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFCaseworkerInformation: NFCaseworkerInformationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFPresumptiveMotivation: NFPresumptiveMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFCocMotivation: NFCocMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFMFASession: NFMFASessionReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFDocUploadBannerMsgs: NFDocUploadBannerMsgsReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFApplicationPending: NFApplicationPendingReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFeNotices: NFeNoticesReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFDocumentUpload: NFDocumentUploadReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFMassCommunication: NFMassCommunicationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFYourInformation: NFYourInformationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFCovidTestingMotivation: NFCovidTestingMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFLIEAPModule: NFLIEAPModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFDSNAP: NFDSNAPReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFEnergy: NFEnergyReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFDeemedNewBornMotivation: NFDeemedNewBornMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFOrganizationVoterRegistration: NFOrganizationVoterRegistrationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFSecureMessagesInfo: NFSecureMessagesInfoReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFSecuredMessages: NFSecuredMessagesReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFFnsOrSnapRecertMotivation: NFFnsOrSnapRecertMotivationReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFSNAPRecert: NFSNAPRecertReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFFNSRenewBenefits: NFFNSRenewBenefitsReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UANFAppealsRequest: NFAppealsRequestReducers.reducers,
};
