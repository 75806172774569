/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFDocumentUpload.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS`
);

const FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS`
);

const GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID`
);

const POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT`
);

const POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS`
);

const FETCH_NF_DOC_UPLOAD_IS_USER_LINKED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOC_UPLOAD_IS_USER_LINKED`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS`
);

const FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS`
);

const POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS`
);

const FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS`
);

const FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDOCUMENTUPLOAD/FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES`
);

export {
  FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS,
  FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS,
  GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS,
  FETCH_NF_DOC_UPLOAD_IS_USER_LINKED,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES,
};
