/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD,
  POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM,
  POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION,
  POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION,
} from './actionTypes';

/**
 * Updates the details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMassCommunicationViewPrefMethodReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD);

/**
 * Updates the indicator of creation of details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMassCommunicationViewPrefMethodReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMassCommunicationViewPrefMethodErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD);

/**
 * Updates the details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMassCommunicationCreateMassCommReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM);

/**
 * Updates the indicator of creation of details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMassCommunicationCreateMassCommReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMassCommunicationCreateMassCommErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM);

/**
 * Updates the details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMassCommunicationUpdateMassCommunicationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION);

/**
 * Updates the indicator of creation of details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMassCommunicationUpdateMassCommunicationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMassCommunicationUpdateMassCommunicationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION
  );

/**
 * Updates the details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMassCommunicationCancelMassCommunicationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION);

/**
 * Updates the indicator of creation of details in the 'NFMassCommunication' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMassCommunicationCancelMassCommunicationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMassCommunicationCancelMassCommunicationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION
  );

const reducers = combineReducers({
  postNfMassCommunicationViewPrefMethod: postNfMassCommunicationViewPrefMethodReducer,

  isFetchingPostNfMassCommunicationViewPrefMethod: isFetchingPostNfMassCommunicationViewPrefMethodReducer,
  postNfMassCommunicationViewPrefMethodError: postNfMassCommunicationViewPrefMethodErrorReducer,

  postNfMassCommunicationCreateMassComm: postNfMassCommunicationCreateMassCommReducer,

  isFetchingPostNfMassCommunicationCreateMassComm: isFetchingPostNfMassCommunicationCreateMassCommReducer,
  postNfMassCommunicationCreateMassCommError: postNfMassCommunicationCreateMassCommErrorReducer,

  postNfMassCommunicationUpdateMassCommunication: postNfMassCommunicationUpdateMassCommunicationReducer,

  isFetchingPostNfMassCommunicationUpdateMassCommunication: isFetchingPostNfMassCommunicationUpdateMassCommunicationReducer,
  postNfMassCommunicationUpdateMassCommunicationError: postNfMassCommunicationUpdateMassCommunicationErrorReducer,

  postNfMassCommunicationCancelMassCommunication: postNfMassCommunicationCancelMassCommunicationReducer,

  isFetchingPostNfMassCommunicationCancelMassCommunication: isFetchingPostNfMassCommunicationCancelMassCommunicationReducer,
  postNfMassCommunicationCancelMassCommunicationError: postNfMassCommunicationCancelMassCommunicationErrorReducer,
});

export default { reducers };
