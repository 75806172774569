export default {
  defaultLocale: 'en',
  locales: [
    {
      locale: 'en',
      displayName: 'English',
      localeData: require('react-intl/locale-data/en'),
      messages: {
        ...require('../locale/messages_en')
      },
    },
    {
      locale: 'es',
      displayName: 'Español',
      localeData: require('react-intl/locale-data/es'),
      messages: {
        ...require('../locale/messages_es')
      },
    },
  ],
};
