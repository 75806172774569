/*
 * Licensed Materials - Property of IBM
 *
 * Copyright IBM Corporation 2018-2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from '@govhhs/govhhs-design-system-react';
import {
  ScrollToTop,
  SessionTimeout,
  ErrorBoundary,
  Toaster,
  ConnectivityHandler
} from '@spm/universal-access-ui';
import NFApplicationHeaderComponent from './features/NFApplicationHeader/components/NFApplicationHeaderComponent';
import NFApplicationHeader from './features/NFApplicationHeader';
import routes from './routes';
import ReduxInit from './redux/ReduxInit';
import IntlInit from './intl/IntlInit';
import DOMPurify from '../node_modules/dompurify';
import 'cleave.js/dist/addons/cleave-phone.us';
import NFSSOVerifier from './features/NFMFASession/components/NFSSOVerifier';
import NFApplicationFooterContainer from './features/NFApplicationFooter/NFApplicationFooterContainer';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });
// svemulapalli - use DOMPurify to prevent html sanitization of the 'target' attribute in link.

/**
 * App component entry point.
 */
const App = () => {
  return (
    <ReduxInit>
      <IntlInit>
        <Router basename={process.env.PUBLIC_URL}>
          <NFSSOVerifier
            placeholder={
              <NFApplicationHeaderComponent
                isALinkedUser={() => false}
                isAppealsEnabled={false}
                isEmpty
              />
            }
          >
            <ScrollToTop>
              <ErrorBoundary
                footer={<NFApplicationFooterContainer />}
                header={<NFApplicationHeaderComponent hasErrorBeenCaught />}
                isHeaderBoundary
              >
                <ConnectivityHandler>
                  <NFApplicationHeader />
                  <ErrorBoundary>
                    <SessionTimeout />
                    <Toaster />
                    <Main
                      className="wds-u-bg--page wds-u-text-word-break"
                      id="maincontent"
                      pushFooter
                    >
                      {routes}
                    </Main>
                  </ErrorBoundary>
                  <NFApplicationFooterContainer />
                </ConnectivityHandler>
              </ErrorBoundary>
            </ScrollToTop>
          </NFSSOVerifier>
        </Router>
      </IntlInit>
    </ReduxInit>
  );
};

export default App;
