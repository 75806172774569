/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets UAIntakeApplicationType from api:
 * /v1/ua/nf_citizen_workspace/apply_lieap
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCitizenWorkspaceApplyLieapUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_workspace/apply_lieap`,
    models.UAIntakeApplicationType
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_citizen_workspace/start_lieap
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfCitizenWorkspaceStartLieapUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_citizen_workspace/start_lieap`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFStartLIEAPIEGResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets UAIntakeApplicationType from api:
 * /v1/ua/form_details/{application_form_id}
 *
 * @param {string} application_form_id.
 * @param {string} isPartialApplication.
 * @param {any} callback function to call once this function has finished
 */
const getFormDetailsByApplicationFormIdUtil = (
  application_form_id,
  isPartialApplication,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/form_details/${application_form_id}?isPartialApplication=${isPartialApplication}`,
    models.UAIntakeApplicationType
  );
};

/**
 * Gets UADraftApplicationList from api:
 * /v1/ua/draft_applications
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchDraftApplicationsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/draft_applications`,
    models.UADraftApplicationList
  );
};

export {
  fetchNfCitizenWorkspaceApplyLieapUtil,
  postNfCitizenWorkspaceStartLieapUtil,
  getFormDetailsByApplicationFormIdUtil,
  fetchDraftApplicationsUtil,
};
