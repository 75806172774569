/*
 * NFApplicationFooter Messages
 *
 * This contains all the text for the NFApplicationFooter component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  NFApplicationFooter_FAQ_Label: {
    id: 'NFApplicationFooter_FAQ_Label',
    defaultMessage: 'FAQ',
  },
  NFApplicationFooter_enrollmentBroker_Label: {
    id: 'NFApplicationFooter_enrollmentBroker_Label',
    defaultMessage: 'Enrollment Broker',
  },
  NFApplicationFooter_findAnOffice_Label: {
    id: 'NFApplicationFooter_findAnOffice_Label',
    defaultMessage: 'Find a DSS office',
  },
  NFApplicationFooter_Dhhs_Label: {
    id: 'NFApplicationFooter_Dhhs_Label',
    defaultMessage: 'DHHS',
  },
  NFApplicationFooter_healthCare_Label: {
    id: 'NFApplicationFooter_healthCare_Label',
    defaultMessage: 'Healthcare.gov',
  },
  NFApplicationFooter_Disclaimer_Label: {
    id: 'NFApplicationFooter_Disclaimer_Label',
    defaultMessage: 'Disclaimer',
  },
  NFApplicationFooter_BuildVersion_Label: {
    id: 'NFApplicationFooter_BuildVersion_Label',
    defaultMessage: 'ePASS RWA Version:',
  },
});
