/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFMotivationTypeResponse
 *
 * @class NFMotivationTypeResponse
 */
class NFMotivationTypeResponse {
  /**
   * Creates an instance of NFMotivationTypeResponse.
   * @param {*} json
   * @memberof NFMotivationTypeResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationName = json.motivationName;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKey
 *
 * @class NewUAMotivationIEGKey
 */
class NewUAMotivationIEGKey {
  /**
   * Creates an instance of NewUAMotivationIEGKey.
   * @param {*} json
   * @memberof NewUAMotivationIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewNFMotivationFinishResponseResponse
 *
 * @class NewNFMotivationFinishResponseResponse
 */
class NewNFMotivationFinishResponseResponse {
  /**
   * Creates an instance of NewNFMotivationFinishResponseResponse.
   * @param {*} json
   * @memberof NewNFMotivationFinishResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;

      // string
      this.motivationID = json.motivationID;

      // boolean
      this.isARCOC = json.isARCOC;

      // boolean
      this.hasAuthRep = json.hasAuthRep;
    }
  }
}

export default {
  NFMotivationTypeResponse,

  NewUAMotivationIEGKey,

  NewNFMotivationFinishResponseResponse,
};
