const ID = {
  NFAPPLICATION_HEADER_SIGNUP_BUTTON_ID: 'nf-application-header-signup-button-id',
  NFAPPLICATION_HEADER_LOGIN_BUTTON_ID: 'nf-application-header-login-button-id',
  NFAPPLICATION_HEADER_MENU_LOGOUT_BUTTON_ID:
    'nf-application-header-overflow-menu-logout-button-id',
  NFAPPLICATION_HEADER_EPASS_LINK_ID: 'nf-application-header-link-id',
  NFAPPLICATION_HEADER_EPASS_LOGO_ID: 'nf-application-header-epass-logo-id',
};

const NAVIGATION_HEADER_TABS = {
  NFAPPLICATION_HEADER_YOUR_ACCOUNT: {
    NAME: 'NFAPPLICATION_HEADER_YOUR_ACCOUNT',
    ID: 'navigation-your-account',
  },
  NFAPPLICATION_HEADER_HOME: { NAME: 'NFAPPLICATION_HEADER_HOME', ID: 'navigation-home' },
  NFAPPLICATION_HEADER_APPLY: { NAME: 'NFAPPLICATION_HEADER_APPLY', ID: 'navigation-apply' },
  NFAPPLICATION_HEADER_NFYOURAPPLICATIONS: {
    NAME: 'NFAPPLICATION_HEADER_NFYOURAPPLICATIONS',
    ID: 'navigation-your-application',
  },
  NFAPPLICATION_HEADER_DASHBOARD: {
    NAME: 'NFAPPLICATION_HEADER_DASHBOARD',
    ID: 'navigation-dashboard',
  },
  NFAPPLICATION_HEADER_NFACCOUNTSETTINGS: {
    NAME: 'NFAPPLICATION_HEADER_NFACCOUNTSETTINGS',
    ID: 'navigation-profile',
  },
  NFAPPLICATION_HEADER_MY_INFORMATION: {
    NAME: 'NFAPPLICATION_HEADER_MY_INFORMATION',
    ID: 'navigation-my-information',
  },
  NFAPPLICATION_HEADER_NFYOURREFERRALS: {
    NAME: 'NFAPPLICATION_HEADER_NFYOURREFERRALS',
    ID: 'navigation-your-referrals',
  },
  NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS: {
    NAME: 'NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS',
    ID: 'navigation-archived-referrals',
  },
};

/* eslint-disable import/prefer-default-export */
export { ID, NAVIGATION_HEADER_TABS };
