/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the NFUAOnlineAppealRequest
 *
 * @class NFUAOnlineAppealRequest
 */
class NFUAOnlineAppealRequest {
  /**
   * Creates an instance of NFUAOnlineAppealRequest.
   * @param {*} json
   * @memberof NFUAOnlineAppealRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.primaryAppellantName = json.primaryAppellantName;

      // object - CodeTableType
      if (json.appealRequestStatus) {
        this.appealRequestStatus = new CodeTableType(json.appealRequestStatus);
      }

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.onlineAppealRequestId = json.onlineAppealRequestId;

      // object - CodeTableType
      if (json.nfAppealedProgramCode) {
        this.nfAppealedProgramCode = new CodeTableType(json.nfAppealedProgramCode);
      }
    }
  }
}

/**
 * Models the NFUAOnlineAppealRequests
 *
 * @class NFUAOnlineAppealRequests
 */
class NFUAOnlineAppealRequests {
  /**
   * Creates an instance of NFUAOnlineAppealRequests.
   * @param {*} json
   * @memberof NFUAOnlineAppealRequests
   */
  constructor(json = null) {
    if (json) {
      // array - NFUAOnlineAppealRequest
      if (json.requests) {
        this.requests = json.requests.map(object => new NFUAOnlineAppealRequest(object));
      } else if (json.constructor === Array) {
        this.requests = json.map(object => new NFUAOnlineAppealRequest(object));
      }
    }
  }
}

export default {
  CodeTableType,

  NFUAOnlineAppealRequest,

  NFUAOnlineAppealRequests,
};
