/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewNFExternalUserSessionUUIDKey
 *
 * @class NewNFExternalUserSessionUUIDKey
 */
class NewNFExternalUserSessionUUIDKey {
  /**
   * Creates an instance of NewNFExternalUserSessionUUIDKey.
   * @param {*} json
   * @memberof NewNFExternalUserSessionUUIDKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfUUID = json.nfUUID;

      // string
      this.currentDateTime = json.currentDateTime;
    }
  }
}

/**
 * Models the NewNFMFAQuestionResponse
 *
 * @class NewNFMFAQuestionResponse
 */
class NewNFMFAQuestionResponse {
  /**
   * Creates an instance of NewNFMFAQuestionResponse.
   * @param {*} json
   * @memberof NewNFMFAQuestionResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfValue = json.nfValue;

      // string
      this.nfMessage = json.nfMessage;
    }
  }
}

/**
 * Models the NewNFMFAQuestionListResponse
 *
 * @class NewNFMFAQuestionListResponse
 */
class NewNFMFAQuestionListResponse {
  /**
   * Creates an instance of NewNFMFAQuestionListResponse.
   * @param {*} json
   * @memberof NewNFMFAQuestionListResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFMFAQuestionResponse
      if (json.data) {
        this.data = json.data.map(object => new NewNFMFAQuestionResponse(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new NewNFMFAQuestionResponse(object));
      }
    }
  }
}

/**
 * Models the NewNFMFACodeRequest
 *
 * @class NewNFMFACodeRequest
 */
class NewNFMFACodeRequest {
  /**
   * Creates an instance of NewNFMFACodeRequest.
   * @param {*} json
   * @memberof NewNFMFACodeRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfUUID = json.nfUUID;

      // string
      this.nfCodeSelection = json.nfCodeSelection;
    }
  }
}

/**
 * Models the NewNFMFACodeResponseResponse
 *
 * @class NewNFMFACodeResponseResponse
 */
class NewNFMFACodeResponseResponse {
  /**
   * Creates an instance of NewNFMFACodeResponseResponse.
   * @param {*} json
   * @memberof NewNFMFACodeResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfIsSentInd = json.nfIsSentInd;

      // string
      this.nfValue = json.nfValue;
    }
  }
}

export default {
  NewNFExternalUserSessionUUIDKey,

  NewNFMFAQuestionResponse,

  NewNFMFAQuestionListResponse,

  NewNFMFACodeRequest,

  NewNFMFACodeResponseResponse,
};
