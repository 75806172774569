import { NAVIGATION_HEADER_TABS } from '../constants';
import PATHS from '../../../paths';

/**
 * Returns true if the selected navigation tab should be active based on the
 * current url pathname. The url pathname string includes the leading forward
 * slash. e.g. /account
 *
 * @param {string} urlPathname current url pathname.
 * @param {string} navigationTabName current navigation tab selected.
 * @returns true if navigation tab should be active based on url pathname.
 */
const isTabActiveForUrlPathname = (urlPathname, navigationTabName) => {
  const {
    NFAPPLICATION_HEADER_HOME,
    NFAPPLICATION_HEADER_APPLY,
    NFAPPLICATION_HEADER_YOUR_ACCOUNT,
    NFAPPLICATION_HEADER_DASHBOARD,
    NFAPPLICATION_HEADER_NFACCOUNTSETTINGS,
    NFAPPLICATION_HEADER_NFYOURAPPLICATIONS,
    NFAPPLICATION_HEADER_NFYOURREFERRALS,
    NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS,
    NFAPPLICATION_HEADER_MY_INFORMATION,
  } = NAVIGATION_HEADER_TABS;

  switch (navigationTabName) {
    case NFAPPLICATION_HEADER_YOUR_ACCOUNT.NAME:
      return (
        urlPathname === PATHS.ACCOUNT ||
        urlPathname === PATHS.APPLY ||
        urlPathname === PATHS.NFACCOUNTSETTINGS ||
        urlPathname === PATHS.MY_INFO ||
        urlPathname === PATHS.NFYOURAPPLICATIONS ||
        urlPathname === PATHS.NFYOURREFERRALS ||
        urlPathname === PATHS.NFARCHIVEDREFERRALS ||
        urlPathname === PATHS.NFUPDATE_SECONDARY_CONTACT ||
        urlPathname === PATHS.NFADD_SECONDARY_CONTACT ||
        urlPathname === PATHS.NFUPDATE_PRIMARY_CONTACT ||
        urlPathname === PATHS.NFCONFIRM_PRIMARY_SECURITY_CODE ||
        urlPathname === PATHS.NFCONFIRM_SECONDARY_SECURITY_CODE ||
        urlPathname === PATHS.NFNCDHHS_ALERTS_OPTIN ||
        urlPathname === PATHS.NFAPPEALS.ROOT ||
        urlPathname === PATHS.NFAPPEALS.OVERVIEW ||
        urlPathname === PATHS.NFAPPEALS.SUBMITTED ||
        urlPathname === PATHS.NFDOCUMENTTOUPLOAD ||
        urlPathname === PATHS.NFCASEWORKERINFO ||
        urlPathname === PATHS.NFSECUREMESSAGES ||
        urlPathname === PATHS.NFENOTICES ||
        urlPathname === PATHS.NFENOTICESQANDA ||
        (urlPathname && urlPathname.indexOf(PATHS.NFENOTICESLISTCOMM.HOME) === 0)
      );
    case NFAPPLICATION_HEADER_HOME.NAME:
      return urlPathname === PATHS.HOME;
    case NFAPPLICATION_HEADER_APPLY.NAME:
      return urlPathname === PATHS.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.OVERVIEW;
    case NFAPPLICATION_HEADER_DASHBOARD.NAME:
      return (
        urlPathname === PATHS.ACCOUNT ||
        urlPathname === PATHS.NFDOCUMENTTOUPLOAD ||
        urlPathname === PATHS.NFENOTICES ||
        urlPathname === PATHS.NFENOTICESQANDA ||
        (urlPathname && urlPathname.indexOf(PATHS.NFENOTICESLISTCOMM.HOME) === 0) ||
        urlPathname === PATHS.NFAPPEALS.ROOT ||
        urlPathname === PATHS.NFAPPEALS.SUBMITTED ||
        urlPathname === PATHS.NFCASEWORKERINFO ||
        urlPathname === PATHS.NFSECUREMESSAGES 
      );
    case NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.NAME:
      return (
        urlPathname === PATHS.NFACCOUNTSETTINGS ||
        urlPathname === PATHS.NFUPDATE_PRIMARY_CONTACT ||
        urlPathname === PATHS.NFCONFIRM_PRIMARY_SECURITY_CODE ||
        urlPathname === PATHS.NFCONFIRM_SECONDARY_SECURITY_CODE ||
        urlPathname === PATHS.NFUPDATE_SECONDARY_CONTACT ||
        urlPathname === PATHS.NFADD_SECONDARY_CONTACT ||
        urlPathname === PATHS.NFNCDHHS_ALERTS_OPTIN
      );
    case NFAPPLICATION_HEADER_NFYOURAPPLICATIONS.NAME:
      return urlPathname === PATHS.NFYOURAPPLICATIONS;
    case NFAPPLICATION_HEADER_NFYOURREFERRALS.NAME:
      return urlPathname === PATHS.NFYOURREFERRALS;
    case NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.NAME:
      return urlPathname === PATHS.NFARCHIVEDREFERRALS;
    case NFAPPLICATION_HEADER_MY_INFORMATION.NAME:
      return urlPathname === PATHS.MY_INFO;

    default:
      return false;
  }
};

/**
 * Gets the id of the active primary navigation tab.
 *
 * @param {string} urlPathname current url pathname.
 * @returns id of active primary navigation tab.
 */
const getActivePrimaryNavigationTabId = urlPathname => {
  const {
    NFAPPLICATION_HEADER_HOME,
    NFAPPLICATION_HEADER_APPLY,
    NFAPPLICATION_HEADER_DASHBOARD,
    NFAPPLICATION_HEADER_NFACCOUNTSETTINGS,
    NFAPPLICATION_HEADER_NFYOURAPPLICATIONS,
    NFAPPLICATION_HEADER_NFYOURREFERRALS,
    NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS,
    NFAPPLICATION_HEADER_MY_INFORMATION,
  } = NAVIGATION_HEADER_TABS;

  let result = NFAPPLICATION_HEADER_HOME.ID;
  if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_APPLY.NAME)) {
    result = NFAPPLICATION_HEADER_APPLY.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_DASHBOARD.NAME)) {
    result = NFAPPLICATION_HEADER_DASHBOARD.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.NAME)) {
    result = NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFYOURAPPLICATIONS.NAME)) {
    result = NFAPPLICATION_HEADER_NFYOURAPPLICATIONS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFYOURREFERRALS.NAME)) {
      result = NFAPPLICATION_HEADER_NFYOURREFERRALS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.NAME)) {
    result = NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_MY_INFORMATION.NAME)) {
    result = NFAPPLICATION_HEADER_MY_INFORMATION.ID;
  }
  return result;
};

/**
 * Gets the id of the active secondary navigation tab. If no tabs are active
 * returns string 'blank', reason is that the SecondaryNavigation 'current' prop
 * will default to the first child tab if null.
 *
 * @param {string} urlPathname current url pathname.
 * @returns id of active secondary navigation tab or 'blank' if no active tabs.
 */
const getActiveSecondaryNavigationTabId = urlPathname => {
  const {
    NFAPPLICATION_HEADER_HOME,
    NFAPPLICATION_HEADER_YOUR_ACCOUNT,
    NFAPPLICATION_HEADER_APPLY,
    NFAPPLICATION_HEADER_NFACCOUNTSETTINGS,
    NFAPPLICATION_HEADER_NFYOURREFERRALS,
    NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS,
  } = NAVIGATION_HEADER_TABS;

  let result = NFAPPLICATION_HEADER_HOME.ID;
  if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_YOUR_ACCOUNT.NAME)) {
    result = NFAPPLICATION_HEADER_YOUR_ACCOUNT.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_APPLY.NAME)) {
    result = NFAPPLICATION_HEADER_APPLY.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.NAME)) {
    result = NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.ID;
  }else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFYOURREFERRALS.NAME)) {
    result = NFAPPLICATION_HEADER_NFYOURREFERRALS.ID;
  }else if (isTabActiveForUrlPathname(urlPathname, NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.NAME)) {
    result = NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.ID;
  }
  return result;
};

export {
  isTabActiveForUrlPathname,
  getActivePrimaryNavigationTabId,
  getActiveSecondaryNavigationTabId,
};
