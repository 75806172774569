/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_RIDP_PROFILE_INFO,
  POST_NF_RIDP_UPDATE_PRI_CONTACT,
  POST_NF_RIDP_UPDATE_SEC_CONTACT,
  POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT,
  POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER,
  POST_NF_RIDP_REVOKE_ALL,
  GET_CODETABLES_BY_TABLE_NAME,
} from './actionTypes';

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfRidpProfileInfoReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_RIDP_PROFILE_INFO);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfRidpProfileInfoReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_RIDP_PROFILE_INFO);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfRidpProfileInfoErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_RIDP_PROFILE_INFO);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfRidpUpdatePriContactReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_RIDP_UPDATE_PRI_CONTACT);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfRidpUpdatePriContactReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_RIDP_UPDATE_PRI_CONTACT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfRidpUpdatePriContactErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_RIDP_UPDATE_PRI_CONTACT);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfRidpUpdateSecContactReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_RIDP_UPDATE_SEC_CONTACT);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfRidpUpdateSecContactReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_RIDP_UPDATE_SEC_CONTACT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfRidpUpdateSecContactErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_RIDP_UPDATE_SEC_CONTACT);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfRidpResendCodeUpdateContactReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfRidpResendCodeUpdateContactReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfRidpResendCodeUpdateContactErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfRidpUpdateConfirmationNumberReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfRidpUpdateConfirmationNumberReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfRidpUpdateConfirmationNumberErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfRidpRevokeAllReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_RIDP_REVOKE_ALL);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfRidpRevokeAllReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_RIDP_REVOKE_ALL);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfRidpRevokeAllErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_RIDP_REVOKE_ALL);

/**
 * Updates the details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getCodetablesByTableNameReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_CODETABLES_BY_TABLE_NAME);

/**
 * Updates the indicator of creation of details in the 'NFProfile' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetCodetablesByTableNameReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_CODETABLES_BY_TABLE_NAME);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getCodetablesByTableNameErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_CODETABLES_BY_TABLE_NAME);

const reducers = combineReducers({
  fetchNfRidpProfileInfo: fetchNfRidpProfileInfoReducer,

  isFetchingFetchNfRidpProfileInfo: isFetchingFetchNfRidpProfileInfoReducer,
  fetchNfRidpProfileInfoError: fetchNfRidpProfileInfoErrorReducer,

  postNfRidpUpdatePriContact: postNfRidpUpdatePriContactReducer,

  isFetchingPostNfRidpUpdatePriContact: isFetchingPostNfRidpUpdatePriContactReducer,
  postNfRidpUpdatePriContactError: postNfRidpUpdatePriContactErrorReducer,

  postNfRidpUpdateSecContact: postNfRidpUpdateSecContactReducer,

  isFetchingPostNfRidpUpdateSecContact: isFetchingPostNfRidpUpdateSecContactReducer,
  postNfRidpUpdateSecContactError: postNfRidpUpdateSecContactErrorReducer,

  postNfRidpResendCodeUpdateContact: postNfRidpResendCodeUpdateContactReducer,

  isFetchingPostNfRidpResendCodeUpdateContact: isFetchingPostNfRidpResendCodeUpdateContactReducer,
  postNfRidpResendCodeUpdateContactError: postNfRidpResendCodeUpdateContactErrorReducer,

  postNfRidpUpdateConfirmationNumber: postNfRidpUpdateConfirmationNumberReducer,

  isFetchingPostNfRidpUpdateConfirmationNumber: isFetchingPostNfRidpUpdateConfirmationNumberReducer,
  postNfRidpUpdateConfirmationNumberError: postNfRidpUpdateConfirmationNumberErrorReducer,

  postNfRidpRevokeAll: postNfRidpRevokeAllReducer,

  isFetchingPostNfRidpRevokeAll: isFetchingPostNfRidpRevokeAllReducer,
  postNfRidpRevokeAllError: postNfRidpRevokeAllErrorReducer,

  getCodetablesByTableName: getCodetablesByTableNameReducer,

  isFetchingGetCodetablesByTableName: isFetchingGetCodetablesByTableNameReducer,
  getCodetablesByTableNameError: getCodetablesByTableNameErrorReducer,
});

export default { reducers };
