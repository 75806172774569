/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_NF_COC_START_COC_SCRIPT,
  FETCH_NF_COC_APPLY_FOR_SNAP_RECERT,
  POST_NF_COC_FINISH_COC_RECERT,
  POST_NF_COC_SIGNANDSUBMIT_COC_RECERT,
  GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_LIST_SUBMITTED_RENEWALS,
} from './actionTypes';

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfCocStartCocScriptReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_COC_START_COC_SCRIPT);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfCocStartCocScriptReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_COC_START_COC_SCRIPT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfCocStartCocScriptErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_COC_START_COC_SCRIPT);

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocApplyForSnapRecertReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_RECERT);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocApplyForSnapRecertReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_RECERT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocApplyForSnapRecertErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_RECERT);

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfCocFinishCocRecertReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_COC_FINISH_COC_RECERT);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfCocFinishCocRecertReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_COC_FINISH_COC_RECERT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfCocFinishCocRecertErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_COC_FINISH_COC_RECERT);

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfCocSignandsubmitCocRecertReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_COC_SIGNANDSUBMIT_COC_RECERT);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfCocSignandsubmitCocRecertReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_COC_SIGNANDSUBMIT_COC_RECERT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfCocSignandsubmitCocRecertErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_COC_SIGNANDSUBMIT_COC_RECERT);

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfCocCocRecertConfirmationDetailsByCocInstanceIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfCocCocRecertConfirmationDetailsByCocInstanceIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfCocCocRecertConfirmationDetailsByCocInstanceIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID
  );

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationSetupStartMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationSetupStartMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationSetupStartMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocListSubmittedRenewalsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_LIST_SUBMITTED_RENEWALS);

/**
 * Updates the indicator of creation of details in the 'NFSNAPRecert' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocListSubmittedRenewalsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_LIST_SUBMITTED_RENEWALS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocListSubmittedRenewalsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_LIST_SUBMITTED_RENEWALS);

const reducers = combineReducers({
  postNfCocStartCocScript: postNfCocStartCocScriptReducer,

  isFetchingPostNfCocStartCocScript: isFetchingPostNfCocStartCocScriptReducer,
  postNfCocStartCocScriptError: postNfCocStartCocScriptErrorReducer,

  fetchNfCocApplyForSnapRecert: fetchNfCocApplyForSnapRecertReducer,

  isFetchingFetchNfCocApplyForSnapRecert: isFetchingFetchNfCocApplyForSnapRecertReducer,
  fetchNfCocApplyForSnapRecertError: fetchNfCocApplyForSnapRecertErrorReducer,

  postNfCocFinishCocRecert: postNfCocFinishCocRecertReducer,

  isFetchingPostNfCocFinishCocRecert: isFetchingPostNfCocFinishCocRecertReducer,
  postNfCocFinishCocRecertError: postNfCocFinishCocRecertErrorReducer,

  postNfCocSignandsubmitCocRecert: postNfCocSignandsubmitCocRecertReducer,

  isFetchingPostNfCocSignandsubmitCocRecert: isFetchingPostNfCocSignandsubmitCocRecertReducer,
  postNfCocSignandsubmitCocRecertError: postNfCocSignandsubmitCocRecertErrorReducer,

  getNfCocCocRecertConfirmationDetailsByCocInstanceID: getNfCocCocRecertConfirmationDetailsByCocInstanceIDReducer,

  isFetchingGetNfCocCocRecertConfirmationDetailsByCocInstanceID: isFetchingGetNfCocCocRecertConfirmationDetailsByCocInstanceIDReducer,
  getNfCocCocRecertConfirmationDetailsByCocInstanceIDError: getNfCocCocRecertConfirmationDetailsByCocInstanceIDErrorReducer,

  postNfMotivationSetupStartMotivation: postNfMotivationSetupStartMotivationReducer,

  isFetchingPostNfMotivationSetupStartMotivation: isFetchingPostNfMotivationSetupStartMotivationReducer,
  postNfMotivationSetupStartMotivationError: postNfMotivationSetupStartMotivationErrorReducer,

  fetchNfCocListSubmittedRenewals: fetchNfCocListSubmittedRenewalsReducer,

  isFetchingFetchNfCocListSubmittedRenewals: isFetchingFetchNfCocListSubmittedRenewalsReducer,
  fetchNfCocListSubmittedRenewalsError: fetchNfCocListSubmittedRenewalsErrorReducer,
});

export default { reducers };
