import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button } from '@govhhs/govhhs-design-system-react';
import translations from './NFApplicationHeaderMessages';
import PATHS from '../../../paths';
import { ID } from '../constants';

/**
 * Returns the sign in menu for display on pages that are accessible without
 * being logged in.
 */
const NFSignInMenuComponent = props => (
  <>
    <span className="wds-u-divider--bottom" />
    <Button
      key="signup"
      category="link"
      data-testid={ID.NFAPPLICATION_HEADER_SIGNUP_BUTTON_ID}
      navClose
      onClick={e => {
        // This is the large screen size signup button
        e.preventDefault();
        props.history.push({
          pathname: PATHS.NFCREATENCIDACCOUNT,
          params: { previousPathname: props.location.pathname },
        });
      }}
      size="small"
    >
      {props.intl.formatMessage(translations.NFApplicationHeader_headerSignUp_Label)}
    </Button>
    <Button
      key="login"
      category="primary"
      className="wds-u-hide--nav-width-down"
      data-testid={ID.NFAPPLICATION_HEADER_LOGIN_BUTTON_ID}
      navClose
      onClick={e => {
        // This is the large screen size login button
        e.preventDefault();
        props.history.push({
          pathname: PATHS.LOGIN,
          params: { previousPathname: props.location.pathname },
        });
      }}
      size="small"
    >
      {props.intl.formatMessage(translations.NFApplicationHeader_headerLogIn_Label)}
    </Button>
  </>
);

/**
 * The NFSignInMenuComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof NFSignInMenuComponent
 */
NFSignInMenuComponent.propTypes = {
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(NFSignInMenuComponent));
