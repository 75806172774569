/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFDSNAPDetails
 *
 * @class NFDSNAPDetails
 */
class NFDSNAPDetails {
  /**
   * Creates an instance of NFDSNAPDetails.
   * @param {*} json
   * @memberof NFDSNAPDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isDisasterActiveInd = json.isDisasterActiveInd;

      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;
    }
  }
}

export default {
  NFDSNAPDetails,
};
