/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFYourInformation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFYOURINFORMATION/FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS`
);

export { FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS };
