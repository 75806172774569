/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

import selectors from './selectors';

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/motivation/setup-and-start
 *
 * postMotivationSetupAndStart - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostMotivationSetupAndStart = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @returns {Promise}
   */
  const postMotivationSetupAndStart = useCallback(
    newUAMotivationType => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postMotivationSetupAndStart(
          dispatch,
          newUAMotivationType,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postMotivationSetupAndStart,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/apply_for_unified
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfMotivationApplyForUnified = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfMotivationApplyForUnified(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfMotivationApplyForUnified(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfMotivationApplyForUnified(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} iegExecutionID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationMotivationTypeByIegExecutionID = (iegExecutionID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfMotivationMotivationTypeByIegExecutionID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationMotivationTypeByIegExecutionID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationMotivationTypeByIegExecutionID(
        dispatch,
        iegExecutionID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(iegExecutionID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/finish_unified
 *
 * postNfMotivationFinishUnified - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationFinishUnified = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/finish_unified
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationFinishUnified = useCallback(
    newUAMotivationIEGKey => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationFinishUnified(
          dispatch,
          newUAMotivationIEGKey,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationFinishUnified,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/{iegExecutionID}
 *
 * deleteNfMotivationByIegExecutionID - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const useDeleteNfMotivationByIegExecutionID = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @returns {Promise}
   */
  const deleteNfMotivationByIegExecutionID = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.deleteNfMotivationByIegExecutionID(
          dispatch,
          iegExecutionID,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    deleteNfMotivationByIegExecutionID,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/draft_applications
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchDraftApplications = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchDraftApplications(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchDraftApplications(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchDraftApplications(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_workspace/list_existing_app
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCitizenWorkspaceListExistingApp = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfCitizenWorkspaceListExistingApp(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCitizenWorkspaceListExistingApp(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCitizenWorkspaceListExistingApp(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/{iegExecutionID}/esignature
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} iegExecutionID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationEsignature = (iegExecutionID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.getNfMotivationEsignature(state));

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationEsignature(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationEsignature(dispatch, iegExecutionID, (success, data) => {
        setResponse({
          data: success ? data : null,
          isFetching: false,
          error: success ? null : data,
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(iegExecutionID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/sign_and_submit
 *
 * postNfMotivationSignAndSubmit - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationSignAndSubmit = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/sign_and_submit
   *
   * @param {NewNFCESignAndSubmit} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationSignAndSubmit = useCallback(
    newNFCESignAndSubmit => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationSignAndSubmit(
          dispatch,
          newNFCESignAndSubmit,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationSignAndSubmit,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/motivation_details/{iegExecutionID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} iegExecutionID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationMotivationDetailsByIegExecutionID = (iegExecutionID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfMotivationMotivationDetailsByIegExecutionID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationMotivationDetailsByIegExecutionID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationMotivationDetailsByIegExecutionID(
        dispatch,
        iegExecutionID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(iegExecutionID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/confirmation_details/{iegExecutionID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} iegExecutionID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationConfirmationDetailsByIegExecutionID = (iegExecutionID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfMotivationConfirmationDetailsByIegExecutionID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationConfirmationDetailsByIegExecutionID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationConfirmationDetailsByIegExecutionID(
        dispatch,
        iegExecutionID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(iegExecutionID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request_reasons
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} application_id.
 * @param {string} application_program_id.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons = (
  application_id,
  application_program_id,
  deps = []
) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons(
        dispatch,
        data
      );
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    (application_id, application_program_id) => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons(
        dispatch,
        application_id,
        application_program_id,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(application_id, application_program_id);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
 *
 * postSubmittedApplicationsApplicationProgramsWithdrawalRequest - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostSubmittedApplicationsApplicationProgramsWithdrawalRequest = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
   *
   * @param {string} application_id.
   * @param {string} application_program_id.
   * @param {NewUAWithdrawalRequest} data object that will be posted to the api
   * @returns {Promise}
   */
  const postSubmittedApplicationsApplicationProgramsWithdrawalRequest = useCallback(
    (application_id, application_program_id, newUAWithdrawalRequest) => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postSubmittedApplicationsApplicationProgramsWithdrawalRequest(
          dispatch,
          application_id,
          application_program_id,
          newUAWithdrawalRequest,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postSubmittedApplicationsApplicationProgramsWithdrawalRequest,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/submitted_applications/application_programs/withdrawal_coc_request
 *
 * postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/submitted_applications/application_programs/withdrawal_coc_request
   *
   * @param {NewNFDenyWithdrawApplicantDetails} data object that will be posted to the api
   * @returns {Promise}
   */
  const postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = useCallback(
    newNFDenyWithdrawApplicantDetails => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest(
          dispatch,
          newNFDenyWithdrawApplicantDetails,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_workspace/list_existing_coc_app
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCitizenWorkspaceListExistingCocApp = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.fetchNfCitizenWorkspaceListExistingCocApp(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCitizenWorkspaceListExistingCocApp(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCitizenWorkspaceListExistingCocApp(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
 *
 * postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
   *
   * @param {string} application_id.
   * @param {string} application_program_id.
   * @param {NewUAWithdrawalRequest} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = useCallback(
    (application_id, application_program_id, newUAWithdrawalRequest) => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest(
          dispatch,
          application_id,
          application_program_id,
          newUAWithdrawalRequest,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/store_save_and_exit_page_name
 *
 * postNfMotivationStoreSaveAndExitPageName - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationStoreSaveAndExitPageName = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/store_save_and_exit_page_name
   *
   * @param {NewNFSaveAndExitKey} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationStoreSaveAndExitPageName = useCallback(
    newNFSaveAndExitKey => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationStoreSaveAndExitPageName(
          dispatch,
          newNFSaveAndExitKey,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationStoreSaveAndExitPageName,
  };
};

export default {
  usePostMotivationSetupAndStart,
  useFetchNfMotivationApplyForUnified,
  useGetNfMotivationMotivationTypeByIegExecutionID,
  usePostNfMotivationFinishUnified,
  useDeleteNfMotivationByIegExecutionID,
  useFetchDraftApplications,
  useFetchNfCitizenWorkspaceListExistingApp,
  useGetNfMotivationEsignature,
  usePostNfMotivationSignAndSubmit,
  useGetNfMotivationMotivationDetailsByIegExecutionID,
  useGetNfMotivationConfirmationDetailsByIegExecutionID,
  useGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons,
  usePostSubmittedApplicationsApplicationProgramsWithdrawalRequest,
  usePostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest,
  useFetchNfCitizenWorkspaceListExistingCocApp,
  usePostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest,
  usePostNfMotivationStoreSaveAndExitPageName,
};
