/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFMFASession' store object.
 * @namespace NFMFASessionSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFMFASession';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaGetMFAQuestions = state =>
    state[selectors.moduleIdentifier].postNfmfaGetMFAQuestions.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static isFetchingPostNfmfaGetMFAQuestions = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfmfaGetMFAQuestions.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaGetMFAQuestionsError = state =>
    state[selectors.moduleIdentifier].postNfmfaGetMFAQuestionsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaSendMFACode = state =>
    state[selectors.moduleIdentifier].postNfmfaSendMFACode.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static isFetchingPostNfmfaSendMFACode = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfmfaSendMFACode.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaSendMFACodeError = state =>
    state[selectors.moduleIdentifier].postNfmfaSendMFACodeError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaResendMFACode = state =>
    state[selectors.moduleIdentifier].postNfmfaResendMFACode.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static isFetchingPostNfmfaResendMFACode = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfmfaResendMFACode.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMFASessionSelectors
   */
  static postNfmfaResendMFACodeError = state =>
    state[selectors.moduleIdentifier].postNfmfaResendMFACodeError.payload;
}
