/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFAppealsRequest' store object.
 * @namespace NFAppealsRequestSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFAppealsRequest';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFAppealsRequestSelectors
   */
  static fetchNfAppeals = state => state[selectors.moduleIdentifier].fetchNfAppeals.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFAppealsRequestSelectors
   */
  static isFetchingFetchNfAppeals = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfAppeals.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFAppealsRequestSelectors
   */
  static fetchNfAppealsError = state =>
    state[selectors.moduleIdentifier].fetchNfAppealsError.payload;
}
