const core = require('@spm/core');
const {
  NF_SECURE_INBOX_ACTION_TYPES, NF_SECURE_INBOX_TOGGLE_EMAIL, NF_SECURE_INBOX_UPDATE_EMAIL, NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS,
  NF_SECURE_INBOX_CONFIRM_EMAIL, NF_SECURE_INBOX_SUBSCRIBE, NF_SECURE_INBOX_UNSUBSCRIBE, NF_SECURE_INBOX_MARK_TO_SEND_BY_MAIL,
  NF_SECURE_TOGGLE_SUBSCRIPTION, NF_SECURE_CONFIRM_SUBSCRIPTION,
  NF_SECURE_INBOX_IS_SUBSCRIBED,
} = require('./nfActionTypes');

export const nfUpdateEmailReducer = (state = {}, action) => {
  if ((action.type === NF_SECURE_INBOX_CONFIRM_EMAIL.successAction) && action.payload.isSuccess) {
    const newstate = Object.assign({}, state);
    newstate.payload.isSuccess = false;
    return core.ReduxUtils.generateReducer(newstate, action, NF_SECURE_INBOX_UPDATE_EMAIL);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_UPDATE_EMAIL);
};
export const nfIsFetchingUpdateEmailReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_UPDATE_EMAIL);

export const nfConfirmEmailReducer = (state = {}, action) => core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_CONFIRM_EMAIL);
export const nfIsFetchingConfirmEmailReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_CONFIRM_EMAIL);

export const nfIsFetchingSecureInboxDataReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_ACTION_TYPES);
export const nfSecureInboxReducer = (state = {}, action) => core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_ACTION_TYPES);

export const nfToggleEmailReducer = (state = {}, action) => {
  if ((action.type === NF_SECURE_INBOX_CONFIRM_EMAIL.successAction) && action.payload.isSuccess) {
    const newstate = Object.assign({}, state);
    newstate.payload = false;
    return core.ReduxUtils.generateReducer(newstate, action, NF_SECURE_INBOX_TOGGLE_EMAIL);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_TOGGLE_EMAIL);
};
export const nfGetPrimaryContactDetailsReducer = (state = {}, action) => {
  if ((action.type === NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS.successAction) && action.payload.isEmailInd) {
    const newState = Object.assign({}, state);
    newState.payload = {};
    newState.payload.isSuccess = true;
    newState.payload.message = "";
    //newstate.payload.emailAddressDtls.emailAddress = action.payload.emailAddressDtls.emailAddress;
    return core.ReduxUtils.generateReducer(newState, action, NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS);
};
export const nfIsFetchingGetEmailReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS);

export const nfToggleSubscriptionReducer = (state = {}, action) => {
  if ((action.type === NF_SECURE_CONFIRM_SUBSCRIPTION.successAction || action.type === NF_SECURE_INBOX_UNSUBSCRIBE.successAction)
    && action.payload.isSuccess) {
    const newState = Object.assign({}, state);
    newState.payload = false;
    return core.ReduxUtils.generateReducer(newState, action, NF_SECURE_TOGGLE_SUBSCRIPTION);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_TOGGLE_SUBSCRIPTION);
};
export const nfMarkCommunicationToSendByMailReducer = (state = {}, action) => core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_MARK_TO_SEND_BY_MAIL);
export const nfIsFetchingMarkCommunicationToSendByMailReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_MARK_TO_SEND_BY_MAIL);

export const nfIsSubscribedToENoticesReducer = (state = {}, action) => {
  if ((action.type === NF_SECURE_CONFIRM_SUBSCRIPTION.successAction) && action.payload.isSuccess) {
    const newState = Object.assign({}, state);
    newState.payload.isSuccess = true;
    newState.payload.isSubscribed = true;
    return core.ReduxUtils.generateReducer(newState, action, NF_SECURE_INBOX_IS_SUBSCRIBED);
  }
  if ((action.type === NF_SECURE_INBOX_UNSUBSCRIBE.successAction) && action.payload.isSuccess) {
    const newState = Object.assign({}, state);
    newState.payload.isSuccess = true;
    newState.payload.isSubscribed = false;
    return core.ReduxUtils.generateReducer(newState, action, NF_SECURE_INBOX_IS_SUBSCRIBED);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_IS_SUBSCRIBED);
};
export const nfIsFetchingIsSubscribedToENoticesReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_IS_SUBSCRIBED);


export const nfSubscribeToENoticesReducer = (state = {}, action) => {
  if (action.type === NF_SECURE_INBOX_SUBSCRIBE.successAction) {
    const newState = Object.assign({}, state);
    const newAction = { ...action };

    if (action.payload === null) {
      newState.payload = { isSuccess: true, isSubscribed: true, message: '' };
      newAction.payload = { isSuccess: true, isSubscribed: true, message: '' };
    } else {
      newState.payload.isSuccess = false;
      newState.payload.isSubscribed = false;
      newState.payload.message = '';
    }
    return core.ReduxUtils.generateReducer(newState, newAction, NF_SECURE_INBOX_SUBSCRIBE);
  }
  return core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_SUBSCRIBE);
};
export const nfIsFetchingSubscribeToENoticesReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_SUBSCRIBE);

export const nfUnSubscribeFromENoticesReducer = (state = {}, action) => core.ReduxUtils.generateReducer(state, action, NF_SECURE_INBOX_UNSUBSCRIBE);
export const nfIsFetchingUnSubscribeFromENoticesReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_INBOX_UNSUBSCRIBE);

export const nfConfirmENoticesSubscriptionReducer = (state = {}, action) => core.ReduxUtils.generateReducer(state, action, NF_SECURE_CONFIRM_SUBSCRIPTION);
export const nfIsFetchingConfirmENoticesSubscriptionReducer = (state = {}, action) => core.ReduxUtils.generateFetchingReducer(state, action, NF_SECURE_CONFIRM_SUBSCRIPTION);
