/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Provider } from 'react-redux';
import { CoreReduxStore } from '@spm/core';
import configureStore from './store';

/** Handles Redux Initialization. */
export default class ReduxInit extends Component {
  /**
   * Creates an instance of ReduxInit.
   * @param {any} props the props for the component
   * @memberof ReduxInit
   */
  constructor(props) {
    super(props);

    // ===================================
    // 1. Create the store and initialize the universal-access module.
    // ==================================

    // Create the app Redux store
    this.appStore = configureStore();

    // Configure the Core package
    CoreReduxStore.configureStore(this.appStore);
  }

  /**
   * ReduxInit component entry point.
   */
  render() {
    return <Provider store={this.appStore}>{this.props.children}</Provider>;
  }
}

ReduxInit.propTypes = {
  children: PropTypes.node.isRequired,
};
