/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFDeemedNewBornResponse
 *
 * @class NFDeemedNewBornResponse
 */
class NFDeemedNewBornResponse {
  /**
   * Creates an instance of NFDeemedNewBornResponse.
   * @param {*} json
   * @memberof NFDeemedNewBornResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.deemedNewBornAllowed = json.deemedNewBornAllowed;
    }
  }
}

/**
 * Models the NFMotivationTypeResponse
 *
 * @class NFMotivationTypeResponse
 */
class NFMotivationTypeResponse {
  /**
   * Creates an instance of NFMotivationTypeResponse.
   * @param {*} json
   * @memberof NFMotivationTypeResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationName = json.motivationName;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationType
 *
 * @class NewUAMotivationType
 */
class NewUAMotivationType {
  /**
   * Creates an instance of NewUAMotivationType.
   * @param {*} json
   * @memberof NewUAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKeyResponse
 *
 * @class NewUAMotivationIEGKeyResponse
 */
class NewUAMotivationIEGKeyResponse {
  /**
   * Creates an instance of NewUAMotivationIEGKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationIEGKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKey
 *
 * @class NewUAMotivationIEGKey
 */
class NewUAMotivationIEGKey {
  /**
   * Creates an instance of NewUAMotivationIEGKey.
   * @param {*} json
   * @memberof NewUAMotivationIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewNFMotivationFinishResponseResponse
 *
 * @class NewNFMotivationFinishResponseResponse
 */
class NewNFMotivationFinishResponseResponse {
  /**
   * Creates an instance of NewNFMotivationFinishResponseResponse.
   * @param {*} json
   * @memberof NewNFMotivationFinishResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;

      // string
      this.motivationID = json.motivationID;

      // boolean
      this.isARCOC = json.isARCOC;

      // boolean
      this.hasAuthRep = json.hasAuthRep;
    }
  }
}

/**
 * Models the CESignAndSubmitResponse
 *
 * @class CESignAndSubmitResponse
 */
class CESignAndSubmitResponse {
  /**
   * Creates an instance of CESignAndSubmitResponse.
   * @param {*} json
   * @memberof CESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the Error
 *
 * @class Error
 */
class Error {
  /**
   * Creates an instance of Error.
   * @param {*} json
   * @memberof Error
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.code = json.code;

      // string
      this.message = json.message;

      // string
      this.message_id = json.message_id;

      // string
      this.level = json.level;
    }
  }
}

/**
 * Models the NFCESignAndSubmitResponse
 *
 * @class NFCESignAndSubmitResponse
 */
class NFCESignAndSubmitResponse {
  /**
   * Creates an instance of NFCESignAndSubmitResponse.
   * @param {*} json
   * @memberof NFCESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - CESignAndSubmitResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new CESignAndSubmitResponse(json.signAndSubmitResponse);
      }

      // array - Error
      if (json.errors) {
        this.errors = json.errors.map(object => new Error(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new Error(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAMotivation
 *
 * @class UAMotivation
 */
class UAMotivation {
  /**
   * Creates an instance of UAMotivation.
   * @param {*} json
   * @memberof UAMotivation
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationId = json.motivationId;

      // string
      this.dataStoreId = json.dataStoreId;

      // string
      this.typeID = json.typeID;

      // object - CodeTableType
      if (json.state) {
        this.state = new CodeTableType(json.state);
      }

      // string
      this.scriptExecutionID = json.scriptExecutionID;

      // string
      this.username = json.username;

      // string
      this.timestamp = json.timestamp;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UAMotivationType
 *
 * @class UAMotivationType
 */
class UAMotivationType {
  /**
   * Creates an instance of UAMotivationType.
   * @param {*} json
   * @memberof UAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NFMotivationDetails
 *
 * @class NFMotivationDetails
 */
class NFMotivationDetails {
  /**
   * Creates an instance of NFMotivationDetails.
   * @param {*} json
   * @memberof NFMotivationDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSubmissionFormInd = json.isSubmissionFormInd;

      // object - UAMotivation
      if (json.motivation) {
        this.motivation = new UAMotivation(json.motivation);
      }

      // object - UAMotivationType
      if (json.motivationType) {
        this.motivationType = new UAMotivationType(json.motivationType);
      }
    }
  }
}

/**
 * Models the NewCESignAndSubmit
 *
 * @class NewCESignAndSubmit
 */
class NewCESignAndSubmit {
  /**
   * Creates an instance of NewCESignAndSubmit.
   * @param {*} json
   * @memberof NewCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.perjuryESignatureInd = json.perjuryESignatureInd;

      // boolean
      this.defaultRenewalInd = json.defaultRenewalInd;

      // boolean
      this.absentParentESignatureInd = json.absentParentESignatureInd;

      // boolean
      this.informationChangedESignatureInd = json.informationChangedESignatureInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.renewalDuration = json.renewalDuration;

      // string
      this.motivation_id = json.motivation_id;

      // string
      this.categoryId = json.categoryId;

      // string
      this.navigatorID = json.navigatorID;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmit
 *
 * @class NewNFCESignAndSubmit
 */
class NewNFCESignAndSubmit {
  /**
   * Creates an instance of NewNFCESignAndSubmit.
   * @param {*} json
   * @memberof NewNFCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfMedicaidTransportationRespInd = json.nfMedicaidTransportationRespInd;

      // boolean
      this.rightsAndResponsibilitiesInd = json.rightsAndResponsibilitiesInd;

      // boolean
      this.nfUAInformationChangedESignatureInd = json.nfUAInformationChangedESignatureInd;

      // boolean
      this.nfMedicaidNoticeOfPrivacyInd = json.nfMedicaidNoticeOfPrivacyInd;

      // boolean
      this.authorizeReleaseInformationInd = json.authorizeReleaseInformationInd;

      // string
      this.iegExecutionID = json.iegExecutionID;

      // string
      this.motivationID = json.motivationID;

      // object - NewCESignAndSubmit
      if (json.signAndSubmitDetails) {
        this.signAndSubmitDetails = new NewCESignAndSubmit(json.signAndSubmitDetails);
      }
    }
  }
}

/**
 * Models the NewCESignAndSubmitResponseResponse
 *
 * @class NewCESignAndSubmitResponseResponse
 */
class NewCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the NewErrorResponse
 *
 * @class NewErrorResponse
 */
class NewErrorResponse {
  /**
   * Creates an instance of NewErrorResponse.
   * @param {*} json
   * @memberof NewErrorResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.message = json.message;

      // string
      this.level = json.level;

      // string
      this.code = json.code;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmitResponseResponse
 *
 * @class NewNFCESignAndSubmitResponseResponse
 */
class NewNFCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewNFCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewNFCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - NewCESignAndSubmitResponseResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new NewCESignAndSubmitResponseResponse(
          json.signAndSubmitResponse
        );
      }

      // array - NewErrorResponse
      if (json.errors) {
        this.errors = json.errors.map(object => new NewErrorResponse(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new NewErrorResponse(object));
      }
    }
  }
}

/**
 * Models the NFIsANBUserLinkedResponse
 *
 * @class NFIsANBUserLinkedResponse
 */
class NFIsANBUserLinkedResponse {
  /**
   * Creates an instance of NFIsANBUserLinkedResponse.
   * @param {*} json
   * @memberof NFIsANBUserLinkedResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isANBUserLinked = json.isANBUserLinked;
    }
  }
}

/**
 * Models the NewNFANBArchiveApplicationDetails
 *
 * @class NewNFANBArchiveApplicationDetails
 */
class NewNFANBArchiveApplicationDetails {
  /**
   * Creates an instance of NewNFANBArchiveApplicationDetails.
   * @param {*} json
   * @memberof NewNFANBArchiveApplicationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.applicationCaseID = json.applicationCaseID;

      // string
      this.processingDate = json.processingDate;
    }
  }
}

/**
 * Models the NewNFANBArchiveApplicationDetailsList
 *
 * @class NewNFANBArchiveApplicationDetailsList
 */
class NewNFANBArchiveApplicationDetailsList {
  /**
   * Creates an instance of NewNFANBArchiveApplicationDetailsList.
   * @param {*} json
   * @memberof NewNFANBArchiveApplicationDetailsList
   */
  constructor(json = null) {
    if (json) {
      // array - NewNFANBArchiveApplicationDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NewNFANBArchiveApplicationDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NewNFANBArchiveApplicationDetails(object));
      }
    }
  }
}

/**
 * Models the NewNFANBReferralArchivedResponseResponse
 *
 * @class NewNFANBReferralArchivedResponseResponse
 */
class NewNFANBReferralArchivedResponseResponse {
  /**
   * Creates an instance of NewNFANBReferralArchivedResponseResponse.
   * @param {*} json
   * @memberof NewNFANBReferralArchivedResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NFANBApplicationsDetails
 *
 * @class NFANBApplicationsDetails
 */
class NFANBApplicationsDetails {
  /**
   * Creates an instance of NFANBApplicationsDetails.
   * @param {*} json
   * @memberof NFANBApplicationsDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.ANBProviderName = json.ANBProviderName;

      // string
      this.providerUser = json.providerUser;

      // string
      this.birthMotherCNDSID = json.birthMotherCNDSID;

      // string
      this.newBornCNDSID = json.newBornCNDSID;

      // string
      this.birthMotherName = json.birthMotherName;

      // string
      this.newBornName = json.newBornName;

      // string
      this.newBornDOB = json.newBornDOB;

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.submittedDate = json.submittedDate;

      // boolean
      this.isProcessed = json.isProcessed;

      // string
      this.processingDate = json.processingDate;

      // string
      this.applicationCaseID = json.applicationCaseID;

      // string
      this.nfCountyDSSCode = json.nfCountyDSSCode;

      // string
      this.nfReferralStatus = json.nfReferralStatus;
    }
  }
}

/**
 * Models the NFANBApplicationsListResponse
 *
 * @class NFANBApplicationsListResponse
 */
class NFANBApplicationsListResponse {
  /**
   * Creates an instance of NFANBApplicationsListResponse.
   * @param {*} json
   * @memberof NFANBApplicationsListResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // integer
      this.pageNumber = json.pageNumber;

      // integer
      this.totalPages = json.totalPages;

      // array - NFANBApplicationsDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFANBApplicationsDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFANBApplicationsDetails(object));
      }
    }
  }
}

/**
 * Models the NFANBUserFacilityDetails
 *
 * @class NFANBUserFacilityDetails
 */
class NFANBUserFacilityDetails {
  /**
   * Creates an instance of NFANBUserFacilityDetails.
   * @param {*} json
   * @memberof NFANBUserFacilityDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.ANBProviderName = json.ANBProviderName;

      // string
      this.providerConcernRoleID = json.providerConcernRoleID;
    }
  }
}

/**
 * Models the NFANBUserFacilityListResponse
 *
 * @class NFANBUserFacilityListResponse
 */
class NFANBUserFacilityListResponse {
  /**
   * Creates an instance of NFANBUserFacilityListResponse.
   * @param {*} json
   * @memberof NFANBUserFacilityListResponse
   */
  constructor(json = null) {
    if (json) {
      // array - NFANBUserFacilityDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFANBUserFacilityDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFANBUserFacilityDetails(object));
      }
    }
  }
}

export default {
  NFDeemedNewBornResponse,

  NFMotivationTypeResponse,

  NewUAMotivationType,

  NewUAMotivationIEGKeyResponse,

  NewUAMotivationIEGKey,

  NewNFMotivationFinishResponseResponse,

  CESignAndSubmitResponse,

  Error,

  NFCESignAndSubmitResponse,

  CodeTableType,

  UAMotivation,

  UAMotivationType,

  NFMotivationDetails,

  NewCESignAndSubmit,

  NewNFCESignAndSubmit,

  NewCESignAndSubmitResponseResponse,

  NewErrorResponse,

  NewNFCESignAndSubmitResponseResponse,

  NFIsANBUserLinkedResponse,

  NewNFANBArchiveApplicationDetails,

  NewNFANBArchiveApplicationDetailsList,

  NewNFANBReferralArchivedResponseResponse,

  NFANBApplicationsDetails,

  NFANBApplicationsListResponse,

  NFANBUserFacilityDetails,

  NFANBUserFacilityListResponse,
};
