/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFLifeEventTypeResponse from api:
 * /v1/ua/nf_lifeevent/apply_for_coc
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfLifeeventApplyForCocUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_lifeevent/apply_for_coc`,
    models.NFLifeEventTypeResponse
  );
};

/**
 * Gets NFLifeEventTypeResponse from api:
 * /v1/ua/nf_lifeevent/apply_for_ar
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfLifeeventApplyForArUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_lifeevent/apply_for_ar`,
    models.NFLifeEventTypeResponse
  );
};

/**
 * Gets NFChangeOfCircumstanceResponse from api:
 * /v1/ua/nf_coc/unsupported_message
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocUnsupportedMessageUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/unsupported_message`,
    models.NFChangeOfCircumstanceResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/life_events_form
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postLifeEventsFormUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/life_events_form`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUALifeEventFormIdResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/finish_coc
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationFinishCocUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/finish_coc`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMotivationFinishResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/sign_and_submit_coc
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationSignAndSubmitCocUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/sign_and_submit_coc`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCESignAndSubmitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFCEReadSignatureDetailsResponse from api:
 * /v1/ua/nf_motivation/{motivation_id}/esignature_by_motivation
 *
 * @param {string} motivation_id.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationEsignatureByMotivationUtil = (motivation_id, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/${motivation_id}/esignature_by_motivation`,
    models.NFCEReadSignatureDetailsResponse
  );
};

/**
 * Gets NFCESignAndSubmitResponse from api:
 * /v1/ua/nf_motivation/coc_confirmation_details/{motivation_id}
 *
 * @param {string} motivation_id.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationCocConfirmationDetailsByMotivationIdUtil = (motivation_id, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/coc_confirmation_details/${motivation_id}`,
    models.NFCESignAndSubmitResponse
  );
};

/**
 * Gets NFCitizenLifeEventsResponse from api:
 * /v1/ua/nf_life_events/list_submitted_lifeevents
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfLifeEventsListSubmittedLifeeventsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_life_events/list_submitted_lifeevents`,
    models.NFCitizenLifeEventsResponse
  );
};

/**
 * Gets NFCitizenPortalEvidenceResponse from api:
 * /v1/ua/nf_citizen_portal_evidence/read_my_info_tab_details
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_portal_evidence/read_my_info_tab_details`,
    models.NFCitizenPortalEvidenceResponse
  );
};

/**
 * Gets NFEnableABD from api:
 * /v1/ua/nf_citizen_portal_evidence/get_enable_abd/{nfMotivationID}
 *
 * @param {string} nfMotivationID.
 * @param {any} callback function to call once this function has finished
 */
const getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDUtil = (nfMotivationID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_portal_evidence/get_enable_abd/${nfMotivationID}`,
    models.NFEnableABD
  );
};

/**
 * Gets NFEnableUnifiedMAGI from api:
 * /v1/ua/nf_citizen_portal_evidence/getEnableUnifiedMagi/{nfMotivationID}
 *
 * @param {string} nfMotivationID.
 * @param {any} callback function to call once this function has finished
 */
const getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDUtil = (
  nfMotivationID,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_portal_evidence/getEnableUnifiedMagi/${nfMotivationID}`,
    models.NFEnableUnifiedMAGI
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/setup_start_motivation
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationSetupStartMotivationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/setup_start_motivation`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFCOCConfigResponse from api:
 * /v1/ua/nf_coc/apply_for_snap_coc
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocApplyForSnapCocUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/apply_for_snap_coc`,
    models.NFCOCConfigResponse
  );
};

/**
 * Gets NFCOCConfigResponse from api:
 * /v1/ua/nf_coc/apply_for_fns_coc
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocApplyForFnsCocUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/apply_for_fns_coc`,
    models.NFCOCConfigResponse
  );
};

export {
  fetchNfLifeeventApplyForCocUtil,
  fetchNfLifeeventApplyForArUtil,
  fetchNfCocUnsupportedMessageUtil,
  postLifeEventsFormUtil,
  postNfMotivationFinishCocUtil,
  postNfMotivationSignAndSubmitCocUtil,
  getNfMotivationEsignatureByMotivationUtil,
  getNfMotivationCocConfirmationDetailsByMotivationIdUtil,
  fetchNfLifeEventsListSubmittedLifeeventsUtil,
  fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil,
  getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDUtil,
  getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDUtil,
  postNfMotivationSetupStartMotivationUtil,
  fetchNfCocApplyForSnapCocUtil,
  fetchNfCocApplyForFnsCocUtil,
};
