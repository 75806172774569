/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP,
  POST_NF_CITIZEN_WORKSPACE_START_LIEAP,
  GET_FORM_DETAILS_BY_APPLICATION_FORM_ID,
  FETCH_DRAFT_APPLICATIONS,
} from './actionTypes';

/**
 * Updates the details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenWorkspaceApplyLieapReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP);

/**
 * Updates the indicator of creation of details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenWorkspaceApplyLieapReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenWorkspaceApplyLieapErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP);

/**
 * Updates the details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfCitizenWorkspaceStartLieapReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_CITIZEN_WORKSPACE_START_LIEAP);

/**
 * Updates the indicator of creation of details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfCitizenWorkspaceStartLieapReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_CITIZEN_WORKSPACE_START_LIEAP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfCitizenWorkspaceStartLieapErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_CITIZEN_WORKSPACE_START_LIEAP);

/**
 * Updates the details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getFormDetailsByApplicationFormIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the indicator of creation of details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetFormDetailsByApplicationFormIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getFormDetailsByApplicationFormIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_DRAFT_APPLICATIONS);

/**
 * Updates the indicator of creation of details in the 'NFLIEAPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_DRAFT_APPLICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchDraftApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_DRAFT_APPLICATIONS);

const reducers = combineReducers({
  fetchNfCitizenWorkspaceApplyLieap: fetchNfCitizenWorkspaceApplyLieapReducer,

  isFetchingFetchNfCitizenWorkspaceApplyLieap: isFetchingFetchNfCitizenWorkspaceApplyLieapReducer,
  fetchNfCitizenWorkspaceApplyLieapError: fetchNfCitizenWorkspaceApplyLieapErrorReducer,

  postNfCitizenWorkspaceStartLieap: postNfCitizenWorkspaceStartLieapReducer,

  isFetchingPostNfCitizenWorkspaceStartLieap: isFetchingPostNfCitizenWorkspaceStartLieapReducer,
  postNfCitizenWorkspaceStartLieapError: postNfCitizenWorkspaceStartLieapErrorReducer,

  getFormDetailsByApplicationFormId: getFormDetailsByApplicationFormIdReducer,

  isFetchingGetFormDetailsByApplicationFormId: isFetchingGetFormDetailsByApplicationFormIdReducer,
  getFormDetailsByApplicationFormIdError: getFormDetailsByApplicationFormIdErrorReducer,

  fetchDraftApplications: fetchDraftApplicationsReducer,

  isFetchingFetchDraftApplications: isFetchingFetchDraftApplicationsReducer,
  fetchDraftApplicationsError: fetchDraftApplicationsErrorReducer,
});

export default { reducers };
