/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFInformationalMsgDtls
 *
 * @class NFInformationalMsgDtls
 */
class NFInformationalMsgDtls {
  /**
   * Creates an instance of NFInformationalMsgDtls.
   * @param {*} json
   * @memberof NFInformationalMsgDtls
   */
  constructor(json = null) {
    if (json) {
      // string
      this.informationalMsgText = json.informationalMsgText;
    }
  }
}

/**
 * Models the NFInformationalMsgDtlsList
 *
 * @class NFInformationalMsgDtlsList
 */
class NFInformationalMsgDtlsList {
  /**
   * Creates an instance of NFInformationalMsgDtlsList.
   * @param {*} json
   * @memberof NFInformationalMsgDtlsList
   */
  constructor(json = null) {
    if (json) {
      // array - NFInformationalMsgDtls
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFInformationalMsgDtls(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFInformationalMsgDtls(object));
      }
    }
  }
}

export default {
  NFInformationalMsgDtls,

  NFInformationalMsgDtlsList,
};
