/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
} from './actionTypes';

import {
  postMotivationSetupAndStartUtil,
  fetchNfMotivationApplyForStandaloneRidpUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishStandaloneRidpUtil,
  deleteNfMotivationByIegExecutionIDUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFMotivationStandaloneRDIP slice of the store.
 *
 * @namespace NFMotivationStandaloneRDIPActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static postMotivationSetupAndStart = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupAndStartUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP_AND_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetPostMotivationSetupAndStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP_AND_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetPostMotivationSetupAndStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP_AND_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/apply_for_standalone_ridp
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static fetchNfMotivationApplyForStandaloneRidp = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfMotivationApplyForStandaloneRidpUtil,
      FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfMotivationApplyForStandaloneRidp.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetFetchNfMotivationApplyForStandaloneRidp = (dispatch, data) => {
    const json = {
      type: FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfMotivationApplyForStandaloneRidp.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetFetchNfMotivationApplyForStandaloneRidpError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static getNfMotivationMotivationTypeByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationTypeByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_standalone_ridp
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static postNfMotivationFinishStandaloneRidp = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishStandaloneRidpUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishStandaloneRidp.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetPostNfMotivationFinishStandaloneRidp = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishStandaloneRidp.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetPostNfMotivationFinishStandaloneRidpError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static deleteNfMotivationByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      deleteNfMotivationByIegExecutionIDUtil.bind(null, iegExecutionID),
      DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetDeleteNfMotivationByIegExecutionID = (dispatch, data) => {
    const json = {
      type: DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMotivationStandaloneRDIPActions
   */
  static resetDeleteNfMotivationByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.failureAction);
  };
}
