import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RESTService, EnvUtils } from '@spm/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Authentication,
  ProfileActions,
  ProfileSelectors,
  ProfileModel,
  UserActions,
  UserSelectors,
  UserModels,
} from '@spm/universal-access';
import { AppSpinner } from '@spm/core-ui';
import { FormsSelectors } from '@spm/intelligent-evidence-gathering';
import PATHS from '../../paths';
import NFApplicationHeaderComponent from './components/NFApplicationHeaderComponent';
import NFDocumentsToUploadSelectors from '../../modules/generated/NFDocumentUpload/selectors';
import NFDocumentsToUploadActions from '../../modules/generated/NFDocumentUpload/actions';
import NFPresumptiveActions from '../../modules/generated/NFPresumptiveMotivation/actions';
import NFPresumptiveSelectors from '../../modules/generated/NFPresumptiveMotivation/selectors';
import { 
  NFDeemedNewBornMotivationActions,
  NFDeemedNewBornMotivationSelectors,
} from '../../modules/generated';

/**
 * NFApplicationHeaderContainer initiates the rendering of the NFApplicationHeader
 * page. This component holds state for the NFApplicationHeader components.
 *
 * @class NFApplicationHeaderContainer
 * @extends {Component}
 */
export class NFApplicationHeaderContainer extends Component {
  /**
   * See https://reactjs.org/docs/react-component.html#componentdidmount
   *
   * @memberof NFApplicationHeaderContainer
   */
  componentDidMount() {
    // Conside refactoring/deprecating profile REST API and putting it
    // under user API. This would obviously impact props here..
    const { user, userSystemData, loadProfile, loadUser, actionIsLinkedUser, actionIsANBLinkedUser, actionHcrgetpresumptiveuser } = this.props;
    if (
      Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      if (!user) {
        loadProfile();
      }
      if (!userSystemData) {
        loadUser();
      }
      actionIsLinkedUser();
      actionIsANBLinkedUser();
      actionHcrgetpresumptiveuser();
    }
  }

  /**
   * See https://reactjs.org/docs/react-component.html#componentDidUpdate
   *
   * @memberof NFApplicationHeaderContainer
   */
  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user && this.props.user) {
      this.props.actionIsLinkedUser();
      this.props.actionIsANBLinkedUser();
    }
  }

  /**
   * See https://reactjs.org/docs/react-component.html#componentwillunmount
   *
   * @memberof NFApplicationHeaderContainer
   */
  componentWillUnmount() {
    const { profileError, resetError, userError, resetUserError } = this.props;
    // reset any errors in the store back to null when un-mounting
    if (profileError) {
      resetError();
    }
    if (userError) {
      resetUserError();
    }
  }

  /**
   * Validate if the user is a linked user.
   *
   * @memberof NFApplicationHeaderContainer
   */
  isALinkedUser = () => {
    if (Authentication.userTypeIs([Authentication.USER_TYPES.LINKED])) {
      return true;
    }
    return false;
  };

  /**
   * Renders the Application Header page using {@link NFApplicationHeaderComponent} or an
   * {@link Alert} if there was a problem loading the data.
   * @returns {@link NFApplicationHeaderComponent} or {@link AppSpinner}
   * @memberof NFApplicationHeaderComponent
   */
  render() {
    const {
      location,
      user,
      profileError,
      isFetchingProfile,
      isBaseFormIegRunning,
      userSystemData,
      propIsLinkedUser,
      propIsFetchingLinkedUser,
      propIsAnbLinkedUser,
      propHcrgetpresumptiveuser
    } = this.props;
    let userCanAppeal = false;

    const isAppealsEnabled = EnvUtils.getEnvironmentProperty('REACT_APP_FEATURE_APPEALS_ENABLED');

    if (userSystemData) {
      // shape of userSystemData will be validated already so safe to get
      // get canAppeal directly
      userCanAppeal = userSystemData.permissions.canAppeal;
    }
    RESTService.handleAPIFailure(profileError);

    // while application form or submission form.
    const isApplicationOrSubmssionForm =
      /\/apply\/-?\d+/.test(this.props.location.pathname) &&
      !/success/.test(this.props.location.pathname);

    // No Header should be rendered when in:
    // 1-Login or Sign Up page
    // 2-Application or Form Submission
    // 3-IEG Form using BaseFormContainer
    if (
      isBaseFormIegRunning ||
      location.pathname === PATHS.LOGIN ||
      location.pathname === PATHS.NFLOGIN ||
      location.pathname === PATHS.NFACCOUNTOPTIONS ||
      location.pathname === PATHS.NFCREATENCIDACCOUNT ||
      isApplicationOrSubmssionForm
    ) {
      return null;
    }

    return !Authentication.userTypeIs([
      Authentication.USER_TYPES.LINKED,
      Authentication.USER_TYPES.STANDARD,
    ]) || !isFetchingProfile ? (
      <NFApplicationHeaderComponent
        currentLocation={location.pathname}
        isALinkedUser={this.isALinkedUser}
        isAppealsEnabled={isAppealsEnabled}
        isUserCanAppeal={userCanAppeal}
        profile={user}
        propIsFetchingLinkedUser={propIsFetchingLinkedUser}
        propIsLinkedUser={propIsLinkedUser}
        propIsAnbLinkedUser={propIsAnbLinkedUser}
        propHcrgetpresumptiveuser={propHcrgetpresumptiveuser}
      />
    ) : (
      <AppSpinner />
    );
  }
}

/**
 * The NFApplicationHeaderContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof NFApplicationHeaderContainer
 */
NFApplicationHeaderContainer.propTypes = {
  /* eslint-disable react/boolean-prop-naming */
  actionIsLinkedUser: PropTypes.func.isRequired,
  isBaseFormIegRunning: PropTypes.string,
  isFetchingProfile: PropTypes.bool,
  loadProfile: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  profileError: PropTypes.object,
  propDeemedNewBornUser: PropTypes.object,
  propIsFetchingLinkedUser: PropTypes.bool,
  propIsLinkedUser: PropTypes.object,
  resetError: PropTypes.func.isRequired,
  resetUserError: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(ProfileModel),
  userError: PropTypes.object,
  userSystemData: PropTypes.instanceOf(UserModels.UAUserInformation),
};

/**
 * The NFApplicationHeaderContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof NFApplicationHeaderContainer
 */
NFApplicationHeaderContainer.defaultProps = {
  isBaseFormIegRunning: null,
  isFetchingProfile: false,
  profileError: null,
  propDeemedNewBornUser: {},
  user: undefined,
  userSystemData: undefined,
  propIsFetchingLinkedUser: false,
};

/**
 * Retrieves data from the Redux store.
 *
 * @param state  the redux store state
 * @memberof NFApplicationHeaderContainer
 */
const mapStateToProps = state => ({
  user: ProfileSelectors.getProfile(state),
  userSystemData: UserSelectors.fetchUser(state),
  profileError: ProfileSelectors.profileError(state),
  isFetchingProfile: ProfileSelectors.isFetchingProfile(state),
  isBaseFormIegRunning: FormsSelectors.getFormId(state),
  propIsLinkedUser: NFDocumentsToUploadSelectors.fetchNfDocUploadIsUserLinked(state),
  propIsFetchingLinkedUser: NFDocumentsToUploadSelectors.isFetchingFetchNfDocUploadIsUserLinked(
    state
  ),
  propIsAnbLinkedUser: NFDeemedNewBornMotivationSelectors.fetchNfDeemedNewBornIsAnbLoggedinUserLinked(state),
  propHcrgetpresumptiveuser: NFPresumptiveSelectors.fetchNfHcrgetpresumptiveuserPresumptive(state),
});

/**
 * Retrieve data from related rest APIs and updates the Redux store.
 *
 * @export
 * @param {*} dispatch the dispatch function
 * @returns {Object} the mappings.
 * @memberof NFApplicationHeaderContainer
 */
export const mapDispatchToProps = dispatch => ({
  loadProfile: () => ProfileActions.getUserProfile(dispatch),
  loadUser: () => UserActions.fetchUser(dispatch),
  resetError: () => ProfileActions.resetError(dispatch),
  resetUserError: () => UserActions.resetFetchUserError(dispatch),
  actionIsLinkedUser: callback =>
    NFDocumentsToUploadActions.fetchNfDocUploadIsUserLinked(dispatch, callback),
  actionIsANBLinkedUser:  () =>
  NFDeemedNewBornMotivationActions.fetchNfDeemedNewBornIsAnbLoggedinUserLinked(dispatch),
  actionHcrgetpresumptiveuser: () =>
      NFPresumptiveActions.fetchNfHcrgetpresumptiveuserPresumptive(dispatch)
});

/**
 * Controls the rendering of the Application Header.
 *
 * The application header is the banner at the top of the application that
 * includes the title of the application and links such as login, signup or
 * the user menus for logged in users.
 */
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NFApplicationHeaderContainer)
);
