/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

import selectors from './selectors';

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_ridp/profile_info
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfRidpProfileInfo = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfRidpProfileInfo(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfRidpProfileInfo(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfRidpProfileInfo(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_ridp/update_pri_contact
 *
 * postNfRidpUpdatePriContact - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfRidpUpdatePriContact = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_ridp/update_pri_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfRidpUpdatePriContact = useCallback(
    newNFUpdateUserContactDetails => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfRidpUpdatePriContact(
          dispatch,
          newNFUpdateUserContactDetails,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfRidpUpdatePriContact,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_ridp/update_sec_contact
 *
 * postNfRidpUpdateSecContact - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfRidpUpdateSecContact = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_ridp/update_sec_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfRidpUpdateSecContact = useCallback(
    newNFUpdateUserContactDetails => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfRidpUpdateSecContact(
          dispatch,
          newNFUpdateUserContactDetails,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfRidpUpdateSecContact,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_ridp/resend_code_update_contact
 *
 * postNfRidpResendCodeUpdateContact - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfRidpResendCodeUpdateContact = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_ridp/resend_code_update_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfRidpResendCodeUpdateContact = useCallback(
    newNFUpdateUserContactDetails => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfRidpResendCodeUpdateContact(
          dispatch,
          newNFUpdateUserContactDetails,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfRidpResendCodeUpdateContact,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_ridp/update_confirmation_number
 *
 * postNfRidpUpdateConfirmationNumber - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfRidpUpdateConfirmationNumber = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_ridp/update_confirmation_number
   *
   * @param {NewNFUserContactDetails} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfRidpUpdateConfirmationNumber = useCallback(
    newNFUserContactDetails => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfRidpUpdateConfirmationNumber(
          dispatch,
          newNFUserContactDetails,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfRidpUpdateConfirmationNumber,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_ridp/revoke_all
 *
 * postNfRidpRevokeAll - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfRidpRevokeAll = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_ridp/revoke_all
   *
   * @returns {Promise}
   */
  const postNfRidpRevokeAll = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    return new Promise((resolve, reject) => {
      actions.postNfRidpRevokeAll(dispatch, (success, responseData) => {
        if (success) {
          resolve(responseData);
          setResponse({
            data: responseData,
            isFetching: false,
            error: null,
          });
        } else {
          reject(responseData);
          setResponse({
            data: null,
            isFetching: false,
            error: responseData,
          });
        }
      });
    });
  }, [dispatch]);

  return {
    ...response,
    postNfRidpRevokeAll,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/codetables/{table_name}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} table_name.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetCodetablesByTableName = (table_name, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.getCodetablesByTableName(state));

  const resetData = useCallback(
    data => {
      actions.resetGetCodetablesByTableName(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    table_name => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getCodetablesByTableName(dispatch, table_name, (success, data) => {
        setResponse({
          data: success ? data : null,
          isFetching: false,
          error: success ? null : data,
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(table_name);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

export default {
  useFetchNfRidpProfileInfo,
  usePostNfRidpUpdatePriContact,
  usePostNfRidpUpdateSecContact,
  usePostNfRidpResendCodeUpdateContact,
  usePostNfRidpUpdateConfirmationNumber,
  usePostNfRidpRevokeAll,
  useGetCodetablesByTableName,
};
