/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFeNotices.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFENOTICES/FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED`
);

const FETCH_NF_SECURE_INBOX_LIST_COMM = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFENOTICES/FETCH_NF_SECURE_INBOX_LIST_COMM`
);

const POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFENOTICES/POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS`
);

const POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFENOTICES/POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES`
);

const POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFENOTICES/POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES`
);

const POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFENOTICES/POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID`
);

const FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFENOTICES/FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS`
);

const GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFENOTICES/GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID`
);

const POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFENOTICES/POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS`
);

export {
  FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED,
  FETCH_NF_SECURE_INBOX_LIST_COMM,
  POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS,
  POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID,
  FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS,
  GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID,
  POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS,
};
