/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/nfmfa/getMFAQuestions
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfmfaGetMFAQuestionsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nfmfa/getMFAQuestions`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMFAQuestionListResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nfmfa/sendMFACode
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfmfaSendMFACodeUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nfmfa/sendMFACode`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMFACodeResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nfmfa/resendMFACode
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfmfaResendMFACodeUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nfmfa/resendMFACode`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMFACodeResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export { postNfmfaGetMFAQuestionsUtil, postNfmfaSendMFACodeUtil, postNfmfaResendMFACodeUtil };
