/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFCocMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_LIFEEVENT_APPLY_FOR_COC = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_LIFEEVENT_APPLY_FOR_COC`
);

const FETCH_NF_LIFEEVENT_APPLY_FOR_AR = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_LIFEEVENT_APPLY_FOR_AR`
);

const FETCH_NF_COC_UNSUPPORTED_MESSAGE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_COC_UNSUPPORTED_MESSAGE`
);

const POST_LIFE_EVENTS_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/POST_LIFE_EVENTS_FORM`
);

const POST_NF_MOTIVATION_FINISH_COC = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/POST_NF_MOTIVATION_FINISH_COC`
);

const POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC`
);

const GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION`
);

const GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID`
);

const FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS`
);

const FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS`
);

const GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID`
);

const GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID`
);

const POST_NF_MOTIVATION_SETUP_START_MOTIVATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/POST_NF_MOTIVATION_SETUP_START_MOTIVATION`
);

const FETCH_NF_COC_APPLY_FOR_SNAP_COC = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_COC_APPLY_FOR_SNAP_COC`
);

const FETCH_NF_COC_APPLY_FOR_FNS_COC = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOCMOTIVATION/FETCH_NF_COC_APPLY_FOR_FNS_COC`
);

export {
  FETCH_NF_LIFEEVENT_APPLY_FOR_COC,
  FETCH_NF_LIFEEVENT_APPLY_FOR_AR,
  FETCH_NF_COC_UNSUPPORTED_MESSAGE,
  POST_LIFE_EVENTS_FORM,
  POST_NF_MOTIVATION_FINISH_COC,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC,
  GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION,
  GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID,
  FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS,
  FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_APPLY_FOR_SNAP_COC,
  FETCH_NF_COC_APPLY_FOR_FNS_COC,
};
