import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  NFDeemedNewBornMotivationSelectors,
} from '../../modules/generated';
import NFApplicationFooter from './components/NFApplicationFooter';

/**
 * NFApplicationFooterContainer initiates the rendering of the NFApplicationFooter
 * page. This component holds state for the NFApplicationFooter components.
 *
 * @class NFApplicationFooterContainer
 * @extends {Component}
 */
export class NFApplicationFooterContainer extends Component {

  /**
   * Renders the Application Footer page using {@link NFApplicationFooter} or an
   * {@link Alert} if there was a problem loading the data.
   * @returns {@link NFApplicationFooter}
   * @memberof NFApplicationFooter
   */
  render() {
    
    return (
      <NFApplicationFooter {...this.props}/>
    )
  }
}

/**
 * The NFApplicationFooterContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof NFApplicationFooterContainer
 */
NFApplicationFooterContainer.propTypes = {
  /* eslint-disable react/boolean-prop-naming */
  isAnbLinkedUser: PropTypes.bool.isRequired,
};

/**
 * The NFApplicationFooterContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof NFApplicationFooterContainer
 */
NFApplicationFooterContainer.defaultProps = {
  isAnbLinkedUser : false
};

/**
 * Retrieves data from the Redux store.
 *
 * @param state  the redux store state
 * @memberof NFApplicationFooterContainer
 */
const mapStateToProps = state => ({
 isAnbLinkedUser: NFDeemedNewBornMotivationSelectors.fetchNfDeemedNewBornIsAnbLoggedinUserLinked(state)
});



/**
 * Controls the rendering of the Application Footer.
 *
 * The application  is the banner at the bottom of the application that
 * includes the language selection, disclaimer, FAQ
 *
 */
export default withRouter(
  connect(mapStateToProps)(NFApplicationFooterContainer)
);
