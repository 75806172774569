import React, { useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { ProfileModel, Authentication } from '@spm/universal-access';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  Button,
  Header,
  PrimaryNavigation,
  SecondaryNavigation,
  Tabs,
  Tab,
} from '@govhhs/govhhs-design-system-react';
import logo from '../../../images/NCDHHS-LOGO.png';
import { Paths as PATHS } from '@spm/universal-access-ui';
import translations from './NFApplicationHeaderMessages';
import accountTranslations from '../../NFCommon/NFCommonMessages';
import NFSignInMenuComponent from './NFSignInMenuComponent';
import paths from '../../../paths';
import * as NFApplicationHeaderLogic from './NFApplicationHeaderLogic';
import { ID, NAVIGATION_HEADER_TABS } from '../constants';

/**
 * Returns true if the Your Account tab is active for the current url pathname. If
 * true the primary navigation contains specific navigation tabs.
 *
 * @param {*} urlPathname current url pathname.
 * @returns true if tab should be active.
 */
const isYourAccountTabActive = urlPathname =>
  NFApplicationHeaderLogic.isTabActiveForUrlPathname(
    urlPathname,
    NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_YOUR_ACCOUNT.NAME
  );

/* eslint-disable sonarjs/cognitive-complexity */
/**
 * Returns the NFApplicationHeaderComponent.
 *
 * @param {*} props props passed to this component.
 * @returns {Component} NFApplicationHeaderComponent.
 */
const NFApplicationHeaderComponent = props => {
  const {
    intl,
    location,
    profile: userProfile,
    hasErrorBeenCaught,
    history,
    propIsLinkedUser,
    propIsFetchingLinkedUser,
    propIsAnbLinkedUser,
    propHcrgetpresumptiveuser
  } = props;
  const { formatMessage } = intl;
  const { pathname: currentUrlPathname } = location;
  const {
    getActivePrimaryNavigationTabId,
    getActiveSecondaryNavigationTabId,
  } = NFApplicationHeaderLogic;

  const marginDirection = document.dir === 'rtl' ? { marginRight: 'auto' } : { marginLeft: 'auto' };
  const [banner, setBanner] = useState(true);

  const isMFA =
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFARESENDCODE) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAREDIRECT) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFASUCCESS) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAQUESTIONS) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFACONFIRMCODE) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAINCORRECTLOGIN) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAERROR) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAREMEMBERDEVICE) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFATEMPORARILYLOCKED) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAACCOUNTLOCKED) >= 0 ||
    currentUrlPathname.indexOf(paths.NF_MFA_SESSION.NFMFAUNABLELOGIN) >= 0 ||
    currentUrlPathname.indexOf(paths.NFLOGIN) >= 0 ||
    currentUrlPathname.indexOf(paths.NFLOGOUT) >= 0;
  
  const isLinkedUserOrANBLinked= () => 
     (propIsAnbLinkedUser && propIsAnbLinkedUser.isANBUserLinked)
      || (!propIsFetchingLinkedUser && propIsLinkedUser && propIsLinkedUser.nfLinkedInd);
  
  const isPresumptiveUser = propHcrgetpresumptiveuser && propHcrgetpresumptiveuser.nfPresumptiveAllowed && propHcrgetpresumptiveuser.nfBCCMAllowed;
  
  return (
    <div>
      {/* Display this banner in case of disaster (such as Covid 19 outbreak) */}

	  { banner ? (
        <Alert
        actions={[
          <Button key="continue" category="link" onClick={() => 
          /*window.open('https://www.ncdhhs.gov/divisions/social-services/food-and-nutrition-services-food-stamps/fns-food-stamps')*/
          window.open(intl.formatMessage(translations.NFApplicationHeader_continueButtonURL))
        }>
            {intl.formatMessage(translations.NFApplicationHeader_continueButton)}
          </Button>
        ]}
        banner
        onDismiss={() => {
          setBanner(false);
		  }}
      >
      <p>{intl.formatMessage(translations.NFApplicationHeader_bannerAlertCampaigns_Message)}</p>
      </Alert>
      ) : null }

      <Header
        key="application-header"
        data-testid={ID.NFAPPLICATION_HEADER_EPASS_LINK_ID}
        link={<RouterLink to={PATHS.HOME} />}
        logo={
          <img
            alt="agency logo"
            aria-hidden
            data-testid={ID.NFAPPLICATION_HEADER_EPASS_LOGO_ID}
            id={ID.NFAPPLICATION_HEADER_EPASS_LOGO_ID}
            src={logo}
          />
        }
        menuButtonTitle={formatMessage(translations.NFApplicationHeader_headerMenu_Label)}
        title={formatMessage(translations.NFApplicationHeader_ePass_Title)}
        titleHoverText={formatMessage(translations.NFApplicationHeader_headerHome_Label)}
        type="scrollable"
      >
        <PrimaryNavigation type="scrollable">
          {isYourAccountTabActive(currentUrlPathname) && (
            <Tabs
              key="primary-tabs"
              ariaLabel="primary-tabs"
              current={getActivePrimaryNavigationTabId(currentUrlPathname)}
              scrollable
            >
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_DASHBOARD.ID}
                id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_DASHBOARD.ID}
                label={formatMessage(translations.NFApplicationHeader_headerDashboard_Label)}
                link={<RouterLink to={PATHS.ACCOUNT} />}
              />
              {!propIsFetchingLinkedUser && propIsLinkedUser && propIsLinkedUser.nfLinkedInd && (
                <Tab
                  data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_MY_INFORMATION.ID}
                  id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_MY_INFORMATION.ID}
                  label={formatMessage(translations.NFApplicationHeader_headerMyInformation_Label)}
                  link={<RouterLink to={paths.MY_INFO} />}
                />
              )}
              {(propIsAnbLinkedUser && propIsAnbLinkedUser.isANBUserLinked && !isPresumptiveUser) ? (
               <Tab
               data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFYOURREFERRALS.ID}
               id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFYOURREFERRALS.ID}
               label={formatMessage(translations.NFApplicationHeader_headerYourReferrals_Label)}
               link={<RouterLink to={paths.NFYOURREFERRALS} />}
             />
              ) : (
                <Tab
                  data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFYOURAPPLICATIONS.ID}
                  id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFYOURAPPLICATIONS.ID}
                  label={formatMessage(
                    translations.NFApplicationHeader_headerYourApplication_Label
                  )}
                  link={<RouterLink to={paths.NFYOURAPPLICATIONS} />}
                />
              )}
              {(propIsAnbLinkedUser && propIsAnbLinkedUser.isANBUserLinked && !isPresumptiveUser) && (
               <Tab
               data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.ID}
               id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFARCHIVEDREFERRALS.ID}
               label={formatMessage(translations.NFApplicationHeader_headerArchivedReferrals_Label)}
               link={<RouterLink to={paths.NFARCHIVEDREFERRALS} />}
              />)}
              {isLinkedUserOrANBLinked()  && !isPresumptiveUser && (
                <Tab
                  data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.ID}
                  id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_NFACCOUNTSETTINGS.ID}
                  label={formatMessage(translations.NFApplicationHeader_headerAccountSetting_Label)}
                  link={<RouterLink to={paths.NFACCOUNTSETTINGS} />}
                />
              )}
            </Tabs>
          )}
        </PrimaryNavigation>
        <SecondaryNavigation type="scrollable">
          <Tabs
            key="secondary-tabs"
            ariaLabel="secondary-tabs"
            className="wds-u-mr--small custom-header-scrollable"
            current={getActiveSecondaryNavigationTabId(currentUrlPathname)}
          >
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_HOME.ID}
              id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_HOME.ID}
              label={formatMessage(translations.NFApplicationHeader_headerHome_Label)}
              link={<RouterLink to={PATHS.HOME} />}
            />
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_APPLY.ID}
              id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_APPLY.ID}
              label={formatMessage(translations.NFApplicationHeader_headerApply_Label)}
              link={<RouterLink to={paths.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.OVERVIEW} />}
            />
            {userProfile && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_YOUR_ACCOUNT.ID}
                id={NAVIGATION_HEADER_TABS.NFAPPLICATION_HEADER_YOUR_ACCOUNT.ID}
                label={formatMessage(translations.NFApplicationHeader_headerYourAccount_Label)}
                link={<RouterLink to={PATHS.ACCOUNT} />}
              />
            )}
          </Tabs>
          {/* Show profile menu or signed out menu */}

          {!isMFA && userProfile ? (
            <Button
              key="logout"
              category="secondary"
              className="wds-u-mr--small"
              data-testid={ID.NFAPPLICATION_HEADER_MENU_LOGOUT_BUTTON_ID}
              onClick={() => {
                if (process.env.REACT_APP_SIMPLE_AUTH_ON !== 'true') {
                  window.location = ''.concat(
                    'https://',
                    process.env.REACT_APP_DOMAIN,
                    ':',
                    process.env.REACT_APP_DOMAIN_PORT,
                    '/CitizenPortal/NFMFA_resolveLogoutPage.do'
                  );
                } else {
                  Authentication.logout(() => {
                    if (location.pathname !== PATHS.HOME) {
                      history.push(PATHS.HOME);
                    }
                  });
                }
              }}
              size="small"
              style={marginDirection}
            >
              {formatMessage(translations.NFApplicationHeader_headerLogOut_Label)}
            </Button>
          ) : (
            !isMFA && !hasErrorBeenCaught && <NFSignInMenuComponent />
          )}
        </SecondaryNavigation>
        {!isMFA && !userProfile && !hasErrorBeenCaught && (
          <Button
            key="login"
            category="link"
            className="wds-u-hide--nav-width-up"
            data-testid={ID.NFAPPLICATION_HEADER_LOGIN_BUTTON_ID}
            onClick={e => {
              // This is the mobile screen login button
              e.preventDefault();
              props.history.push({
                pathname: PATHS.LOGIN,
                params: { previousPathname: location.pathname },
              });
            }}
            style={marginDirection}
          >
            {formatMessage(translations.NFApplicationHeader_headerLogIn_Label)}
          </Button>
        )}
      </Header>
    </div>
  );
};

/**
 * The NFApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof NFApplicationHeaderComponent
 */
NFApplicationHeaderComponent.propTypes = {
  hasErrorBeenCaught: PropTypes.bool,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  profile: PropTypes.instanceOf(ProfileModel),
  /* eslint-disable react/boolean-prop-naming */
  propIsFetchingLinkedUser: PropTypes.bool,
  propIsLinkedUser: PropTypes.object,
  propIsAnbLinkedUser: PropTypes.object
};

/**
 * The NFApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof NFApplicationHeaderComponent
 */
NFApplicationHeaderComponent.defaultProps = {
  hasErrorBeenCaught: false,
  profile: null,
  propIsFetchingLinkedUser: false,
  propIsLinkedUser: { nfLinkedInd: false },
  propIsAnbLinkedUser: {},
};

export default withRouter(injectIntl(NFApplicationHeaderComponent));
