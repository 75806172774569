/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFSecureMessagesInfo.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSECUREMESSAGESINFO/FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION`
);

const FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSECUREMESSAGESINFO/FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT`
);

const POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSECUREMESSAGESINFO/POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION`
);

const POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSECUREMESSAGESINFO/POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION`
);

const POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSECUREMESSAGESINFO/POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE`
);

export {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION,
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE,
};
