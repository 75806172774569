/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS } from './actionTypes';

import { fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil } from './utils';

/**
 * Contains the action creator functions that update the NFYourInformation slice of the store.
 *
 * @namespace NFYourInformationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_portal_evidence/read_my_info_tab_details
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFYourInformationActions
   */
  static fetchNfCitizenPortalEvidenceReadMyInfoTabDetails = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil,
      FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCitizenPortalEvidenceReadMyInfoTabDetails.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFYourInformationActions
   */
  static resetFetchNfCitizenPortalEvidenceReadMyInfoTabDetails = (dispatch, data) => {
    const json = {
      type: FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCitizenPortalEvidenceReadMyInfoTabDetails.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFYourInformationActions
   */
  static resetFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS.failureAction
    );
  };
}
