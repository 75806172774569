const core = require('@spm/core');

// creates a set of fetch action types
export const NF_IS_LINKED_USER = core.ReduxUtils.generatePostActionTypes('nf', 'isLinkedUSer');
export const NF_SECURE_INBOX_ACTION_TYPES = core.ReduxUtils.generateActionTypes('nf', 'nfSecureInbox');
export const NF_SECURE_INBOX_TOGGLE_EMAIL = core.ReduxUtils.generateActionTypes('nf', 'nfSecureInboxToggleEmail');
export const NF_SECURE_INBOX_UPDATE_EMAIL = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxUpdateEmail');
export const NF_SECURE_INBOX_GET_PRIMARY_CONTACT_DETAILS = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxGetPrimaryContactDetails');
export const NF_SECURE_INBOX_CONFIRM_EMAIL = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxConfirmEmail');
export const NF_SECURE_INBOX_MARK_TO_SEND_BY_MAIL = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxMarkToSendByMail');
export const NF_SECURE_INBOX_SUBSCRIBE = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxSubscribe');
export const NF_SECURE_INBOX_UNSUBSCRIBE = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxUnSubscribe');
export const NF_SECURE_INBOX_IS_SUBSCRIBED = core.ReduxUtils.generatePostActionTypes('nf', 'nfSecureInboxIsSubscribed');
export const NF_SECURE_TOGGLE_SUBSCRIPTION = core.ReduxUtils.generateActionTypes('nf', 'nfSecureInboxToggleSubscription');
export const NF_SECURE_CONFIRM_SUBSCRIPTION = core.ReduxUtils.generateActionTypes('nf', 'nfSecureInboxConfirmSubscription');
