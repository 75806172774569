/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED,
  FETCH_NF_SECURE_INBOX_LIST_COMM,
  POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS,
  POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID,
  FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS,
  GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID,
  POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS,
} from './actionTypes';

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfSecureInboxIsSubscribedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfSecureInboxIsSubscribedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfSecureInboxIsSubscribedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfSecureInboxListCommReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_SECURE_INBOX_LIST_COMM);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfSecureInboxListCommReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_SECURE_INBOX_LIST_COMM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfSecureInboxListCommErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_SECURE_INBOX_LIST_COMM);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSecureInboxPrimaryContactDtlsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSecureInboxPrimaryContactDtlsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSecureInboxPrimaryContactDtlsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSecureInboxSubscribeEnoticesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSecureInboxSubscribeEnoticesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSecureInboxSubscribeEnoticesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSecureInboxUnsubscribeEnoticesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSecureInboxUnsubscribeEnoticesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSecureInboxUnsubscribeEnoticesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSecureInboxSendByMailByCommunicationIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSecureInboxSendByMailByCommunicationIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSecureInboxSendByMailByCommunicationIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID
  );

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfSecureInboxGetMailingAddressReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfSecureInboxGetMailingAddressReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfSecureInboxGetMailingAddressErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS);

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfSecureInboxGetENoticesByCommunicationIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfSecureInboxGetENoticesByCommunicationIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfSecureInboxGetENoticesByCommunicationIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID
  );

/**
 * Updates the details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSecureInboxUpdateENoticeViewStatusReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS);

/**
 * Updates the indicator of creation of details in the 'NFeNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSecureInboxUpdateENoticeViewStatusReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSecureInboxUpdateENoticeViewStatusErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS);

const reducers = combineReducers({
  fetchNfSecureInboxIsSubscribed: fetchNfSecureInboxIsSubscribedReducer,

  isFetchingFetchNfSecureInboxIsSubscribed: isFetchingFetchNfSecureInboxIsSubscribedReducer,
  fetchNfSecureInboxIsSubscribedError: fetchNfSecureInboxIsSubscribedErrorReducer,

  fetchNfSecureInboxListComm: fetchNfSecureInboxListCommReducer,

  isFetchingFetchNfSecureInboxListComm: isFetchingFetchNfSecureInboxListCommReducer,
  fetchNfSecureInboxListCommError: fetchNfSecureInboxListCommErrorReducer,

  postNfSecureInboxPrimaryContactDtls: postNfSecureInboxPrimaryContactDtlsReducer,

  isFetchingPostNfSecureInboxPrimaryContactDtls: isFetchingPostNfSecureInboxPrimaryContactDtlsReducer,
  postNfSecureInboxPrimaryContactDtlsError: postNfSecureInboxPrimaryContactDtlsErrorReducer,

  postNfSecureInboxSubscribeEnotices: postNfSecureInboxSubscribeEnoticesReducer,

  isFetchingPostNfSecureInboxSubscribeEnotices: isFetchingPostNfSecureInboxSubscribeEnoticesReducer,
  postNfSecureInboxSubscribeEnoticesError: postNfSecureInboxSubscribeEnoticesErrorReducer,

  postNfSecureInboxUnsubscribeEnotices: postNfSecureInboxUnsubscribeEnoticesReducer,

  isFetchingPostNfSecureInboxUnsubscribeEnotices: isFetchingPostNfSecureInboxUnsubscribeEnoticesReducer,
  postNfSecureInboxUnsubscribeEnoticesError: postNfSecureInboxUnsubscribeEnoticesErrorReducer,

  postNfSecureInboxSendByMailByCommunicationID: postNfSecureInboxSendByMailByCommunicationIDReducer,

  isFetchingPostNfSecureInboxSendByMailByCommunicationID: isFetchingPostNfSecureInboxSendByMailByCommunicationIDReducer,
  postNfSecureInboxSendByMailByCommunicationIDError: postNfSecureInboxSendByMailByCommunicationIDErrorReducer,

  fetchNfSecureInboxGetMailingAddress: fetchNfSecureInboxGetMailingAddressReducer,

  isFetchingFetchNfSecureInboxGetMailingAddress: isFetchingFetchNfSecureInboxGetMailingAddressReducer,
  fetchNfSecureInboxGetMailingAddressError: fetchNfSecureInboxGetMailingAddressErrorReducer,

  getNfSecureInboxGetENoticesByCommunicationID: getNfSecureInboxGetENoticesByCommunicationIDReducer,

  isFetchingGetNfSecureInboxGetENoticesByCommunicationID: isFetchingGetNfSecureInboxGetENoticesByCommunicationIDReducer,
  getNfSecureInboxGetENoticesByCommunicationIDError: getNfSecureInboxGetENoticesByCommunicationIDErrorReducer,

  postNfSecureInboxUpdateENoticeViewStatus: postNfSecureInboxUpdateENoticeViewStatusReducer,

  isFetchingPostNfSecureInboxUpdateENoticeViewStatus: isFetchingPostNfSecureInboxUpdateENoticeViewStatusReducer,
  postNfSecureInboxUpdateENoticeViewStatusError: postNfSecureInboxUpdateENoticeViewStatusErrorReducer,
});

export default { reducers };
