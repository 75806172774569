/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING,
  POST_NF_MOTIVATION_FINISH_COVID_TESTING,
} from './actionTypes';

import {
  fetchNfMotivationApplyForCovidTestingUtil,
  postNfMotivationFinishCovidTestingUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFCovidTestingMotivation slice of the store.
 *
 * @namespace NFCovidTestingMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/apply_for_covid_testing
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static fetchNfMotivationApplyForCovidTesting = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfMotivationApplyForCovidTestingUtil,
      FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfMotivationApplyForCovidTesting.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static resetFetchNfMotivationApplyForCovidTesting = (dispatch, data) => {
    const json = {
      type: FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfMotivationApplyForCovidTesting.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static resetFetchNfMotivationApplyForCovidTestingError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_covid_testing
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static postNfMotivationFinishCovidTesting = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishCovidTestingUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_COVID_TESTING,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishCovidTesting.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static resetPostNfMotivationFinishCovidTesting = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_COVID_TESTING.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishCovidTesting.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCovidTestingMotivationActions
   */
  static resetPostNfMotivationFinishCovidTestingError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_COVID_TESTING.failureAction);
  };
}
