/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS } from './actionTypes';

/**
 * Updates the details in the 'NFYourInformation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

/**
 * Updates the indicator of creation of details in the 'NFYourInformation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

const reducers = combineReducers({
  fetchNfCitizenPortalEvidenceReadMyInfoTabDetails: fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer,

  isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetails: isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer,
  fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError: fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsErrorReducer,
});

export default { reducers };
