/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION,
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE,
} from './actionTypes';

import {
  fetchNfTextMessageSubscriptionuaGetPersonInformationUtil,
  fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountUtil,
  postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionUtil,
  postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionUtil,
  postNfTextMessageSubscriptionuaUnsubscribeTextMessageUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFSecureMessagesInfo slice of the store.
 *
 * @namespace NFSecureMessagesInfoActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/get_Person_Information
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static fetchNfTextMessageSubscriptionuaGetPersonInformation = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfTextMessageSubscriptionuaGetPersonInformationUtil,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfTextMessageSubscriptionuaGetPersonInformation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetFetchNfTextMessageSubscriptionuaGetPersonInformation = (dispatch, data) => {
    const json = {
      type: FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfTextMessageSubscriptionuaGetPersonInformation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetFetchNfTextMessageSubscriptionuaGetPersonInformationError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/unread_SecureMessages_Count
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountUtil,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount = (dispatch, data) => {
    const json = {
      type: FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/create_textMessage_subscription
   *
   * @param {NewNFSubscribeTextMessage} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static postNfTextMessageSubscriptionuaCreateTextMessageSubscription = (
    dispatch,
    newNFSubscribeTextMessage,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionUtil.bind(
        null,
        newNFSubscribeTextMessage
      ),
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfTextMessageSubscriptionuaCreateTextMessageSubscription.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaCreateTextMessageSubscription = (dispatch, data) => {
    const json = {
      type: POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfTextMessageSubscriptionuaCreateTextMessageSubscription.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaCreateTextMessageSubscriptionError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/update_TextMessage_Subscription
   *
   * @param {NewNFSubscribeTextMessage} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static postNfTextMessageSubscriptionuaUpdateTextMessageSubscription = (
    dispatch,
    newNFSubscribeTextMessage,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionUtil.bind(
        null,
        newNFSubscribeTextMessage
      ),
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfTextMessageSubscriptionuaUpdateTextMessageSubscription.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaUpdateTextMessageSubscription = (dispatch, data) => {
    const json = {
      type: POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfTextMessageSubscriptionuaUpdateTextMessageSubscription.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/unsubscribe_textMessage
   *
   * @param {NewNFSubscriptionKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static postNfTextMessageSubscriptionuaUnsubscribeTextMessage = (
    dispatch,
    newNFSubscriptionKey,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfTextMessageSubscriptionuaUnsubscribeTextMessageUtil.bind(null, newNFSubscriptionKey),
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfTextMessageSubscriptionuaUnsubscribeTextMessage.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaUnsubscribeTextMessage = (dispatch, data) => {
    const json = {
      type: POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfTextMessageSubscriptionuaUnsubscribeTextMessage.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecureMessagesInfoActions
   */
  static resetPostNfTextMessageSubscriptionuaUnsubscribeTextMessageError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE.failureAction
    );
  };
}
