/*
 * LanguageDropdown Messages
 *
 * This contains all the text for the LanguageDropdown component.
 * This uses Google Translate, blame Google.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  dropdownLabel: {
    id: 'Dropdown_Label',
    defaultMessage: 'Change Your Language',
  },
});
