/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFPresumptiveMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MOTIVATION_SETUP_AND_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPRESUMPTIVEMOTIVATION/POST_MOTIVATION_SETUP_AND_START`
);

const FETCH_NF_MOTIVATION_PRESUMPTIVE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFPRESUMPTIVEMOTIVATION/FETCH_NF_MOTIVATION_PRESUMPTIVE`
);

const GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFPRESUMPTIVEMOTIVATION/GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID`
);

const POST_NF_MOTIVATION_FINISH_MOTIVATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPRESUMPTIVEMOTIVATION/POST_NF_MOTIVATION_FINISH_MOTIVATION`
);

const FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFPRESUMPTIVEMOTIVATION/FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE`
);

export {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_PRESUMPTIVE,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE,
};
