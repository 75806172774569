/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the NewNFUserContactDetailsResponse
 *
 * @class NewNFUserContactDetailsResponse
 */
class NewNFUserContactDetailsResponse {
  /**
   * Creates an instance of NewNFUserContactDetailsResponse.
   * @param {*} json
   * @memberof NewNFUserContactDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // string
      this.emailAddress = json.emailAddress;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.playerID = json.playerID;
    }
  }
}

/**
 * Models the NewNFMassCommDetailsForePass
 *
 * @class NewNFMassCommDetailsForePass
 */
class NewNFMassCommDetailsForePass {
  /**
   * Creates an instance of NewNFMassCommDetailsForePass.
   * @param {*} json
   * @memberof NewNFMassCommDetailsForePass
   */
  constructor(json = null) {
    if (json) {
      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.newPhoneNumber = json.newPhoneNumber;

      // integer
      this.versionNo = json.versionNo;

      // string
      this.phoneNumberID = json.phoneNumberID;

      // string
      this.newEmailAddress = json.newEmailAddress;

      // string
      this.partcipantEmailID = json.partcipantEmailID;

      // string
      this.phoneNumberString = json.phoneNumberString;

      // string
      this.userName = json.userName;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // object - CodeTableType
      if (json.selectPrimaryContactValue) {
        this.selectPrimaryContactValue = new CodeTableType(json.selectPrimaryContactValue);
      }

      // string
      this.ePassPhoneNumber = json.ePassPhoneNumber;

      // string
      this.ePassEmailAddress = json.ePassEmailAddress;
    }
  }
}

/**
 * Models the NewNFMassCommResponseResponse
 *
 * @class NewNFMassCommResponseResponse
 */
class NewNFMassCommResponseResponse {
  /**
   * Creates an instance of NewNFMassCommResponseResponse.
   * @param {*} json
   * @memberof NewNFMassCommResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NewNFUserContactDetails
 *
 * @class NewNFUserContactDetails
 */
class NewNFUserContactDetails {
  /**
   * Creates an instance of NewNFUserContactDetails.
   * @param {*} json
   * @memberof NewNFUserContactDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // string
      this.emailAddress = json.emailAddress;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.playerID = json.playerID;
    }
  }
}

/**
 * Models the NewNFMassCommePassUserName
 *
 * @class NewNFMassCommePassUserName
 */
class NewNFMassCommePassUserName {
  /**
   * Creates an instance of NewNFMassCommePassUserName.
   * @param {*} json
   * @memberof NewNFMassCommePassUserName
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;
    }
  }
}

export default {
  CodeTableType,

  NewNFUserContactDetailsResponse,

  NewNFMassCommDetailsForePass,

  NewNFMassCommResponseResponse,

  NewNFUserContactDetails,

  NewNFMassCommePassUserName,
};
