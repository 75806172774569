import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Dropdown, DropdownOption } from '@govhhs/govhhs-design-system-react';
import LanguageDropdownMessages from './NFLanguageDropdownMessages';

/**
 * The NFLanguageDropdownComponent component handles the language dropdown content
 * containing the supported languages.
 *
 * @param props The component properties.
 *
 * @namespace LanguageDropdown
 * @memberof features.LanguageDropdown/components
 */
const NFLanguageDropdownComponent = ({ locales, intl, onLanguageToggle, language }) => {
  const content = locales.map((value, index) => {
    const { locale, displayName } = value;
    return (
      <DropdownOption key={locale} data-testid={`dropdown-language-${index}`} value={locale}>
        {displayName}
      </DropdownOption>
    );
  });

  return (
    locales.length !== 0 && (
      <Dropdown
        ariaLabel={intl.formatMessage(LanguageDropdownMessages.dropdownLabel)}
        onChange={onLanguageToggle}
        value={language}
      >
        {content}
      </Dropdown>
    )
  );
};

NFLanguageDropdownComponent.defaultProps = {
  language: 'en',
};

NFLanguageDropdownComponent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.string,
  locales: PropTypes.array.isRequired,
  onLanguageToggle: PropTypes.func.isRequired,
};

export default injectIntl(NFLanguageDropdownComponent);
