/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the UAProgramPdfForm
 *
 * @class UAProgramPdfForm
 */
class UAProgramPdfForm {
  /**
   * Creates an instance of UAProgramPdfForm.
   * @param {*} json
   * @memberof UAProgramPdfForm
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pdfFormId = json.pdfFormId;
    }
  }
}

/**
 * Models the UAProgram
 *
 * @class UAProgram
 */
class UAProgram {
  /**
   * Creates an instance of UAProgram.
   * @param {*} json
   * @memberof UAProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.associatedPendingApplicationId = json.associatedPendingApplicationId;

      // string
      this.icon = json.icon;

      // array - UAProgramPdfForm
      if (json.pdfFormIds) {
        this.pdfFormIds = json.pdfFormIds.map(object => new UAProgramPdfForm(object));
      } else if (json.constructor === Array) {
        this.pdfFormIds = json.map(object => new UAProgramPdfForm(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAWithdrawalReason
 *
 * @class UAWithdrawalReason
 */
class UAWithdrawalReason {
  /**
   * Creates an instance of UAWithdrawalReason.
   * @param {*} json
   * @memberof UAWithdrawalReason
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }
    }
  }
}

/**
 * Models the UAIntakeApplicationType
 *
 * @class UAIntakeApplicationType
 */
class UAIntakeApplicationType {
  /**
   * Creates an instance of UAIntakeApplicationType.
   * @param {*} json
   * @memberof UAIntakeApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.iegScriptId = json.iegScriptId;

      // boolean
      this.individualProgramSelectionAllowed = json.individualProgramSelectionAllowed;

      // string
      this.iegSubmissionScriptId = json.iegSubmissionScriptId;

      // string
      this.datastoreSchema = json.datastoreSchema;

      // string
      this.name = json.name;

      // string
      this.summary = json.summary;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.pdfFormId = json.pdfFormId;

      // string
      this.associatedDraftApplicationId = json.associatedDraftApplicationId;

      // string
      this.url = json.url;

      // boolean
      this.submitOnCompletionInd = json.submitOnCompletionInd;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }

      // array - UAWithdrawalReason
      if (json.allowedWithdrawalReasons) {
        this.allowedWithdrawalReasons = json.allowedWithdrawalReasons.map(
          object => new UAWithdrawalReason(object)
        );
      } else if (json.constructor === Array) {
        this.allowedWithdrawalReasons = json.map(object => new UAWithdrawalReason(object));
      }
    }
  }
}

/**
 * Models the NewUAOnlineApplicationProgram
 *
 * @class NewUAOnlineApplicationProgram
 */
class NewUAOnlineApplicationProgram {
  /**
   * Creates an instance of NewUAOnlineApplicationProgram.
   * @param {*} json
   * @memberof NewUAOnlineApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;
    }
  }
}

/**
 * Models the NewUAOnlineApplicationType
 *
 * @class NewUAOnlineApplicationType
 */
class NewUAOnlineApplicationType {
  /**
   * Creates an instance of NewUAOnlineApplicationType.
   * @param {*} json
   * @memberof NewUAOnlineApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.screening_form_id = json.screening_form_id;

      // array - NewUAOnlineApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new NewUAOnlineApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new NewUAOnlineApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the NewNFStartLIEAPIEGResponseResponse
 *
 * @class NewNFStartLIEAPIEGResponseResponse
 */
class NewNFStartLIEAPIEGResponseResponse {
  /**
   * Creates an instance of NewNFStartLIEAPIEGResponseResponse.
   * @param {*} json
   * @memberof NewNFStartLIEAPIEGResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // string
      this.message = json.message;

      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the UADraftApplicationProgram
 *
 * @class UADraftApplicationProgram
 */
class UADraftApplicationProgram {
  /**
   * Creates an instance of UADraftApplicationProgram.
   * @param {*} json
   * @memberof UADraftApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UADraftApplication
 *
 * @class UADraftApplication
 */
class UADraftApplication {
  /**
   * Creates an instance of UADraftApplication.
   * @param {*} json
   * @memberof UADraftApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.createdOn = json.createdOn;

      // string
      this.lastUpdatedOn = json.lastUpdatedOn;

      // string
      this.draft_application_id = json.draft_application_id;

      // boolean
      this.isSubmissionForm = json.isSubmissionForm;

      // boolean
      this.isMotivation = json.isMotivation;

      // string
      this.motivationID = json.motivationID;

      // array - UADraftApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UADraftApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UADraftApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the UADraftApplicationList
 *
 * @class UADraftApplicationList
 */
class UADraftApplicationList {
  /**
   * Creates an instance of UADraftApplicationList.
   * @param {*} json
   * @memberof UADraftApplicationList
   */
  constructor(json = null) {
    if (json) {
      // array - UADraftApplication
      if (json.data) {
        this.data = json.data.map(object => new UADraftApplication(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UADraftApplication(object));
      }
    }
  }
}

export default {
  UAProgramPdfForm,

  UAProgram,

  CodeTableType,

  UAWithdrawalReason,

  UAIntakeApplicationType,

  NewUAOnlineApplicationProgram,

  NewUAOnlineApplicationType,

  NewNFStartLIEAPIEGResponseResponse,

  UADraftApplicationProgram,

  UADraftApplication,

  UADraftApplicationList,
};
