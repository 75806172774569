/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthenticationSelectors } from '@spm/core';
import { AppSpinner } from '@spm/core-ui';

/**
 * NFSAMLRedirect
 */
class NFSAMLRedirect extends Component {
  /**
   * The AuthenticatedRoute component. See further documentation below.
   */
  render() {
    const { userAccount } = this.props;
    if (
      !userAccount ||
      (userAccount.userType !== 'STANDARD' && userAccount.userType !== 'LINKED')
    ) {
      return <AppSpinner />;
    }
    return (
      <Redirect
        to={{
          pathname: `/account`
        }}
      />
    );
  }
}

NFSAMLRedirect.propTypes = {
  location: PropTypes.object,
  userAccount: PropTypes.object
};
NFSAMLRedirect.defaultProps = {
  location: null,
  userAccount: null
};
/**
 *
 * @param {*} state
 */
const mapStateToProps = state => ({
  userAccount: AuthenticationSelectors.getUserAccount(state)
});

/**
 * Handles the authorization of a page based on the specified user types that are passed
 * as an input prop. If the current user is authorized to access the requested page, then they
 * can access the page directly. Otherwise they will be redirected to the login page.
 *
 * @param {Array} authUserTypes The list of authentication user types. It is mandatory.
 * @param {string} title A react-intl message that will dynamically prefix the application title with the page title
 * in order to provide a meaningful page title. It is mandatory.
 *
 */
export default withRouter(connect(mapStateToProps)(NFSAMLRedirect));
