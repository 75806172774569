/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFPersonInformationDetails
 *
 * @class NFPersonInformationDetails
 */
class NFPersonInformationDetails {
  /**
   * Creates an instance of NFPersonInformationDetails.
   * @param {*} json
   * @memberof NFPersonInformationDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.phoneNumberInd = json.phoneNumberInd;

      // string
      this.phoneNumberID = json.phoneNumberID;

      // boolean
      this.subscriptionInd = json.subscriptionInd;

      // string
      this.phoneNumber = json.phoneNumber;

      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NFUnreadSecureMessagesCount
 *
 * @class NFUnreadSecureMessagesCount
 */
class NFUnreadSecureMessagesCount {
  /**
   * Creates an instance of NFUnreadSecureMessagesCount.
   * @param {*} json
   * @memberof NFUnreadSecureMessagesCount
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // integer
      this.nfUnreadSecureMessagesCount = json.nfUnreadSecureMessagesCount;
    }
  }
}

/**
 * Models the NewNFSubscribeTextMessage
 *
 * @class NewNFSubscribeTextMessage
 */
class NewNFSubscribeTextMessage {
  /**
   * Creates an instance of NewNFSubscribeTextMessage.
   * @param {*} json
   * @memberof NewNFSubscribeTextMessage
   */
  constructor(json = null) {
    if (json) {
      // string
      this.subscriptionID = json.subscriptionID;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.phoneNumber = json.phoneNumber;

      // boolean
      this.isContactID = json.isContactID;
    }
  }
}

/**
 * Models the NewNFTextMessageResponseResponse
 *
 * @class NewNFTextMessageResponseResponse
 */
class NewNFTextMessageResponseResponse {
  /**
   * Creates an instance of NewNFTextMessageResponseResponse.
   * @param {*} json
   * @memberof NewNFTextMessageResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.subscriptionID = json.subscriptionID;
    }
  }
}

/**
 * Models the NewNFSubscriptionKey
 *
 * @class NewNFSubscriptionKey
 */
class NewNFSubscriptionKey {
  /**
   * Creates an instance of NewNFSubscriptionKey.
   * @param {*} json
   * @memberof NewNFSubscriptionKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfSubscriptionID = json.nfSubscriptionID;
    }
  }
}

export default {
  NFPersonInformationDetails,

  NFUnreadSecureMessagesCount,

  NewNFSubscribeTextMessage,

  NewNFTextMessageResponseResponse,

  NewNFSubscriptionKey,
};
