/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFMotivationStandaloneRDIP.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MOTIVATION_SETUP_AND_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMOTIVATIONSTANDALONERDIP/POST_MOTIVATION_SETUP_AND_START`
);

const FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFMOTIVATIONSTANDALONERDIP/FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP`
);

const GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFMOTIVATIONSTANDALONERDIP/GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID`
);

const POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMOTIVATIONSTANDALONERDIP/POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP`
);

const DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID = ReduxUtils.generateActionTypes(
  'ua-library',
  `NFMOTIVATIONSTANDALONERDIP/DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID`,
  'DELETE'
);

export {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
};
