import { defineMessages } from 'react-intl';

export default defineMessages({
  NFCommon_continueButton: {
    id: 'NFCommon_continueButton',
    defaultMessage: 'Continue',
  },
  NFCommon_yourAccount_SubTitle: {
    id: 'NFCommon_yourAccount_SubTitle',
    defaultMessage: 'Your account',
  },
  NFCommon_applyForBenefit_Title: {
    id: 'NFCommon_applyForBenefit_Title',
    defaultMessage: 'Apply for benefits',
  },
  NFCommon_backButton_Label: {
    id: 'NFCommon_backButton_Label',
    defaultMessage: 'Back',
  },
  NFCommon_YourInfo_ReportChanges_Button: {
    id: 'NFCommon_YourInfo_ReportChanges_Button',
    defaultMessage: 'Report changes',
  },
  NFCommon_Confirm_Label: {
    id: 'NFCommon_Confirm_Label',
    defaultMessage: 'Confirm',
  },
  NFCommon_saveButton_Label: {
    id: 'NFCommon_saveButton_Label',
    defaultMessage: 'Save',
  },
  NFCommon_Required_Label: {
    id: 'NFCommon_Required_Label',
    defaultMessage: 'Required',
  },
  NFCommon_Cancel_Label: {
    id: 'NFCommon_Cancel_Label',
    defaultMessage: 'Cancel',
  },
  NFCommon_Yes_Label: {
    id: 'NFCommon_Yes_Label',
    defaultMessage: 'Yes',
  },
  NFCommon_No_Label: {
    id: 'NFCommon_No_Label',
    defaultMessage: 'No',
  },
  NFCommon_securityOfAccount_Description: {
    id: 'NFCommon_securityOfAccount_Description',
    defaultMessage:
      'To ensure the security of your account, a 6-digit security code will be sent to the contact method you prefer.',
  },
  NFCommon_select_Label: {
    id: 'NFCommon_select_Label',
    defaultMessage: '-- Select Please--',
  },
  NFCommon_selectContactError_Label: {
    id: 'NFCommon_selectContactError_Label',
    defaultMessage: 'Please select contact method',
  },
  NFCommon_enterEmailError_Label: {
    id: 'NFCommon_enterEmailError_Label',
    defaultMessage: 'An email address must be entered',
  },
  NFCommon_emailAddress_Label: {
    id: 'NFCommon_emailAddress_Label',
    defaultMessage: 'Email address:',
  },
  NFCommon_enterPhoneNumberError_Label: {
    id: 'NFCommon_enterPhoneNumberError_Label',
    defaultMessage: 'A phone number must be entered',
  },
  NFCommon_mobilePhoneNumber_Label: {
    id: 'NFCommon_mobilePhoneNumber_Label',
    defaultMessage: 'Mobile Phone Number:',
  },
  NFCommon_confirmCode_Security_Description: {
    id: 'NFCommon_confirmCode_Security_Description',
    defaultMessage:
      'A 6-digit security code has been sent to {primary/secondary_method}. Please enter it below.',
  },
  NFCommon_resend_Code_Description: {
    id: 'NFCommon_resend_Code_Description',
    defaultMessage:
      'The security code sent to {primary/secondary_method} may take a minute or two to receive. If you have waited a minute or two and still have not received a code, select ‘Resend’ below to send a new security code. Make sure you use the most recent code sent to you. Every time that you select to resend a code, the prior code sent will be cancelled out and only the most recent code is valid.',
  },
  NFCommon_confirmation_Security_Title: {
    id: 'NFCommon_confirmation_Security_Title',
    defaultMessage: 'Confirm Security Code',
  },
  NFCommon_emailNotShowUp_Label: {
    id: 'NFCommon_emailNotShowUp_Label',
    defaultMessage:
      'If the email does not show up in your inbox, check your spam or junk folder.',
  },
  NFCommon_enterValidCode_Label: {
    id: 'NFCommon_enterValidCode_Label',
    defaultMessage: 'Please enter valid confirmation code',
  },
  NFCommon_securityCodeQuestion_Label: {
    id: 'NFCommon_securityCodeQuestion_Label',
    defaultMessage: 'Didn’t get a security code? ',
  },
  NFCommon_resend_button: {
    id: 'NFCommon_resend_button',
    defaultMessage: 'Resend',
  },
  NFCommon_resendCode_Title: {
    id: 'NFCommon_resendCode_Title',
    defaultMessage: 'Resend Security Code',
  },
  NFCommon_resendCode_Link: {
    id: 'NFCommon_resendCode_Link',
    defaultMessage: 'Resend code',
  },
  NFCommon_Ok_button: {
    id: 'NFCommon_Ok_button',
    defaultMessage: 'OK',
  },
  NFCommon_contactMethod_Updated_Text: {
    id: 'NFCommon_contactMethod_Updated_Text',
    defaultMessage: 'Your contact method was successfully updated!',
  },
  NFCommon_securityCode_Label: {
    id: 'NFCommon_securityCode_Label',
    defaultMessage: 'Security Code:',
  },
  NFCommon_emailHint_Text: {
    id: 'NFCommon_emailHint_Text',
    defaultMessage: 'john_smith@example.com',
  },
  NFCommon_forExample_Text: {
    id: 'NFCommon_forExample_Text',
    defaultMessage: 'For example: ',
  },
  NFCommon_phoneNumberHint_Text: {
    id: 'NFCommon_phoneNumberHint_Text',
    defaultMessage: '919-123-1234',
  },
  NFCommon_update_Text: {
    id: 'NFCommon_update_Text',
    defaultMessage: 'Update',
  },
  NFCommon_add_Text: {
    id: 'NFCommon_add_Text',
    defaultMessage: 'Add',
  },
  NFCommon_subscribeToENotices_Text: {
    id: 'NFCommon_subscribeToENotices_Text',
    defaultMessage: 'Subscribe to eNotices',
  },
  NFCommon_subscribeButton_Text: {
    id: 'NFCommon_subscribeButton_Text',
    defaultMessage: 'Subscribe',
  },
  NFCommon_needPhoneOrEmail_Description: {
    id: 'NFCommon_needPhoneOrEmail_Description',
    defaultMessage: `
    To subscribe to eNotices, you need a mobile phone that can receive text messages or a valid email account.`,
  },
  NFCommon_contactMethodNoLongerInUse_Description: {
    id: 'NFCommon_contactMethodNoLongerInUse_Description',
    defaultMessage: `If this contact method is no longer in use or you want to use a different mobile phone or email, update your contact method on the`,
  },
  NFCommon_contactMethodOnFile_Description: {
    id: 'NFCommon_contactMethodOnFile_Description',
    defaultMessage: `The contact method we have on file for your ePASS account is {email}. If this is correct, continue to select ‘Subscribe’.`,
  },
  NFCommon_profileLink_Text: {
    id: 'NFCommon_profileLink_Text',
    defaultMessage: `Account settings`,
  },
  NFCommon_eNoticesFAQ_Text: {
    id: 'NFCommon_eNoticesFAQ_Text',
    defaultMessage: 'eNotices FAQs',
  },
  NFCommon_TextSecureMessageFAQ_Text: {
    id: 'NFCommon_TextSecureMessageFAQ_Text',
    defaultMessage: 'Text and Secure Messaging FAQs',
  },
  NFCommon_subscriptionConfirmation_Description: {
    id: 'NFCommon_subscriptionConfirmation_Description',
    defaultMessage:
      'You are now subscribed to eNotices. You may unsubscribe at any time.',
  },
  NFCommon_subscriptionConfirmation_Title: {
    id: 'NFCommon_subscriptionConfirmation_Title',
    defaultMessage: 'Your eNotice subscription is confirmed!',
  },
  NFCommon_Warning_Title: {
    id: 'NFCommon_Warning_Title',
    defaultMessage: 'Warning',
  },
  NFCommon_Warning_lang_description: {
    id: 'NFCommon_Warning_lang_description',
    defaultMessage:
      "Your application PDF will generate in English. If you wish to view the PDF in another language, please click 'Cancel' and select a different language in the dropdown at the bottom of the page.",
  },
  NFCommon_Warning_description: {
    id: 'NFCommon_Warning_description',
    defaultMessage:
      'The document you are about to download is likely to have personal information in it. Some browsers, such as Chrome, Safari, and Firefox, may temporarily store this information in a cache. Make sure to clear your browser’s cache if you are using a public computer and don’t want other people to access it.',
  },
  NFCommon_Exit_Text: {
    id: 'NFCommon_Exit_Text',
    defaultMessage: 'Exit',
  },
  NFCommon_LearnMore_Title: {
    id: 'NFCommon_LearnMore_Title',
    defaultMessage: 'Learn more',
  },
  NFCommon_Applying_On_Behalf_Of_Someone: {
    id: 'NFCommon_Applying_On_Behalf_Of_Someone',
    defaultMessage: 'Applying on behalf of someone else?',
  },
  NFCommon_oneOrMoreErrors_Text: {
    id: 'NFCommon_oneOrMoreErrors_Text',
    defaultMessage: 'There are one or more errors on this page:',
  },
  NFCommon_Q_tag: {
    id: 'NFCommon_Q_tag',
    defaultMessage: 'Q:',
  },
  NFCommon_A_tag: {
    id: 'NFCommon_A_tag',
    defaultMessage: 'A:',
  },
  NFCommon_Print_text: {
    id: 'NFCommon_Print_text',
    defaultMessage: 'Print',
  },
  NFCommon_mobileHint_Text: {
    id: 'NFCommon_mobileHint_Text',
    defaultMessage: 'Text message and data rates may apply.',
  },
  NFCommon_Close_Text: {
    id: 'NFCommon_Close_Text',
    defaultMessage: 'Close',
  },
  NFCommon_Required_Text: {
    id: 'NFCommon_Required_Text',
    defaultMessage: 'Required',
  },
  NFDisaster_Apply: {
    id: 'NFDisaster_Apply',
    defaultMessage: 'Preregister for Disaster Food and Nutrition Services'
  },
  NFDisaster_Description: {
    id: 'NFDisaster_Description',
    defaultMessage:
      'Receive food assistance if your household is affected by the recent disaster.'
  },
  NFCovid_Apply: {
    id: 'NFCovid_Apply',
    defaultMessage: 'Get help paying for COVID-19 testing, vaccines and treatment',
  },
  NFCovid_Costs: {
    id: 'NFCovid_Costs',
    defaultMessage: 'Apply for benefits to cover the costs associated with COVID-19 testing, vaccines and treatment.',
  },
  NFCommon_Apply_Text: {
    id: 'NFCommon_Apply_Text',
    defaultMessage: 'Apply',
  },
  NFApplyForAssistance_Spanish_User_Title_One: {
    id: 'NFApplyForAssistance_Spanish_User_Title_One',
    defaultMessage: 'Si necesita presentar su solicitud en español, haga {NFApplyForAssistance_Spanish_User_Title_Link} para obtener la versión en papel del PDF de la solicitud en español.'
  },
  NFApplyForAssistance_Spanish_User_Title_Link: {
    id: 'NFApplyForAssistance_Spanish_User_Title_Link',
    defaultMessage: 'https://files.nc.gov/ncdma/covid-19/COVID19-Testing-Group-App-Spanish.pdf'
  },
  NFApplyForAssistance_Spanish_User_Title_LinkText: {
    id: 'NFApplyForAssistance_Spanish_User_Title_LinkText',
    defaultMessage: 'clic aquí'
  },
  NFApplyForAssistance_Spanish_User_Title_DSNAP_Link: {
    id: 'NFApplyForAssistance_Spanish_User_Title_DSNAP_Link',
    defaultMessage: 'https://policies.ncdhhs.gov/divisional/social-services/forms/spanish/dss-1432sp-division-de-servicios-sociales-de-carolina-del-norte-solicitud-de-servicios-de-alimento-y-nutricion-por-desastre/@@display-file/form_file/DSS-1432sp-ia.pdf'
  },
  NFCommon_Search_Button: {
    id: 'NFCommon_Search_Button',
    defaultMessage: 'Search',
  },
  NFCommon_Reset_Button: {
    id: 'NFCommon_Reset_Button',
    defaultMessage: 'Reset',
  },
  NFCommon_Search_Filter_Validation: {
    id: 'NFCommon_Search_Filter_Validation',
    defaultMessage: 'Please choose an option from the "Search by" dropdown to conduct this search.',
  },
  NFCommon_Search_Filter_Validation_1: {
    id: 'NFCommon_Search_Filter_Validation_1',
    defaultMessage: 'Please enter the search details.',
  },
  NFCommon_Search_Filter_Validation_2: {
    id: 'NFCommon_Search_Filter_Validation_2',
    defaultMessage: 'Please enter at least three characters for your search.',
  },
  NFCommon_Search_Filter_Validation_3: {
    id: 'NFCommon_Search_Filter_Validation_3',
    defaultMessage: 'Medicaid ID numbers must contain 10 digits.',
  },
  NFCommon_Search_Filter_Validation_4: {
    id: 'NFCommon_Search_Filter_Validation_4',
    defaultMessage: 'Dates must be entered as “mm/dd/yyyy”.',
  },
  NFCommon_Search_Filter_Validation_DSS: {
    id: 'NFCommon_Search_Filter_Validation_DSS',
    defaultMessage: 'DSS code must contain 3 digits.',
  },
  NFCommon_pagination_prevPage: {
    id: 'NFCommon_pagination_prevPage',
    defaultMessage: 'Previous Page',
  },
  NFCommon_pagination_nextPage: {
    id: 'NFCommon_pagination_nextPage',
    defaultMessage: 'Next Page',
  },
  NFCommon_referralStatus_submitted: {
    id: 'NFCommon_referralStatus_submitted',
    defaultMessage: 'Submitted',
  },
  NFCommon_referralStatus_approved: {
    id: 'NFCommon_referralStatus_approved',
    defaultMessage: 'Approved',
  },
  NFCommon_referralStatus_withdrawn: {
    id: 'NFCommon_referralStatus_withdrawn',
    defaultMessage: 'Withdrawn',
  },
  NFCommon_referralStatus_denied: {
    id: 'NFCommon_referralStatus_denied',
    defaultMessage: 'Denied',
  },
  authRepconfirmationErrorText1: {
    id: 'authRepconfirmationErrorText1',
    defaultMessage: 'There are one or more issues:'
  },
  authRepconfirmationErrorText2: {
    id: 'authRepconfirmationErrorText2',
    defaultMessage:
      'Files with an error cannot be saved. Click the ‘X’ on the file(s) with an error to delete it.'
  },
  authRepconfirmationErrorText3: {
    id: 'authRepconfirmationErrorText3',
    defaultMessage: 'The file you selected is already added.'
  },
  authRepconfirmationErrorText4: {
    id: 'authRepconfirmationErrorText4',
    defaultMessage: 'The file you selected is already listed below and was not uploaded.'
  },
  authRepconfirmationText1: {
    id: 'authRepconfirmationText1',
    defaultMessage:
      'Authorized Representatives for Medical Assistance must show proof of being an authorized representative.'
  },
  authRepconfirmationText2: {
    id: 'authRepconfirmationText2',
    defaultMessage:
      'An authorized representative for Medical Assistance is someone who has written permission to help with this application, such as a family member or other trusted person. Some authorized representatives may have legal authority to make decisions for the applicant.'
  },
  authRepconfirmationText3: {
    id: 'authRepconfirmationText3',
    defaultMessage:
      'You can upload a document for proof or bring it in person to your local County Department of Social Services.'
  },
  authRepconfirmationHintText: {
    id: 'authRepconfirmationHintText',
    defaultMessage:
      'Only image files with .pdf, .tif, .bmp, .jpg or .jpeg extensions. 30MB max file size.'
  },
  authRepconfirmationAddButtonText: {
    id: 'authRepconfirmationAddButtonText',
    defaultMessage: 'Add Authorized Representative file'
  },
  addMoreFilesButtonText: {
    id: 'addMoreFilesButtonText',
    defaultMessage: 'Add More Files'
  },
  addFilesButtonText: {
    id: 'addFilesButtonText',
    defaultMessage: 'Add Files'
  },
  authRepconfirmationLable: {
    id: 'authRepconfirmationLable',
    defaultMessage: 'Next steps'
  },
  authRepconfirmationTitle: {
    id: 'authRepconfirmationTitle',
    defaultMessage: 'Verification of Authorized Representative for Medical Assistance'
  },
   authRepconfirmationCloseButton: {
    id: 'authRepconfirmationCloseButton',
    defaultMessage: 'Close'
  },
  authRepconfirmationCloseAndReviewButton: {
    id: 'authRepconfirmationCloseAndReviewButton',
    defaultMessage: 'Submit files and Close'
  },
  New_Text: {
    id: 'New_Text',
    defaultMessage: 'New'
  },
  NFMXP_Apply: {
    id: 'NFMXP_Apply',
    defaultMessage: 'Looking for information on Medicaid Expansion? Select "Continue" for details and to start the application process.',
  },
  NFCommon_save_and_exit_confirmation_submission_title: {
    id: 'NFCommon_save_and_exit_confirmation_submission_title',
    defaultMessage: 'Confirm submission'
  },
  NFCommon_save_and_exit_confirmation_submission_1: {
    id: 'NFCommon_save_and_exit_confirmation_submission_1',
    defaultMessage: 'You have not entered all the required fields for this screen. If you continue to submit your application without entering information in the required fields, then any information you have entered on this screen will be lost.'
  },
  NFCommon_save_and_exit_confirmation_submission_2: {
    id: 'NFCommon_save_and_exit_confirmation_submission_2',
    defaultMessage: 'Do you want to continue with your application submission?'
  }
});
