/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewNFCOCConfig
 *
 * @class NewNFCOCConfig
 */
class NewNFCOCConfig {
  /**
   * Creates an instance of NewNFCOCConfig.
   * @param {*} json
   * @memberof NewNFCOCConfig
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfCOCConfigID = json.nfCOCConfigID;

      // string
      this.cocTypeCode = json.cocTypeCode;

      // string
      this.processID = json.processID;
    }
  }
}

/**
 * Models the NewNFCOCFormIdResponse
 *
 * @class NewNFCOCFormIdResponse
 */
class NewNFCOCFormIdResponse {
  /**
   * Creates an instance of NewNFCOCFormIdResponse.
   * @param {*} json
   * @memberof NewNFCOCFormIdResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegScriptExecutionID = json.iegScriptExecutionID;
    }
  }
}

/**
 * Models the NFCOCConfigResponse
 *
 * @class NFCOCConfigResponse
 */
class NFCOCConfigResponse {
  /**
   * Creates an instance of NFCOCConfigResponse.
   * @param {*} json
   * @memberof NFCOCConfigResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfCOCConfigID = json.nfCOCConfigID;

      // string
      this.cocTypeCode = json.cocTypeCode;

      // string
      this.processID = json.processID;
    }
  }
}

/**
 * Models the NewNFCOCIEGKey
 *
 * @class NewNFCOCIEGKey
 */
class NewNFCOCIEGKey {
  /**
   * Creates an instance of NewNFCOCIEGKey.
   * @param {*} json
   * @memberof NewNFCOCIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegScriptExecutionID = json.iegScriptExecutionID;
    }
  }
}

/**
 * Models the NewNFCOCFinishResponseResponse
 *
 * @class NewNFCOCFinishResponseResponse
 */
class NewNFCOCFinishResponseResponse {
  /**
   * Creates an instance of NewNFCOCFinishResponseResponse.
   * @param {*} json
   * @memberof NewNFCOCFinishResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.pdcCaseReference = json.pdcCaseReference;

      // string
      this.cocInstanceID = json.cocInstanceID;

      // boolean
      this.isARCOC = json.isARCOC;

      // string
      this.submittedOn = json.submittedOn;
    }
  }
}

/**
 * Models the NewNFCOCSignAndSubmit
 *
 * @class NewNFCOCSignAndSubmit
 */
class NewNFCOCSignAndSubmit {
  /**
   * Creates an instance of NewNFCOCSignAndSubmit.
   * @param {*} json
   * @memberof NewNFCOCSignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.rightsAndResponsibilitiesInd = json.rightsAndResponsibilitiesInd;

      // boolean
      this.nfUAInformationChangedESignatureInd = json.nfUAInformationChangedESignatureInd;

      // string
      this.iegExecutionID = json.iegExecutionID;

      // boolean
      this.perjuryESignatureInd = json.perjuryESignatureInd;

      // boolean
      this.informationChangedESignatureInd = json.informationChangedESignatureInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.nfCOCInstanceID = json.nfCOCInstanceID;

      // boolean
      this.authorizeReleaseInformationInd = json.authorizeReleaseInformationInd;

      // string
      this.sigMiddleName = json.sigMiddleName;
    }
  }
}

/**
 * Models the NewErrorResponse
 *
 * @class NewErrorResponse
 */
class NewErrorResponse {
  /**
   * Creates an instance of NewErrorResponse.
   * @param {*} json
   * @memberof NewErrorResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.message = json.message;

      // string
      this.level = json.level;

      // string
      this.code = json.code;
    }
  }
}

/**
 * Models the NewNFCOCSignAndSubmitResponseResponse
 *
 * @class NewNFCOCSignAndSubmitResponseResponse
 */
class NewNFCOCSignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewNFCOCSignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewNFCOCSignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // string
      this.pdcCaseReference = json.pdcCaseReference;

      // string
      this.contact = json.contact;

      // boolean
      this.nfEnableFNSAppFromCOC = json.nfEnableFNSAppFromCOC;

      // boolean
      this.showVoterRegistration = json.showVoterRegistration;

      // array - NewErrorResponse
      if (json.errors) {
        this.errors = json.errors.map(object => new NewErrorResponse(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new NewErrorResponse(object));
      }
    }
  }
}

/**
 * Models the Error
 *
 * @class Error
 */
class Error {
  /**
   * Creates an instance of Error.
   * @param {*} json
   * @memberof Error
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.code = json.code;

      // string
      this.message = json.message;

      // string
      this.message_id = json.message_id;

      // string
      this.level = json.level;
    }
  }
}

/**
 * Models the NFCOCSignAndSubmitResponse
 *
 * @class NFCOCSignAndSubmitResponse
 */
class NFCOCSignAndSubmitResponse {
  /**
   * Creates an instance of NFCOCSignAndSubmitResponse.
   * @param {*} json
   * @memberof NFCOCSignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // string
      this.pdcCaseReference = json.pdcCaseReference;

      // string
      this.contact = json.contact;

      // boolean
      this.nfEnableFNSAppFromCOC = json.nfEnableFNSAppFromCOC;

      // boolean
      this.showVoterRegistration = json.showVoterRegistration;

      // array - Error
      if (json.errors) {
        this.errors = json.errors.map(object => new Error(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new Error(object));
      }
    }
  }
}

/**
 * Models the NewNFStartMotivationKey
 *
 * @class NewNFStartMotivationKey
 */
class NewNFStartMotivationKey {
  /**
   * Creates an instance of NewNFStartMotivationKey.
   * @param {*} json
   * @memberof NewNFStartMotivationKey
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfUXIsApplyingAsRepSelect = json.nfUXIsApplyingAsRepSelect;

      // string
      this.motivationTypeID = json.motivationTypeID;

      // string
      this.srcAppMotivationID = json.srcAppMotivationID;

      // boolean
      this.nfUXIsApplyingAsFNSSelect = json.nfUXIsApplyingAsFNSSelect;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKeyResponse
 *
 * @class NewUAMotivationIEGKeyResponse
 */
class NewUAMotivationIEGKeyResponse {
  /**
   * Creates an instance of NewUAMotivationIEGKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationIEGKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NFFNSSNAPCOCDetails
 *
 * @class NFFNSSNAPCOCDetails
 */
class NFFNSSNAPCOCDetails {
  /**
   * Creates an instance of NFFNSSNAPCOCDetails.
   * @param {*} json
   * @memberof NFFNSSNAPCOCDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.referenceNumber = json.referenceNumber;

      // string
      this.primaryApplicant = json.primaryApplicant;

      // boolean
      this.nfLocalizedInd = json.nfLocalizedInd;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.caseID = json.caseID;

      // string
      this.attachmentID = json.attachmentID;

      // string
      this.programName = json.programName;

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.nfCOCInstanceID = json.nfCOCInstanceID;
    }
  }
}

/**
 * Models the NFFNSSNAPRenewalDetails
 *
 * @class NFFNSSNAPRenewalDetails
 */
class NFFNSSNAPRenewalDetails {
  /**
   * Creates an instance of NFFNSSNAPRenewalDetails.
   * @param {*} json
   * @memberof NFFNSSNAPRenewalDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.referenceNumber = json.referenceNumber;

      // string
      this.primaryApplicant = json.primaryApplicant;

      // boolean
      this.nfLocalizedInd = json.nfLocalizedInd;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.caseID = json.caseID;

      // string
      this.attachmentID = json.attachmentID;

      // string
      this.programName = json.programName;

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.nfCOCInstanceID = json.nfCOCInstanceID;
    }
  }
}

/**
 * Models the NFFNSSNAPRenewalsAndCOCDetailsResponse
 *
 * @class NFFNSSNAPRenewalsAndCOCDetailsResponse
 */
class NFFNSSNAPRenewalsAndCOCDetailsResponse {
  /**
   * Creates an instance of NFFNSSNAPRenewalsAndCOCDetailsResponse.
   * @param {*} json
   * @memberof NFFNSSNAPRenewalsAndCOCDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isLinkedUserInd = json.isLinkedUserInd;

      // boolean
      this.isDelinkedUserInd = json.isDelinkedUserInd;

      // boolean
      this.displayPDFInd = json.displayPDFInd;

      // array - NFFNSSNAPCOCDetails
      if (json.cocDetails) {
        this.cocDetails = json.cocDetails.map(object => new NFFNSSNAPCOCDetails(object));
      } else if (json.constructor === Array) {
        this.cocDetails = json.map(object => new NFFNSSNAPCOCDetails(object));
      }

      // array - NFFNSSNAPRenewalDetails
      if (json.renewalDetails) {
        this.renewalDetails = json.renewalDetails.map(
          object => new NFFNSSNAPRenewalDetails(object)
        );
      } else if (json.constructor === Array) {
        this.renewalDetails = json.map(object => new NFFNSSNAPRenewalDetails(object));
      }
    }
  }
}

export default {
  NewNFCOCConfig,

  NewNFCOCFormIdResponse,

  NFCOCConfigResponse,

  NewNFCOCIEGKey,

  NewNFCOCFinishResponseResponse,

  NewNFCOCSignAndSubmit,

  NewErrorResponse,

  NewNFCOCSignAndSubmitResponseResponse,

  Error,

  NFCOCSignAndSubmitResponse,

  NewNFStartMotivationKey,

  NewUAMotivationIEGKeyResponse,

  NFFNSSNAPCOCDetails,

  NFFNSSNAPRenewalDetails,

  NFFNSSNAPRenewalsAndCOCDetailsResponse,
};
