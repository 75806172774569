/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/motivation/setup-and-start
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationSetupAndStartUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/setup-and-start`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/apply_for_unified
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfMotivationApplyForUnifiedUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/apply_for_unified`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationMotivationTypeByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/motivation_type/${iegExecutionID}`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/finish_unified
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationFinishUnifiedUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/finish_unified`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMotivationFinishResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Deletes from api:
 * /v1/ua/nf_motivation/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const deleteNfMotivationByIegExecutionIDUtil = (iegExecutionID, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/${iegExecutionID}`;
  RESTService.del(url, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets UADraftApplicationList from api:
 * /v1/ua/draft_applications
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchDraftApplicationsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/draft_applications`,
    models.UADraftApplicationList
  );
};

/**
 * Gets NFExistingApplicationDetailsResponse from api:
 * /v1/ua/nf_citizen_workspace/list_existing_app
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCitizenWorkspaceListExistingAppUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_workspace/list_existing_app`,
    models.NFExistingApplicationDetailsResponse
  );
};

/**
 * Gets NFCEReadSignatureDetailsResponse from api:
 * /v1/ua/nf_motivation/{iegExecutionID}/esignature
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationEsignatureUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/${iegExecutionID}/esignature`,
    models.NFCEReadSignatureDetailsResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/sign_and_submit
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationSignAndSubmitUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/sign_and_submit`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCESignAndSubmitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFMotivationDetails from api:
 * /v1/ua/nf_motivation/motivation_details/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationMotivationDetailsByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/motivation_details/${iegExecutionID}`,
    models.NFMotivationDetails
  );
};

/**
 * Gets NFCESignAndSubmitResponse from api:
 * /v1/ua/nf_motivation/confirmation_details/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/confirmation_details/${iegExecutionID}`,
    models.NFCESignAndSubmitResponse
  );
};

/**
 * Gets UAWithdrawalRequestReasonList from api:
 * /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request_reasons
 *
 * @param {string} application_id.
 * @param {string} application_program_id.
 * @param {any} callback function to call once this function has finished
 */
const getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsUtil = (
  application_id,
  application_program_id,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/submitted_applications/${application_id}/application_programs/${application_program_id}/withdrawal_request_reasons`,
    models.UAWithdrawalRequestReasonList
  );
};

/**
 * Post request to path:
 * /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
 *
 * @param {string} application_id.
 * @param {string} application_program_id.
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil = (
  application_id,
  application_program_id,
  data = {},
  callback
) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/submitted_applications/${application_id}/application_programs/${application_program_id}/withdrawal_request`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/submitted_applications/application_programs/withdrawal_coc_request
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestUtil = (
  data = {},
  callback
) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/submitted_applications/application_programs/withdrawal_coc_request`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets NFSubmittedAppDtlsListResponse from api:
 * /v1/ua/nf_citizen_workspace/list_existing_coc_app
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCitizenWorkspaceListExistingCocAppUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_citizen_workspace/list_existing_coc_app`,
    models.NFSubmittedAppDtlsListResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
 *
 * @param {string} application_id.
 * @param {string} application_program_id.
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil = (
  application_id,
  application_program_id,
  data = {},
  callback
) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_submitted_applications/${application_id}/application_programs/${application_program_id}/withdrawal_request`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/store_save_and_exit_page_name
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationStoreSaveAndExitPageNameUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/store_save_and_exit_page_name`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFSaveAndExitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export {
  postMotivationSetupAndStartUtil,
  fetchNfMotivationApplyForUnifiedUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishUnifiedUtil,
  deleteNfMotivationByIegExecutionIDUtil,
  fetchDraftApplicationsUtil,
  fetchNfCitizenWorkspaceListExistingAppUtil,
  getNfMotivationEsignatureUtil,
  postNfMotivationSignAndSubmitUtil,
  getNfMotivationMotivationDetailsByIegExecutionIDUtil,
  getNfMotivationConfirmationDetailsByIegExecutionIDUtil,
  getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsUtil,
  postSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil,
  postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestUtil,
  fetchNfCitizenWorkspaceListExistingCocAppUtil,
  postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil,
  postNfMotivationStoreSaveAndExitPageNameUtil,
};
