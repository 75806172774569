/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFFnsOrSnapRecertMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_COC_SNAP_BENEFIT_AMOUNT = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFFNSORSNAPRECERTMOTIVATION/FETCH_NF_COC_SNAP_BENEFIT_AMOUNT`
);

const POST_LIFE_EVENTS_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFFNSORSNAPRECERTMOTIVATION/POST_LIFE_EVENTS_FORM`
);

export { FETCH_NF_COC_SNAP_BENEFIT_AMOUNT, POST_LIFE_EVENTS_FORM };
