/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFSecureInboxSubscriptionResponse from api:
 * /v1/ua/nf_secure_inbox/is_subscribed
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfSecureInboxIsSubscribedUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_secure_inbox/is_subscribed`,
    models.NFSecureInboxSubscriptionResponse
  );
};

/**
 * Gets NFSecureInboxCommunicationResponse from api:
 * /v1/ua/nf_secure_inbox/list_comm
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfSecureInboxListCommUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_secure_inbox/list_comm`,
    models.NFSecureInboxCommunicationResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_secure_inbox/primary_contact_dtls
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSecureInboxPrimaryContactDtlsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_secure_inbox/primary_contact_dtls`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFContactInformationAndMethodResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_secure_inbox/subscribe_enotices
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSecureInboxSubscribeEnoticesUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_secure_inbox/subscribe_enotices`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFSecureInboxSubscriptionResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_secure_inbox/unsubscribe_enotices
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSecureInboxUnsubscribeEnoticesUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_secure_inbox/unsubscribe_enotices`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFSecureInboxSubscriptionResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_secure_inbox/send_by_mail/{communicationID}
 *
 * @param {string} communicationID.
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSecureInboxSendByMailByCommunicationIDUtil = (communicationID, data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_secure_inbox/send_by_mail/${communicationID}`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFSecureInboxResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFSecureInboxMailingAddressResponse from api:
 * /v1/ua/nf_secure_inbox/get_mailing_address
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfSecureInboxGetMailingAddressUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_secure_inbox/get_mailing_address`,
    models.NFSecureInboxMailingAddressResponse
  );
};

/**
 * Gets NFSecureInboxNoticeResponse from api:
 * /v1/ua/nf_secure_inbox/get_eNotices/{communicationID}
 *
 * @param {string} communicationID.
 * @param {any} callback function to call once this function has finished
 */
const getNfSecureInboxGetENoticesByCommunicationIDUtil = (communicationID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_secure_inbox/get_eNotices/${communicationID}`,
    models.NFSecureInboxNoticeResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_secure_inbox/update_ENotice_ViewStatus
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfSecureInboxUpdateENoticeViewStatusUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_secure_inbox/update_ENotice_ViewStatus`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFSecureInboxResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export {
  fetchNfSecureInboxIsSubscribedUtil,
  fetchNfSecureInboxListCommUtil,
  postNfSecureInboxPrimaryContactDtlsUtil,
  postNfSecureInboxSubscribeEnoticesUtil,
  postNfSecureInboxUnsubscribeEnoticesUtil,
  postNfSecureInboxSendByMailByCommunicationIDUtil,
  fetchNfSecureInboxGetMailingAddressUtil,
  getNfSecureInboxGetENoticesByCommunicationIDUtil,
  postNfSecureInboxUpdateENoticeViewStatusUtil,
};
