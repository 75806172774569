/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER,
  FETCH_NF_AUTHREP_AUTHORIZED_REP,
} from './actionTypes';

/**
 * Updates the details in the 'NFApplicationPending' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfAuthrepExistsPendingAppForUserReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER);

/**
 * Updates the indicator of creation of details in the 'NFApplicationPending' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfAuthrepExistsPendingAppForUserReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfAuthrepExistsPendingAppForUserErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER);

/**
 * Updates the details in the 'NFApplicationPending' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfAuthrepAuthorizedRepReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_AUTHREP_AUTHORIZED_REP);

/**
 * Updates the indicator of creation of details in the 'NFApplicationPending' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfAuthrepAuthorizedRepReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_AUTHREP_AUTHORIZED_REP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfAuthrepAuthorizedRepErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_AUTHREP_AUTHORIZED_REP);

const reducers = combineReducers({
  fetchNfAuthrepExistsPendingAppForUser: fetchNfAuthrepExistsPendingAppForUserReducer,

  isFetchingFetchNfAuthrepExistsPendingAppForUser: isFetchingFetchNfAuthrepExistsPendingAppForUserReducer,
  fetchNfAuthrepExistsPendingAppForUserError: fetchNfAuthrepExistsPendingAppForUserErrorReducer,

  fetchNfAuthrepAuthorizedRep: fetchNfAuthrepAuthorizedRepReducer,

  isFetchingFetchNfAuthrepAuthorizedRep: isFetchingFetchNfAuthrepAuthorizedRepReducer,
  fetchNfAuthrepAuthorizedRepError: fetchNfAuthrepAuthorizedRepErrorReducer,
});

export default { reducers };
