/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/nf_counter/{nfCounterTypeCode}
 *
 * @param {string} nfCounterTypeCode.
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfCounterByNfCounterTypeCodeUtil = (nfCounterTypeCode, data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_counter/${nfCounterTypeCode}`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

export { postNfCounterByNfCounterTypeCodeUtil };
