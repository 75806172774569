import { connect } from 'react-redux';
import {
  IntlConfigSelectors,
  UserPreferencesSelectors,
  UserPreferencesActions,
} from '@spm/universal-access';
import NFLanguageDropdownComponent from './components/NFLanguageDropdownComponent';

/**
 * Retrieves data from the Redux store.
 *
 * @param state  the redux store state
 * @memberof PaymentsContainer
 */
const mapStateToProps = state => ({
  language: UserPreferencesSelectors.getUserSelectedLanguage(state),
  locales: IntlConfigSelectors.getLocales(state),
});

/**
 * Retrieve data from related rest APIs and updates the Redux store.
 *
 * @export
 * @param {*} dispatch the dispatch function
 * @returns {Object} the mappings.
 * @memberof NFLanguageDropdownContainer
 */
export const mapDispatchToProps = dispatch => ({
  onLanguageToggle: evt => UserPreferencesActions.changeLanguage(dispatch, evt.target.value),
});

/**
 * Renders the Language Dropdown feature on a page within the application.
 * @memberof NFLanguageDropdownContainer
 */
export default connect(mapStateToProps, mapDispatchToProps)(NFLanguageDropdownComponent);
