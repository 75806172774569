/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFSecureInboxSubscriptionResponse
 *
 * @class NFSecureInboxSubscriptionResponse
 */
class NFSecureInboxSubscriptionResponse {
  /**
   * Creates an instance of NFSecureInboxSubscriptionResponse.
   * @param {*} json
   * @memberof NFSecureInboxSubscriptionResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // boolean
      this.isSubscribed = json.isSubscribed;
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the CitizenCommunicationDetails
 *
 * @class CitizenCommunicationDetails
 */
class CitizenCommunicationDetails {
  /**
   * Creates an instance of CitizenCommunicationDetails.
   * @param {*} json
   * @memberof CitizenCommunicationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.communicationID = json.communicationID;

      // string
      this.correspondentName = json.correspondentName;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.subjectText = json.subjectText;

      // object - CodeTableType
      if (json.typeCode) {
        this.typeCode = new CodeTableType(json.typeCode);
      }

      // string
      this.communicationDate = json.communicationDate;

      // object - CodeTableType
      if (json.communicationStatus) {
        this.communicationStatus = new CodeTableType(json.communicationStatus);
      }

      // object - CodeTableType
      if (json.statusCode) {
        this.statusCode = new CodeTableType(json.statusCode);
      }

      // string
      this.ticketID = json.ticketID;

      // integer
      this.versionNo = json.versionNo;

      // object - CodeTableType
      if (json.methodTypeCode) {
        this.methodTypeCode = new CodeTableType(json.methodTypeCode);
      }

      // boolean
      this.attachmentInd = json.attachmentInd;

      // string
      this.caseID = json.caseID;

      // string
      this.caseReference = json.caseReference;

      // object - CodeTableType
      if (json.communicationFormat) {
        this.communicationFormat = new CodeTableType(json.communicationFormat);
      }

      // string
      this.userName = json.userName;
    }
  }
}

/**
 * Models the NFSecureInboxCommunicationResponse
 *
 * @class NFSecureInboxCommunicationResponse
 */
class NFSecureInboxCommunicationResponse {
  /**
   * Creates an instance of NFSecureInboxCommunicationResponse.
   * @param {*} json
   * @memberof NFSecureInboxCommunicationResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // array - CitizenCommunicationDetails
      if (json.data) {
        this.data = json.data.map(object => new CitizenCommunicationDetails(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new CitizenCommunicationDetails(object));
      }
    }
  }
}

/**
 * Models the NewNFContactInformationAndMethodResponse
 *
 * @class NewNFContactInformationAndMethodResponse
 */
class NewNFContactInformationAndMethodResponse {
  /**
   * Creates an instance of NewNFContactInformationAndMethodResponse.
   * @param {*} json
   * @memberof NewNFContactInformationAndMethodResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contactInformation = json.contactInformation;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // boolean
      this.isEmailInd = json.isEmailInd;

      // string
      this.contactID = json.contactID;
    }
  }
}

/**
 * Models the NewNFSecureInboxSubscriptionResponseResponse
 *
 * @class NewNFSecureInboxSubscriptionResponseResponse
 */
class NewNFSecureInboxSubscriptionResponseResponse {
  /**
   * Creates an instance of NewNFSecureInboxSubscriptionResponseResponse.
   * @param {*} json
   * @memberof NewNFSecureInboxSubscriptionResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;

      // boolean
      this.isSubscribed = json.isSubscribed;
    }
  }
}

/**
 * Models the NewNFSecureInboxCommunicationRequest
 *
 * @class NewNFSecureInboxCommunicationRequest
 */
class NewNFSecureInboxCommunicationRequest {
  /**
   * Creates an instance of NewNFSecureInboxCommunicationRequest.
   * @param {*} json
   * @memberof NewNFSecureInboxCommunicationRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.communicationID = json.communicationID;
    }
  }
}

/**
 * Models the NewNFSecureInboxResponseResponse
 *
 * @class NewNFSecureInboxResponseResponse
 */
class NewNFSecureInboxResponseResponse {
  /**
   * Creates an instance of NewNFSecureInboxResponseResponse.
   * @param {*} json
   * @memberof NewNFSecureInboxResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.message = json.message;
    }
  }
}

/**
 * Models the NFSecureInboxMailingAddressResponse
 *
 * @class NFSecureInboxMailingAddressResponse
 */
class NFSecureInboxMailingAddressResponse {
  /**
   * Creates an instance of NFSecureInboxMailingAddressResponse.
   * @param {*} json
   * @memberof NFSecureInboxMailingAddressResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.mailingAddress = json.mailingAddress;
    }
  }
}

/**
 * Models the NFSecureInboxNoticeResponse
 *
 * @class NFSecureInboxNoticeResponse
 */
class NFSecureInboxNoticeResponse {
  /**
   * Creates an instance of NFSecureInboxNoticeResponse.
   * @param {*} json
   * @memberof NFSecureInboxNoticeResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.data = json.data;
    }
  }
}

/**
 * Models the NewCommunicationIDKey
 *
 * @class NewCommunicationIDKey
 */
class NewCommunicationIDKey {
  /**
   * Creates an instance of NewCommunicationIDKey.
   * @param {*} json
   * @memberof NewCommunicationIDKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.communicationID = json.communicationID;
    }
  }
}

export default {
  NFSecureInboxSubscriptionResponse,

  CodeTableType,

  CitizenCommunicationDetails,

  NFSecureInboxCommunicationResponse,

  NewNFContactInformationAndMethodResponse,

  NewNFSecureInboxSubscriptionResponseResponse,

  NewNFSecureInboxCommunicationRequest,

  NewNFSecureInboxResponseResponse,

  NFSecureInboxMailingAddressResponse,

  NFSecureInboxNoticeResponse,

  NewCommunicationIDKey,
};
