/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the ConcernRoleCommunicationDtls
 *
 * @class ConcernRoleCommunicationDtls
 */
class ConcernRoleCommunicationDtls {
  /**
   * Creates an instance of ConcernRoleCommunicationDtls.
   * @param {*} json
   * @memberof ConcernRoleCommunicationDtls
   */
  constructor(json = null) {
    if (json) {
      // string
      this.communicationID = json.communicationID;

      // string
      this.concernRoleID = json.concernRoleID;

      // object - CodeTableType
      if (json.typeCode) {
        this.typeCode = new CodeTableType(json.typeCode);
      }

      // integer
      this.proFormaVersionNo = json.proFormaVersionNo;

      // object - CodeTableType
      if (json.methodTypeCode) {
        this.methodTypeCode = new CodeTableType(json.methodTypeCode);
      }

      // boolean
      this.incomingInd = json.incomingInd;

      // string
      this.communicationDate = json.communicationDate;

      // object - CodeTableType
      if (json.statusCode) {
        this.statusCode = new CodeTableType(json.statusCode);
      }

      // string
      this.correspondentName = json.correspondentName;

      // string
      this.correspondentConcernRoleID = json.correspondentConcernRoleID;

      // object - CodeTableType
      if (json.correspondentTypeCode) {
        this.correspondentTypeCode = new CodeTableType(json.correspondentTypeCode);
      }

      // string
      this.documentLocation = json.documentLocation;

      // string
      this.documentRefNumber = json.documentRefNumber;

      // string
      this.fileLocation = json.fileLocation;

      // string
      this.fileReferenceNumber = json.fileReferenceNumber;

      // string
      this.caseID = json.caseID;

      // string
      this.ticketID = json.ticketID;

      // string
      this.userName = json.userName;

      // string
      this.comments = json.comments;

      // string
      this.communicationText = json.communicationText;

      // string
      this.addressID = json.addressID;

      // string
      this.phoneNumberID = json.phoneNumberID;

      // string
      this.emailAddressID = json.emailAddressID;

      // string
      this.proFormaID = json.proFormaID;

      // object - CodeTableType
      if (json.communicationStatus) {
        this.communicationStatus = new CodeTableType(json.communicationStatus);
      }

      // boolean
      this.proFormaInd = json.proFormaInd;

      // boolean
      this.attachmentInd = json.attachmentInd;

      // string
      this.documentTemplateID = json.documentTemplateID;

      // object - CodeTableType
      if (json.communicationFormat) {
        this.communicationFormat = new CodeTableType(json.communicationFormat);
      }

      // object - CodeTableType
      if (json.localeIdentifier) {
        this.localeIdentifier = new CodeTableType(json.localeIdentifier);
      }

      // string
      this.subjectText = json.subjectText;

      // string
      this.nfCarbonCopyFromCommID = json.nfCarbonCopyFromCommID;

      // string
      this.nfHeldToDate = json.nfHeldToDate;

      // boolean
      this.nfViewedInd = json.nfViewedInd;

      // string
      this.nfExternalTransactionID = json.nfExternalTransactionID;

      // string
      this.nfClientViewedDate = json.nfClientViewedDate;

      // integer
      this.versionNo = json.versionNo;
    }
  }
}

/**
 * Models the NFConcernRoleCommunicationDtls
 *
 * @class NFConcernRoleCommunicationDtls
 */
class NFConcernRoleCommunicationDtls {
  /**
   * Creates an instance of NFConcernRoleCommunicationDtls.
   * @param {*} json
   * @memberof NFConcernRoleCommunicationDtls
   */
  constructor(json = null) {
    if (json) {
      // object - ConcernRoleCommunicationDtls
      if (json.dtls) {
        this.dtls = new ConcernRoleCommunicationDtls(json.dtls);
      }
    }
  }
}

/**
 * Models the NFConcernRoleCommunicationDtlsList
 *
 * @class NFConcernRoleCommunicationDtlsList
 */
class NFConcernRoleCommunicationDtlsList {
  /**
   * Creates an instance of NFConcernRoleCommunicationDtlsList.
   * @param {*} json
   * @memberof NFConcernRoleCommunicationDtlsList
   */
  constructor(json = null) {
    if (json) {
      // array - NFConcernRoleCommunicationDtls
      if (json.dtlsList) {
        this.dtlsList = json.dtlsList.map(object => new NFConcernRoleCommunicationDtls(object));
      } else if (json.constructor === Array) {
        this.dtlsList = json.map(object => new NFConcernRoleCommunicationDtls(object));
      }
    }
  }
}

/**
 * Models the NewCommunicationIDKey
 *
 * @class NewCommunicationIDKey
 */
class NewCommunicationIDKey {
  /**
   * Creates an instance of NewCommunicationIDKey.
   * @param {*} json
   * @memberof NewCommunicationIDKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.communicationID = json.communicationID;
    }
  }
}

/**
 * Models the NewNFTextMessageResponseResponse
 *
 * @class NewNFTextMessageResponseResponse
 */
class NewNFTextMessageResponseResponse {
  /**
   * Creates an instance of NewNFTextMessageResponseResponse.
   * @param {*} json
   * @memberof NewNFTextMessageResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.subscriptionID = json.subscriptionID;
    }
  }
}

export default {
  CodeTableType,

  ConcernRoleCommunicationDtls,

  NFConcernRoleCommunicationDtls,

  NFConcernRoleCommunicationDtlsList,

  NewCommunicationIDKey,

  NewNFTextMessageResponseResponse,
};
