/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_UNIFIED,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_DRAFT_APPLICATIONS,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP,
  GET_NF_MOTIVATION_ESIGNATURE,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP,
  POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME,
} from './actionTypes';

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupAndStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfMotivationApplyForUnifiedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfMotivationApplyForUnifiedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfMotivationApplyForUnifiedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationTypeByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishUnifiedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_UNIFIED);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishUnifiedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_UNIFIED);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishUnifiedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_UNIFIED);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const deleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingDeleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const deleteNfMotivationByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_DRAFT_APPLICATIONS);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_DRAFT_APPLICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchDraftApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_DRAFT_APPLICATIONS);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenWorkspaceListExistingAppReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenWorkspaceListExistingAppReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenWorkspaceListExistingAppErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationEsignatureReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationEsignatureReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationEsignatureErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationSignAndSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationSignAndSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationSignAndSubmitErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationDetailsByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationConfirmationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalRequestErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequestReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenWorkspaceListExistingCocAppReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenWorkspaceListExistingCocAppReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenWorkspaceListExistingCocAppErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP);

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST
  );

/**
 * Updates the details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationStoreSaveAndExitPageNameReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME);

/**
 * Updates the indicator of creation of details in the 'NFUnifiedMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationStoreSaveAndExitPageNameReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationStoreSaveAndExitPageNameErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME);

const reducers = combineReducers({
  postMotivationSetupAndStart: postMotivationSetupAndStartReducer,

  isFetchingPostMotivationSetupAndStart: isFetchingPostMotivationSetupAndStartReducer,
  postMotivationSetupAndStartError: postMotivationSetupAndStartErrorReducer,

  fetchNfMotivationApplyForUnified: fetchNfMotivationApplyForUnifiedReducer,

  isFetchingFetchNfMotivationApplyForUnified: isFetchingFetchNfMotivationApplyForUnifiedReducer,
  fetchNfMotivationApplyForUnifiedError: fetchNfMotivationApplyForUnifiedErrorReducer,

  getNfMotivationMotivationTypeByIegExecutionID: getNfMotivationMotivationTypeByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationTypeByIegExecutionID: isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer,
  getNfMotivationMotivationTypeByIegExecutionIDError: getNfMotivationMotivationTypeByIegExecutionIDErrorReducer,

  postNfMotivationFinishUnified: postNfMotivationFinishUnifiedReducer,

  isFetchingPostNfMotivationFinishUnified: isFetchingPostNfMotivationFinishUnifiedReducer,
  postNfMotivationFinishUnifiedError: postNfMotivationFinishUnifiedErrorReducer,

  deleteNfMotivationByIegExecutionID: deleteNfMotivationByIegExecutionIDReducer,

  isFetchingDeleteNfMotivationByIegExecutionID: isFetchingDeleteNfMotivationByIegExecutionIDReducer,
  deleteNfMotivationByIegExecutionIDError: deleteNfMotivationByIegExecutionIDErrorReducer,

  fetchDraftApplications: fetchDraftApplicationsReducer,

  isFetchingFetchDraftApplications: isFetchingFetchDraftApplicationsReducer,
  fetchDraftApplicationsError: fetchDraftApplicationsErrorReducer,

  fetchNfCitizenWorkspaceListExistingApp: fetchNfCitizenWorkspaceListExistingAppReducer,

  isFetchingFetchNfCitizenWorkspaceListExistingApp: isFetchingFetchNfCitizenWorkspaceListExistingAppReducer,
  fetchNfCitizenWorkspaceListExistingAppError: fetchNfCitizenWorkspaceListExistingAppErrorReducer,

  getNfMotivationEsignature: getNfMotivationEsignatureReducer,

  isFetchingGetNfMotivationEsignature: isFetchingGetNfMotivationEsignatureReducer,
  getNfMotivationEsignatureError: getNfMotivationEsignatureErrorReducer,

  postNfMotivationSignAndSubmit: postNfMotivationSignAndSubmitReducer,

  isFetchingPostNfMotivationSignAndSubmit: isFetchingPostNfMotivationSignAndSubmitReducer,
  postNfMotivationSignAndSubmitError: postNfMotivationSignAndSubmitErrorReducer,

  getNfMotivationMotivationDetailsByIegExecutionID: getNfMotivationMotivationDetailsByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationDetailsByIegExecutionID: isFetchingGetNfMotivationMotivationDetailsByIegExecutionIDReducer,
  getNfMotivationMotivationDetailsByIegExecutionIDError: getNfMotivationMotivationDetailsByIegExecutionIDErrorReducer,

  getNfMotivationConfirmationDetailsByIegExecutionID: getNfMotivationConfirmationDetailsByIegExecutionIDReducer,

  isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID: isFetchingGetNfMotivationConfirmationDetailsByIegExecutionIDReducer,
  getNfMotivationConfirmationDetailsByIegExecutionIDError: getNfMotivationConfirmationDetailsByIegExecutionIDErrorReducer,

  getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons: getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsReducer,

  isFetchingGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons: isFetchingGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsReducer,
  getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsError: getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsErrorReducer,

  postSubmittedApplicationsApplicationProgramsWithdrawalRequest: postSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer,

  isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalRequest: isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer,
  postSubmittedApplicationsApplicationProgramsWithdrawalRequestError: postSubmittedApplicationsApplicationProgramsWithdrawalRequestErrorReducer,

  postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest: postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestReducer,

  isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest: isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequestReducer,
  postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestError: postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestErrorReducer,

  fetchNfCitizenWorkspaceListExistingCocApp: fetchNfCitizenWorkspaceListExistingCocAppReducer,

  isFetchingFetchNfCitizenWorkspaceListExistingCocApp: isFetchingFetchNfCitizenWorkspaceListExistingCocAppReducer,
  fetchNfCitizenWorkspaceListExistingCocAppError: fetchNfCitizenWorkspaceListExistingCocAppErrorReducer,

  postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest: postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer,

  isFetchingPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest: isFetchingPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequestReducer,
  postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestError: postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestErrorReducer,

  postNfMotivationStoreSaveAndExitPageName: postNfMotivationStoreSaveAndExitPageNameReducer,

  isFetchingPostNfMotivationStoreSaveAndExitPageName: isFetchingPostNfMotivationStoreSaveAndExitPageNameReducer,
  postNfMotivationStoreSaveAndExitPageNameError: postNfMotivationStoreSaveAndExitPageNameErrorReducer,
});

export default { reducers };
