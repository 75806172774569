/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFProfile.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_RIDP_PROFILE_INFO = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFPROFILE/FETCH_NF_RIDP_PROFILE_INFO`
);

const POST_NF_RIDP_UPDATE_PRI_CONTACT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPROFILE/POST_NF_RIDP_UPDATE_PRI_CONTACT`
);

const POST_NF_RIDP_UPDATE_SEC_CONTACT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPROFILE/POST_NF_RIDP_UPDATE_SEC_CONTACT`
);

const POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPROFILE/POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT`
);

const POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPROFILE/POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER`
);

const POST_NF_RIDP_REVOKE_ALL = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFPROFILE/POST_NF_RIDP_REVOKE_ALL`
);

const GET_CODETABLES_BY_TABLE_NAME = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFPROFILE/GET_CODETABLES_BY_TABLE_NAME`
);

export {
  FETCH_NF_RIDP_PROFILE_INFO,
  POST_NF_RIDP_UPDATE_PRI_CONTACT,
  POST_NF_RIDP_UPDATE_SEC_CONTACT,
  POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT,
  POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER,
  POST_NF_RIDP_REVOKE_ALL,
  GET_CODETABLES_BY_TABLE_NAME,
};
