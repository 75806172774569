/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFOrganizationVoterRegistration.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFORGANIZATIONVOTERREGISTRATION/POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE`
);

export { POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE };
