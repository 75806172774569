/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFLIEAPModule' store object.
 * @namespace NFLIEAPModuleSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFLIEAPModule';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static fetchNfCitizenWorkspaceApplyLieap = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceApplyLieap.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static isFetchingFetchNfCitizenWorkspaceApplyLieap = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCitizenWorkspaceApplyLieap.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static fetchNfCitizenWorkspaceApplyLieapError = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceApplyLieapError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static postNfCitizenWorkspaceStartLieap = state =>
    state[selectors.moduleIdentifier].postNfCitizenWorkspaceStartLieap.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static isFetchingPostNfCitizenWorkspaceStartLieap = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfCitizenWorkspaceStartLieap.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static postNfCitizenWorkspaceStartLieapError = state =>
    state[selectors.moduleIdentifier].postNfCitizenWorkspaceStartLieapError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static getFormDetailsByApplicationFormId = state =>
    state[selectors.moduleIdentifier].getFormDetailsByApplicationFormId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static isFetchingGetFormDetailsByApplicationFormId = state =>
    state[selectors.moduleIdentifier].isFetchingGetFormDetailsByApplicationFormId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static getFormDetailsByApplicationFormIdError = state =>
    state[selectors.moduleIdentifier].getFormDetailsByApplicationFormIdError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static fetchDraftApplications = state =>
    state[selectors.moduleIdentifier].fetchDraftApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static isFetchingFetchDraftApplications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchDraftApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFLIEAPModuleSelectors
   */
  static fetchDraftApplicationsError = state =>
    state[selectors.moduleIdentifier].fetchDraftApplicationsError.payload;
}
