/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS,
} from './actionTypes';

/**
 * Updates the details in the 'NFSecuredMessages' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfTextMessageSubscriptionuaListSecureMessagesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES
  );

/**
 * Updates the indicator of creation of details in the 'NFSecuredMessages' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfTextMessageSubscriptionuaListSecureMessagesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfTextMessageSubscriptionuaListSecureMessagesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES
  );

/**
 * Updates the details in the 'NFSecuredMessages' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS
  );

/**
 * Updates the indicator of creation of details in the 'NFSecuredMessages' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS
  );

const reducers = combineReducers({
  fetchNfTextMessageSubscriptionuaListSecureMessages: fetchNfTextMessageSubscriptionuaListSecureMessagesReducer,

  isFetchingFetchNfTextMessageSubscriptionuaListSecureMessages: isFetchingFetchNfTextMessageSubscriptionuaListSecureMessagesReducer,
  fetchNfTextMessageSubscriptionuaListSecureMessagesError: fetchNfTextMessageSubscriptionuaListSecureMessagesErrorReducer,

  postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus: postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusReducer,

  isFetchingPostNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus: isFetchingPostNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusReducer,
  postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusError: postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusErrorReducer,
});

export default { reducers };
