/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFMyProfileDetailsResponse from api:
 * /v1/ua/nf_ridp/profile_info
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfRidpProfileInfoUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_ridp/profile_info`,
    models.NFMyProfileDetailsResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_ridp/update_pri_contact
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfRidpUpdatePriContactUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_ridp/update_pri_contact`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFRIDPResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_ridp/update_sec_contact
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfRidpUpdateSecContactUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_ridp/update_sec_contact`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFRIDPResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_ridp/resend_code_update_contact
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfRidpResendCodeUpdateContactUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_ridp/resend_code_update_contact`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFRIDPResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_ridp/update_confirmation_number
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfRidpUpdateConfirmationNumberUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_ridp/update_confirmation_number`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUserContactDetailsResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_ridp/revoke_all
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfRidpRevokeAllUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_ridp/revoke_all`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFRIDPResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets CodeTable from api:
 * /v1/codetables/{table_name}
 *
 * @param {string} table_name.
 * @param {any} callback function to call once this function has finished
 */
const getCodetablesByTableNameUtil = (table_name, callback) => {
  ReduxUtils.getModelDataFromRestAPI(callback, `v1/codetables/${table_name}`, models.CodeTable);
};

export {
  fetchNfRidpProfileInfoUtil,
  postNfRidpUpdatePriContactUtil,
  postNfRidpUpdateSecContactUtil,
  postNfRidpResendCodeUpdateContactUtil,
  postNfRidpUpdateConfirmationNumberUtil,
  postNfRidpRevokeAllUtil,
  getCodetablesByTableNameUtil,
};
