/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFFnsOrSnapRecertMotivation' store object.
 * @namespace NFFnsOrSnapRecertMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFFnsOrSnapRecertMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static fetchNfCocSnapBenefitAmount = state =>
    state[selectors.moduleIdentifier].fetchNfCocSnapBenefitAmount.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static isFetchingFetchNfCocSnapBenefitAmount = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocSnapBenefitAmount.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static fetchNfCocSnapBenefitAmountError = state =>
    state[selectors.moduleIdentifier].fetchNfCocSnapBenefitAmountError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static postLifeEventsForm = state => state[selectors.moduleIdentifier].postLifeEventsForm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static isFetchingPostLifeEventsForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostLifeEventsForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFFnsOrSnapRecertMotivationSelectors
   */
  static postLifeEventsFormError = state =>
    state[selectors.moduleIdentifier].postLifeEventsFormError.payload;
}
