/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CitizenCaseContactDetails
 *
 * @class CitizenCaseContactDetails
 */
class CitizenCaseContactDetails {
  /**
   * Creates an instance of CitizenCaseContactDetails.
   * @param {*} json
   * @memberof CitizenCaseContactDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.citizenCases = json.citizenCases;
    }
  }
}

/**
 * Models the CitizenAddressDetails
 *
 * @class CitizenAddressDetails
 */
class CitizenAddressDetails {
  /**
   * Creates an instance of CitizenAddressDetails.
   * @param {*} json
   * @memberof CitizenAddressDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.citizenAddressString = json.citizenAddressString;
    }
  }
}

/**
 * Models the CitizenEmailAddressDetails
 *
 * @class CitizenEmailAddressDetails
 */
class CitizenEmailAddressDetails {
  /**
   * Creates an instance of CitizenEmailAddressDetails.
   * @param {*} json
   * @memberof CitizenEmailAddressDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.citizenEmailAddressString = json.citizenEmailAddressString;
    }
  }
}

/**
 * Models the CitizenPhoneNumberDetails
 *
 * @class CitizenPhoneNumberDetails
 */
class CitizenPhoneNumberDetails {
  /**
   * Creates an instance of CitizenPhoneNumberDetails.
   * @param {*} json
   * @memberof CitizenPhoneNumberDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.citizenPhoneNumberString = json.citizenPhoneNumberString;
    }
  }
}

/**
 * Models the CitizenContactDetailsList
 *
 * @class CitizenContactDetailsList
 */
class CitizenContactDetailsList {
  /**
   * Creates an instance of CitizenContactDetailsList.
   * @param {*} json
   * @memberof CitizenContactDetailsList
   */
  constructor(json = null) {
    if (json) {
      // array - CitizenAddressDetails
      if (json.addressDtls) {
        this.addressDtls = json.addressDtls.map(object => new CitizenAddressDetails(object));
      } else if (json.constructor === Array) {
        this.addressDtls = json.map(object => new CitizenAddressDetails(object));
      }

      // array - CitizenEmailAddressDetails
      if (json.emailDtls) {
        this.emailDtls = json.emailDtls.map(object => new CitizenEmailAddressDetails(object));
      } else if (json.constructor === Array) {
        this.emailDtls = json.map(object => new CitizenEmailAddressDetails(object));
      }

      // array - CitizenPhoneNumberDetails
      if (json.phoneDtls) {
        this.phoneDtls = json.phoneDtls.map(object => new CitizenPhoneNumberDetails(object));
      } else if (json.constructor === Array) {
        this.phoneDtls = json.map(object => new CitizenPhoneNumberDetails(object));
      }
    }
  }
}

/**
 * Models the CitizenContactInformationDetails
 *
 * @class CitizenContactInformationDetails
 */
class CitizenContactInformationDetails {
  /**
   * Creates an instance of CitizenContactInformationDetails.
   * @param {*} json
   * @memberof CitizenContactInformationDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.showCaseworkerDetailsInd = json.showCaseworkerDetailsInd;

      // boolean
      this.showClientDetailsInd = json.showClientDetailsInd;

      // string
      this.caseownerAddressString = json.caseownerAddressString;

      // string
      this.caseownerPhoneNumberString = json.caseownerPhoneNumberString;

      // object - CitizenCaseContactDetails
      if (json.caseDtls) {
        this.caseDtls = new CitizenCaseContactDetails(json.caseDtls);
      }

      // object - CitizenContactDetailsList
      if (json.contactDtls) {
        this.contactDtls = new CitizenContactDetailsList(json.contactDtls);
      }
    }
  }
}

/**
 * Models the NFCitizenAccountResponse
 *
 * @class NFCitizenAccountResponse
 */
class NFCitizenAccountResponse {
  /**
   * Creates an instance of NFCitizenAccountResponse.
   * @param {*} json
   * @memberof NFCitizenAccountResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.caseNameRef = json.caseNameRef;

      // string
      this.cwName = json.cwName;

      // object - CitizenContactInformationDetails
      if (json.dtls) {
        this.dtls = new CitizenContactInformationDetails(json.dtls);
      }
    }
  }
}

export default {
  CitizenCaseContactDetails,

  CitizenAddressDetails,

  CitizenEmailAddressDetails,

  CitizenPhoneNumberDetails,

  CitizenContactDetailsList,

  CitizenContactInformationDetails,

  NFCitizenAccountResponse,
};
