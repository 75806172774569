/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFLIEAPModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFLIEAPMODULE/FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP`
);

const POST_NF_CITIZEN_WORKSPACE_START_LIEAP = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFLIEAPMODULE/POST_NF_CITIZEN_WORKSPACE_START_LIEAP`
);

const GET_FORM_DETAILS_BY_APPLICATION_FORM_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFLIEAPMODULE/GET_FORM_DETAILS_BY_APPLICATION_FORM_ID`
);

const FETCH_DRAFT_APPLICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFLIEAPMODULE/FETCH_DRAFT_APPLICATIONS`
);

export {
  FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP,
  POST_NF_CITIZEN_WORKSPACE_START_LIEAP,
  GET_FORM_DETAILS_BY_APPLICATION_FORM_ID,
  FETCH_DRAFT_APPLICATIONS,
};
