/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFMassCommunication.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMASSCOMMUNICATION/POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD`
);

const POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMASSCOMMUNICATION/POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM`
);

const POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMASSCOMMUNICATION/POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION`
);

const POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMASSCOMMUNICATION/POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION`
);

export {
  POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD,
  POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM,
  POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION,
  POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION,
};
