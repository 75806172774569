/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 * Routes Messages
 *
 * This contains all the text Routes to be displayed on the window's title.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  sampleApplication: {
    id: 'sampleApplication',
    defaultMessage: 'Sample of Apply for Benefits',
  },
  sampleApplicationOverview: {
    id: 'sampleApplicationOverview',
    defaultMessage: 'Sample of Apply for Benefits Overview',
  },
  sampleApplicationForm: {
    id: 'sampleApplicationForm',
    defaultMessage: 'Sample of Apply for Benefits Form',
  },
  sampleApplicationConfirmation: {
    id: 'sampleApplicationConfirmation',
    defaultMessage: 'Sample of Apply for Benefits Confirmation',
  },
  orgHome: {
    id: 'orgHome',
    defaultMessage: 'NCDHHS - ePASS',
  },
  nfaccountHome: {
    id: 'nfaccountHome',
    defaultMessage: 'Dashboard',
  },
  docUpload: {
    id: 'docUpload',
    defaultMessage: 'Document Upload',
  },
  nfUploadFilePicker: {
    id: 'nfUploadFilePicker',
    defaultMessage: 'File Picker',
  },
  nfUploadStandaloneFilePicker: {
    id: 'nfUploadStandaloneFilePicker',
    defaultMessage: 'Standalone File Picker',
  },
  nfENUploadStandaloneFilePicker: {
    id: 'nfENUploadStandaloneFilePicker',
    defaultMessage: 'Energy Standalone File Picker',
  },
  nfDocumentToUpload: {
    id: 'nfDocumentToUpload',
    defaultMessage: 'Upload Documents',
  },
  nfdocumentuploadqanda: {
    id: 'nfdocumentuploadqanda',
    defaultMessage: 'Document to Upload Q and A',
  },
  nfSecureInbox: {
    id: 'nfSecureInbox',
    defaultMessage: 'Secure Inbox',
  },
  nfSecureInboxQandA: {
    id: 'nfSecureInboxQandA',
    defaultMessage: 'Secure Inbox Q and A',
  },
  nfeNotices: {
    id: 'nfeNotices',
    defaultMessage: 'eNotices',
  },
  nfeNoticesQandA: {
    id: 'nfeNoticesQandA',
    defaultMessage: 'eNotices FAQs',
  },
  nfTextAndSecureMessagingQandA: {
    id: 'nfTextAndSecureMessagingQandA',
    defaultMessage: 'Text and Secure Messaging FAQs',
  },
  /* Appeal request */
  nfAppealRequestConfirmation: {
    id: 'nfAppealRequestConfirmation',
    defaultMessage: 'Confirmation',
  },
  nfAppealForm: {
    id: 'nfAppealForm',
    defaultMessage: 'Appeal Form',
  },
  nfAppealDetails: {
    id: 'nfAppealDetails',
    defaultMessage: 'Appeal Details',
  },
  nfHeaderAppealsLabel: {
    id: 'nfHeaderAppealsLabel',
    defaultMessage: 'Request an appeal',
  },
  nfAccountSettings: {
    id: 'nfAccountSettings',
    defaultMessage: 'Account Settings',
  },
  nfUpdateSecondaryContact: {
    id: 'nfUpdateSecondaryContact',
    defaultMessage: 'Update Secondary Contact',
  },
  nfAddSecondaryContact: {
    id: 'nfAddSecondaryContact',
    defaultMessage: 'Add Secondary Contact',
  },
  nfUpdatePrimaryContact: {
    id: 'nfUpdatePrimaryContact',
    defaultMessage: 'Update Primary Contact',
  },
  nfConfirmPrimarySecurityCode: {
    id: 'nfConfirmPrimarySecurityCode',
    defaultMessage: 'Confirm Security Code',
  },
  nfRidp: {
    id: 'nfRidp',
    defaultMessage: 'RIDP FAQ',
  },
  nfRidpIEGQA: {
    id: 'nfRidpIEGQA',
    defaultMessage: 'RIDP FAQ',
  },
  nfCaseworkerInfo: {
    id: 'nfCaseworkerInfo',
    defaultMessage: 'Your local DSS office information',
  },
  nfMyInformation: {
    id: 'nfMyInformation',
    defaultMessage: 'Your Information',
  },
  NFUpdateMyInformation: {
    id: 'NFUpdateMyInformation',
    defaultMessage: 'Update Your Information',
  },
  nfStandaloneRidp: {
    id: 'nfStandaloneRidp',
    defaultMessage: 'Enhance your ePASS account',
  },
  nfLearnMore: {
    id: 'nfLearnMore',
    defaultMessage: 'Learn More'
  },
  nfUnableToLogin: {
    id: 'nfUnableToLogin',
    defaultMessage: 'Unable to complete log in',
  },
  nfMFAQuestions: {
    id: 'nfMFAQuestions',
    defaultMessage: 'MFA questions',
  },
  nMFAConfirmCode: {
    id: 'nMFAConfirmCode',
    defaultMessage: 'For your security',
  },
  nfResendCode: {
    id: 'nfResendCode',
    defaultMessage: 'Resend code',
  },
  nfRedirect: {
    id: 'nfRedirect',
    defaultMessage: 'Redirect',
  },
  nfMFASuccess: {
    id: 'nfMFASuccess',
    defaultMessage: 'Success',
  },
  nfCreateNCIDAccount: {
    id: 'nfCreateNCIDAccount',
    defaultMessage: 'Create account',
  },
  nfSignUpHomePage: {
    id: 'nfSignUpHomePage',
    defaultMessage: 'Sign up Redirect',
  },
  nfAccountOptions: {
    id: 'nfAccountOptions',
    defaultMessage: 'Log in',
  },
  nfApplyForAssistanceAnonymousAccountOptions: {
    id: 'nfApplyForAssistanceAnonymousAccountOptions',
    defaultMessage: 'Apply for assistance - Anonymous',
  },
  nfLogin: {
    id: 'nfLogin',
    defaultMessage: 'Log in',
  },
  nfLogout: {
    id: 'nfLogout',
    defaultMessage: 'Log out',
  },
  nMFAIncorrectLogin: {
    id: 'nMFAIncorrectLogin',
    defaultMessage: 'Incorrect login',
  },
  nfMFAError: {
    id: 'nfMFAError',
    defaultMessage: 'Oooops!',
  },
  nfMFARememberDevice: {
    id: 'nfMFARememberDevice',
    defaultMessage: 'Remember my device',
  },
  nfMFATemporarilyLocked: {
    id: 'nfMFATemporarilyLocked',
    defaultMessage: 'Account temporarily locked',
  },
  nfMFAAccountLocked: {
    id: 'nfMFAAccountLocked',
    defaultMessage: 'Account locked',
  },
  nfMFAAccountDelinked: {
    id: 'nfMFAAccountDelinked',
    defaultMessage: 'Account delinked',
  },
  nfApplyForAssistanceOverview: {
    id: 'nfApplyForAssistanceOverview',
    defaultMessage: 'Apply for Assistance Overview',
  },
  nfApplyForAssistanceForm: {
    id: 'nfApplyForAssistanceForm',
    defaultMessage: 'Apply for Assistance Form',
  },
  nfApplyForAssistanceSignAndSubmit: {
    id: 'nfApplyForAssistanceSignAndSubmit',
    defaultMessage: 'Apply for Assistance Sign & Submit',
  },
  nfApplyForAssistanceConfirmation: {
    id: 'nfApplyForAssistanceConfirmation',
    defaultMessage: 'Apply for Assistance Confirmation',
  },
  nfStandaloneRIDPForm: {
    id: 'nfStandaloneRIDPForm',
    defaultMessage: 'Standalone RIDP Form',
  },
  nfStandaloneRDIPFinishPage: {
    id: 'nfStandaloneRDIPFinishPage',
    defaultMessage: 'Standalone RIDP Finish page',
  },
  nfConfirmSecondarySecurityCode: {
    id: 'nfConfirmSecondarySecurityCode',
    defaultMessage: 'Confirm Secondary Security Code',
  },
  nfApplyForAssistanceAnonymousOverview: {
    id: 'nfApplyForAssistanceAnonymousOverview',
    defaultMessage: 'Apply for Assistance Overview - Anonymous',
  },
  nfApplyForAssistanceAnonymousForm: {
    id: 'nfApplyForAssistanceAnonymousForm',
    defaultMessage: 'Apply for Assistance Form - Anonymous',
  },
  nfApplyForAssistanceAnonymousSignAndSubmit: {
    id: 'nfApplyForAssistanceAnonymousSignAndSubmit',
    defaultMessage: 'Apply for Assistance Sign & Submit - Anonymous',
  },
  nfApplyForAssistanceAnonymousConfirmation: {
    id: 'nfApplyForAssistanceAnonymousConfirmation',
    defaultMessage: 'Apply for Assistance Confirmation - Anonymous',
  },
  nfApplyForAssistanceAnonymousExitWarning: {
    id: 'nfApplyForAssistanceAnonymousExitWarning',
    defaultMessage: 'Unable to save - Anonymous',
  },
  nfPresumptiveForm: {
    id: 'nfPresumptiveForm',
    defaultMessage: 'Presumptive Eligibility Form ',
  },
  nfPresumptiveSignAndSubmit: {
    id: 'nfPresumptiveSignAndSubmit',
    defaultMessage: 'Presumptive Eligibility Sign & Submit',
  },
  nfPresumptiveConfirmation: {
    id: 'nfPresumptiveConfirmation',
    defaultMessage: 'Presumptive Eligibility Confirmation ',
  },
  nfCOCOverview: {
    id: 'nfCOC',
    defaultMessage: 'Change of Circumstance Overview',
  },
  nfCOCForm: {
    id: 'nfCOCForm',
    defaultMessage: 'Change of Circumstance Form',
  },
  nfCOCSignAndSubmit: {
    id: 'nfCOCSignAndSubmit',
    defaultMessage: 'Change of Circumstance Sign & Submit',
  },
  nfCOCConfirmation: {
    id: 'nfCOCConfirmation',
    defaultMessage: 'Change of Circumstance Confirmation',
  },
  nfApplicationPending: {
    id: 'nfApplicationPending',
    defaultMessage: 'Application Pending',
  },
  nfYourapplications: {
    id: 'nfYourapplications',
    defaultMessage: 'Your applications',
  },
  nfYourreferrals: {
    id: 'nfYourreferrals',
    defaultMessage: 'Your referrals',
  },
  nfArchivedreferrals: {
    id: 'nfArchivedreferrals',
    defaultMessage: 'Archived referrals',
  },
  nfeNoticesListComm: {
    id: 'nfeNoticesListComm',
    defaultMessage: 'eNotices List Communication',
  },
  nfFAQ: {
    id: 'nfFAQ',
    defaultMessage: 'Frequently asked questions',
  },
  nfLandingPageLearnMore: {
    id: 'nfLandingPageLearnMore',
    defaultMessage: 'Learn more',
  },
  nfApplyingOnBehalfofSomeone: {
    id: 'nfApplyingOnBehalfofSomeone',
    defaultMessage: 'Applying on behalf of someone else',
  },
  nfEpassDisclaimer: {
    id: 'nfEpassDisclaimer',
    defaultMessage: 'ePASS disclaimer',
  },
  nfFNSinfoFactsheet: {
    id: 'nfFNSinfoFactsheet',
    defaultMessage: 'FNS Information Fact Sheet',
  },
  nfBrowserSupportVersions: {
    id: 'nfBrowserSupportVersions',
    defaultMessage: 'Browser Support Information',
  },
  nfNCDHHSAlertsOptIn: {
    id: 'nfNCDHHSAlertsOptIn',
    defaultMessage: 'NCDHHS Alerts Opt In',
  },
  nfNCDHHSAlertsUpdate: {
    id: 'nfNCDHHSAlertsUpdate',
    defaultMessage: 'NCDHHS Alerts Update',
  },
  nflieap: {
    id: 'nflieap',
    defaultMessage: 'Low Income Energy Assistance',
  },
  overviewTitle: {
    id: 'ApplicationOverview_LabelOverviewTitle',
    defaultMessage: 'Overview',
  },
  submissionFormConfirmation: {
    id: 'Routes_submissionFormConfirmation',
    defaultMessage: 'Submission form confirmation',
  },
  nfDisasterAssistance: {
    id: 'nfDisasterAssistance',
    defaultMessage: 'Disaster Assistance',
  },
  submissionFormConfirmationComplete: {
    id: 'Routes_submissionFormConfirmationComplete',
    defaultMessage: 'Submission form confirmation complete',
  },
  applyForBenefitsPageTitle: {
    id: 'ApplyForBenefits_PageTitle',
    defaultMessage: 'Apply for benefits',
  },
  nfApplyForCovidTestingOverview: {
    id: 'nfApplyForCovidTestingOverview',
    defaultMessage: 'Apply for Covid Testing Overview',
  },
  nfApplyForCovidTestingForm: {
    id: 'nfApplyForCovidTestingForm',
    defaultMessage: 'Apply for Covid Testing Form',
  },
  nfApplyForCovidTestingSignAndSubmit: {
    id: 'nfApplyForCovidTestingSignAndSubmit',
    defaultMessage: 'Apply for Covid Testing Sign & Submit',
  },
  nfApplyForCovidTestingConfirmation: {
    id: 'nfApplyForCovidTestingConfirmation',
    defaultMessage: 'Apply for Covid Testing Confirmation',
  },
  nfApplyForCovidTestingExitWarning: {
    id: 'nfApplyForCovidTestingExitWarning',
    defaultMessage: 'Unable to save',
  },
  nfApplyLIEAPAnonymousAccountOptions: {
    id: 'nfApplyLIEAPAnonymousAccountOptions',
    defaultMessage: 'Apply for Energy Assistance anonymous user account options',
  },
  nfApplyLIEAPAnonymousForm: {
    id: 'nfApplyLIEAPAnonymousForm',
    defaultMessage: 'Apply for Energy Assistance anonymous user form',
  },
  nfApplyLIEAPSignAndSubmit: {
    id: 'nfApplyLIEAPSignAndSubmit',
    defaultMessage: 'Apply for Energy Assistance sign and submit',
  },
  nfApplyLIEAPConfirmation: {
    id: 'nfApplyLIEAPConfirmation',
    defaultMessage: 'Apply for Energy Assistance confirmation',
  },
  nfApplyLIEAPExitWarning: {
    id: 'nfApplyLIEAPExitWarning',
    defaultMessage: 'Apply for Energy Assistance exit warning',
  },
  nfApplyLIEAPForm: {
    id: 'nfApplyLIEAPForm',
    defaultMessage: 'Apply for Energy Assistance Form',
  },
  nfDeemedNewbornForm: {
    id: 'nfDeemedNewbornForm',
    defaultMessage: 'Submit Auto Newborn Assistance referral',
  },
  nfDeemedNewbornConfirmation: {
    id: 'nfDeemedNewbornConfirmation',
    defaultMessage: 'Submit Auto Newborn Assistance referral confirmation',
  },
  nfDeemedNewbornSignAndSubmit: {
    id: 'nfDeemedNewbornSignAndSubmit',
    defaultMessage: 'Submit Auto Newborn Assistance referral sign & submit',
  },
  nfMFAAutoNewBornLocked: {
    id: 'nfMFAAutoNewBornLocked',
    defaultMessage: 'Account locked',
  },
  nfRenewFnsOrSnapBenefits: {
    id: 'nfRenewFnsOrSnapBenefits',
    defaultMessage: 'Renew FNS/SNAP benefits',
  },
  nfRenewFnsOrSnapForm: {
    id: 'nfRenewFnsOrSnapForm',
    defaultMessage: 'Renew FNS/SNAP Recert Form',
  },
  nfRenewFnsOrSnapSignAndSubmit: {
    id: 'nfRenewFnsOrSnapSignAndSubmit',
    defaultMessage: 'Sign & Submit',
  },
  nfRenewFnsOrSnapConfirmation: {
    id: 'nfRenewFnsOrSnapConfirmation',
    defaultMessage: 'Confirmation',
  },
  nfSnapCOCForm: {
    id: 'nfSnapCOCForm',
    defaultMessage: 'SNAP COC IEG Form',
  },
  nfSnapCOCSignAndSubmit: {
    id: 'nfSnapCOCSignAndSubmit',
    defaultMessage: 'Sign & Submit',
  },
  nfSnapCOCConfirmation: {
    id: 'nfSnapCOCConfirmation',
    defaultMessage: 'Confirmation',
  },
  nfSecureMessagesOptIn: {
    id: 'nfSecureMessagesOptIn',
    defaultMessage: 'Secure Messages Opt in',
  },
  nfSecureMessagesUpdate: {
    id: 'nfSecureMessagesUpdate',
    defaultMessage: 'Secure Messages Update',
  },
   nfSecureMessages: {
    id: 'nfSecureMessages',
    defaultMessage: 'Secure Messages',
  },
  nfApplyForMedicaidExpansionOverview: {
    id: 'nfApplyForMedicaidExpansionOverview',
    defaultMessage: 'Apply for Medicaid Expansion Overview',
  },
  nfIntermediateCOCOverview: {
    id: 'nfIntermediateCOCOverview',
    defaultMessage: 'Tell us if anything has changed',
  },
  nfFnsOrSnapCOC: {
    id: 'nfFnsOrSnapCOC',
    defaultMessage: 'Report a change for FNS or SNAP',
  },
  nfFNSCOCForm: {
    id: 'nfFNSCOCForm',
    defaultMessage: 'FNS COC IEG Form',
  },
  nfFNSCOCSignAndSubmit: {
    id: 'nfFNSCOCSignAndSubmit',
    defaultMessage: 'Sign & Submit',
  },
  nfFNSCOCConfirmation: {
    id: 'nfFNSCOCConfirmation',
    defaultMessage: 'Confirmation',
  },
  nfFNSorSNAPOnlineRecertFAQ: {
    id: 'nfFNSorSNAPOnlineRecertFAQ',
    defaultMessage: 'FNS/SNAP Online Recertification FAQs',
  },
});
