/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_NF_APPEALS } from './actionTypes';

import { fetchNfAppealsUtil } from './utils';

/**
 * Contains the action creator functions that update the NFAppealsRequest slice of the store.
 *
 * @namespace NFAppealsRequestActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_appeals
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFAppealsRequestActions
   */
  static fetchNfAppeals = (dispatch, callback) => {
    ReduxUtils.generateAction(dispatch, fetchNfAppealsUtil, FETCH_NF_APPEALS, callback);
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfAppeals.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFAppealsRequestActions
   */
  static resetFetchNfAppeals = (dispatch, data) => {
    const json = {
      type: FETCH_NF_APPEALS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfAppeals.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFAppealsRequestActions
   */
  static resetFetchNfAppealsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_APPEALS.failureAction);
  };
}
