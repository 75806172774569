/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewUAMotivationType
 *
 * @class NewUAMotivationType
 */
class NewUAMotivationType {
  /**
   * Creates an instance of NewUAMotivationType.
   * @param {*} json
   * @memberof NewUAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKeyResponse
 *
 * @class NewUAMotivationIEGKeyResponse
 */
class NewUAMotivationIEGKeyResponse {
  /**
   * Creates an instance of NewUAMotivationIEGKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationIEGKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NFMotivationTypeResponse
 *
 * @class NFMotivationTypeResponse
 */
class NFMotivationTypeResponse {
  /**
   * Creates an instance of NFMotivationTypeResponse.
   * @param {*} json
   * @memberof NFMotivationTypeResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationName = json.motivationName;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKey
 *
 * @class NewUAMotivationIEGKey
 */
class NewUAMotivationIEGKey {
  /**
   * Creates an instance of NewUAMotivationIEGKey.
   * @param {*} json
   * @memberof NewUAMotivationIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewNFMotivationFinishResponseResponse
 *
 * @class NewNFMotivationFinishResponseResponse
 */
class NewNFMotivationFinishResponseResponse {
  /**
   * Creates an instance of NewNFMotivationFinishResponseResponse.
   * @param {*} json
   * @memberof NewNFMotivationFinishResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;

      // string
      this.motivationID = json.motivationID;

      // boolean
      this.isARCOC = json.isARCOC;

      // boolean
      this.hasAuthRep = json.hasAuthRep;
    }
  }
}

/**
 * Models the UADraftApplicationProgram
 *
 * @class UADraftApplicationProgram
 */
class UADraftApplicationProgram {
  /**
   * Creates an instance of UADraftApplicationProgram.
   * @param {*} json
   * @memberof UADraftApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UADraftApplication
 *
 * @class UADraftApplication
 */
class UADraftApplication {
  /**
   * Creates an instance of UADraftApplication.
   * @param {*} json
   * @memberof UADraftApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.createdOn = json.createdOn;

      // string
      this.lastUpdatedOn = json.lastUpdatedOn;

      // string
      this.draft_application_id = json.draft_application_id;

      // boolean
      this.isSubmissionForm = json.isSubmissionForm;

      // boolean
      this.isMotivation = json.isMotivation;

      // string
      this.motivationID = json.motivationID;

      // array - UADraftApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UADraftApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UADraftApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the UADraftApplicationList
 *
 * @class UADraftApplicationList
 */
class UADraftApplicationList {
  /**
   * Creates an instance of UADraftApplicationList.
   * @param {*} json
   * @memberof UADraftApplicationList
   */
  constructor(json = null) {
    if (json) {
      // array - UADraftApplication
      if (json.data) {
        this.data = json.data.map(object => new UADraftApplication(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UADraftApplication(object));
      }
    }
  }
}

/**
 * Models the InprogressApplicationDetails
 *
 * @class InprogressApplicationDetails
 */
class InprogressApplicationDetails {
  /**
   * Creates an instance of InprogressApplicationDetails.
   * @param {*} json
   * @memberof InprogressApplicationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.applicationName = json.applicationName;

      // string
      this.createdDate = json.createdDate;

      // string
      this.scriptExecutionID = json.scriptExecutionID;

      // string
      this.intakeApplicationTypeID = json.intakeApplicationTypeID;

      // string
      this.programListXML = json.programListXML;

      // string
      this.resumeApplicationUrl = json.resumeApplicationUrl;

      // string
      this.applicationUrl = json.applicationUrl;

      // string
      this.discardPageURI = json.discardPageURI;

      // string
      this.discardObjectID = json.discardObjectID;
    }
  }
}

/**
 * Models the NFInprogressApplicationDetails
 *
 * @class NFInprogressApplicationDetails
 */
class NFInprogressApplicationDetails {
  /**
   * Creates an instance of NFInprogressApplicationDetails.
   * @param {*} json
   * @memberof NFInprogressApplicationDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfPrintInd = json.nfPrintInd;

      // string
      this.nfPrintApplicationUrl = json.nfPrintApplicationUrl;

      // boolean
      this.nfTraditionalAppInd = json.nfTraditionalAppInd;

      // string
      this.primaryApplicant = json.primaryApplicant;

      // string
      this.programListString = json.programListString;

      // object - InprogressApplicationDetails
      if (json.dtlsInprogress) {
        this.dtlsInprogress = new InprogressApplicationDetails(json.dtlsInprogress);
      }
    }
  }
}

/**
 * Models the SubmittedApplicationDetails
 *
 * @class SubmittedApplicationDetails
 */
class SubmittedApplicationDetails {
  /**
   * Creates an instance of SubmittedApplicationDetails.
   * @param {*} json
   * @memberof SubmittedApplicationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.applicationName = json.applicationName;

      // string
      this.programName = json.programName;

      // string
      this.intakeApplicationID = json.intakeApplicationID;

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.programStatus = json.programStatus;

      // string
      this.applicationUrl = json.applicationUrl;

      // string
      this.programUrl = json.programUrl;

      // boolean
      this.withdrawInd = json.withdrawInd;

      // string
      this.intakeProgramApplicationID = json.intakeProgramApplicationID;

      // string
      this.reference = json.reference;

      // boolean
      this.displayOtherActionInd = json.displayOtherActionInd;

      // string
      this.otherActionURI = json.otherActionURI;
    }
  }
}

/**
 * Models the NFSubmittedApplicationDetails
 *
 * @class NFSubmittedApplicationDetails
 */
class NFSubmittedApplicationDetails {
  /**
   * Creates an instance of NFSubmittedApplicationDetails.
   * @param {*} json
   * @memberof NFSubmittedApplicationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.referenceNumber = json.referenceNumber;

      // string
      this.primaryApplicant = json.primaryApplicant;

      // string
      this.intakeApplicationTypeID = json.intakeApplicationTypeID;

      // boolean
      this.nfLocalizedInd = json.nfLocalizedInd;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.applicationCaseID = json.applicationCaseID;

      // string
      this.caseID = json.caseID;

      // string
      this.attachmentID = json.attachmentID;

      // object - SubmittedApplicationDetails
      if (json.dtlsSubmitted) {
        this.dtlsSubmitted = new SubmittedApplicationDetails(json.dtlsSubmitted);
      }
    }
  }
}

/**
 * Models the NFExistingApplicationDetailsResponse
 *
 * @class NFExistingApplicationDetailsResponse
 */
class NFExistingApplicationDetailsResponse {
  /**
   * Creates an instance of NFExistingApplicationDetailsResponse.
   * @param {*} json
   * @memberof NFExistingApplicationDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isLinkedUserInd = json.isLinkedUserInd;

      // boolean
      this.isDelinkedUserInd = json.isDelinkedUserInd;

      // boolean
      this.displayPDFInd = json.displayPDFInd;

      // array - NFInprogressApplicationDetails
      if (json.inProgAppDtls) {
        this.inProgAppDtls = json.inProgAppDtls.map(
          object => new NFInprogressApplicationDetails(object)
        );
      } else if (json.constructor === Array) {
        this.inProgAppDtls = json.map(object => new NFInprogressApplicationDetails(object));
      }

      // array - NFSubmittedApplicationDetails
      if (json.subAppDtls) {
        this.subAppDtls = json.subAppDtls.map(object => new NFSubmittedApplicationDetails(object));
      } else if (json.constructor === Array) {
        this.subAppDtls = json.map(object => new NFSubmittedApplicationDetails(object));
      }
    }
  }
}

/**
 * Models the CEReadESignatureDetails
 *
 * @class CEReadESignatureDetails
 */
class CEReadESignatureDetails {
  /**
   * Creates an instance of CEReadESignatureDetails.
   * @param {*} json
   * @memberof CEReadESignatureDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.showConsentAccuracyQuestion = json.showConsentAccuracyQuestion;

      // boolean
      this.hasAbsentParent = json.hasAbsentParent;

      // boolean
      this.isAnnualRenewalMotivationType = json.isAnnualRenewalMotivationType;

      // boolean
      this.showConsentUpdateInfoQuestion = json.showConsentUpdateInfoQuestion;

      // boolean
      this.showCooperateMedicalQuestion = json.showCooperateMedicalQuestion;

      // boolean
      this.showCoverageRenewalQuestion = json.showCoverageRenewalQuestion;

      // boolean
      this.showFullNameInputs = json.showFullNameInputs;

      // boolean
      this.showMedicalExpenseQuestion = json.showMedicalExpenseQuestion;

      // boolean
      this.isEligibleForMA = json.isEligibleForMA;
    }
  }
}

/**
 * Models the NFCEReadSignatureDetailsResponse
 *
 * @class NFCEReadSignatureDetailsResponse
 */
class NFCEReadSignatureDetailsResponse {
  /**
   * Creates an instance of NFCEReadSignatureDetailsResponse.
   * @param {*} json
   * @memberof NFCEReadSignatureDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.showUAMedicalAssistanceQuestion = json.showUAMedicalAssistanceQuestion;

      // string
      this.username = json.username;

      // object - CEReadESignatureDetails
      if (json.eSignatureDetails) {
        this.eSignatureDetails = new CEReadESignatureDetails(json.eSignatureDetails);
      }
    }
  }
}

/**
 * Models the NewCESignAndSubmit
 *
 * @class NewCESignAndSubmit
 */
class NewCESignAndSubmit {
  /**
   * Creates an instance of NewCESignAndSubmit.
   * @param {*} json
   * @memberof NewCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.perjuryESignatureInd = json.perjuryESignatureInd;

      // boolean
      this.defaultRenewalInd = json.defaultRenewalInd;

      // boolean
      this.absentParentESignatureInd = json.absentParentESignatureInd;

      // boolean
      this.informationChangedESignatureInd = json.informationChangedESignatureInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.renewalDuration = json.renewalDuration;

      // string
      this.motivation_id = json.motivation_id;

      // string
      this.categoryId = json.categoryId;

      // string
      this.navigatorID = json.navigatorID;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmit
 *
 * @class NewNFCESignAndSubmit
 */
class NewNFCESignAndSubmit {
  /**
   * Creates an instance of NewNFCESignAndSubmit.
   * @param {*} json
   * @memberof NewNFCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfMedicaidTransportationRespInd = json.nfMedicaidTransportationRespInd;

      // boolean
      this.rightsAndResponsibilitiesInd = json.rightsAndResponsibilitiesInd;

      // boolean
      this.nfUAInformationChangedESignatureInd = json.nfUAInformationChangedESignatureInd;

      // boolean
      this.nfMedicaidNoticeOfPrivacyInd = json.nfMedicaidNoticeOfPrivacyInd;

      // boolean
      this.authorizeReleaseInformationInd = json.authorizeReleaseInformationInd;

      // string
      this.iegExecutionID = json.iegExecutionID;

      // string
      this.motivationID = json.motivationID;

      // object - NewCESignAndSubmit
      if (json.signAndSubmitDetails) {
        this.signAndSubmitDetails = new NewCESignAndSubmit(json.signAndSubmitDetails);
      }
    }
  }
}

/**
 * Models the NewCESignAndSubmitResponseResponse
 *
 * @class NewCESignAndSubmitResponseResponse
 */
class NewCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the NewErrorResponse
 *
 * @class NewErrorResponse
 */
class NewErrorResponse {
  /**
   * Creates an instance of NewErrorResponse.
   * @param {*} json
   * @memberof NewErrorResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.message = json.message;

      // string
      this.level = json.level;

      // string
      this.code = json.code;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmitResponseResponse
 *
 * @class NewNFCESignAndSubmitResponseResponse
 */
class NewNFCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewNFCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewNFCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - NewCESignAndSubmitResponseResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new NewCESignAndSubmitResponseResponse(
          json.signAndSubmitResponse
        );
      }

      // array - NewErrorResponse
      if (json.errors) {
        this.errors = json.errors.map(object => new NewErrorResponse(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new NewErrorResponse(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAMotivation
 *
 * @class UAMotivation
 */
class UAMotivation {
  /**
   * Creates an instance of UAMotivation.
   * @param {*} json
   * @memberof UAMotivation
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationId = json.motivationId;

      // string
      this.dataStoreId = json.dataStoreId;

      // string
      this.typeID = json.typeID;

      // object - CodeTableType
      if (json.state) {
        this.state = new CodeTableType(json.state);
      }

      // string
      this.scriptExecutionID = json.scriptExecutionID;

      // string
      this.username = json.username;

      // string
      this.timestamp = json.timestamp;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UAMotivationType
 *
 * @class UAMotivationType
 */
class UAMotivationType {
  /**
   * Creates an instance of UAMotivationType.
   * @param {*} json
   * @memberof UAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NFMotivationDetails
 *
 * @class NFMotivationDetails
 */
class NFMotivationDetails {
  /**
   * Creates an instance of NFMotivationDetails.
   * @param {*} json
   * @memberof NFMotivationDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSubmissionFormInd = json.isSubmissionFormInd;

      // object - UAMotivation
      if (json.motivation) {
        this.motivation = new UAMotivation(json.motivation);
      }

      // object - UAMotivationType
      if (json.motivationType) {
        this.motivationType = new UAMotivationType(json.motivationType);
      }
    }
  }
}

/**
 * Models the CESignAndSubmitResponse
 *
 * @class CESignAndSubmitResponse
 */
class CESignAndSubmitResponse {
  /**
   * Creates an instance of CESignAndSubmitResponse.
   * @param {*} json
   * @memberof CESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the Error
 *
 * @class Error
 */
class Error {
  /**
   * Creates an instance of Error.
   * @param {*} json
   * @memberof Error
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.code = json.code;

      // string
      this.message = json.message;

      // string
      this.message_id = json.message_id;

      // string
      this.level = json.level;
    }
  }
}

/**
 * Models the NFCESignAndSubmitResponse
 *
 * @class NFCESignAndSubmitResponse
 */
class NFCESignAndSubmitResponse {
  /**
   * Creates an instance of NFCESignAndSubmitResponse.
   * @param {*} json
   * @memberof NFCESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - CESignAndSubmitResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new CESignAndSubmitResponse(json.signAndSubmitResponse);
      }

      // array - Error
      if (json.errors) {
        this.errors = json.errors.map(object => new Error(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new Error(object));
      }
    }
  }
}

/**
 * Models the UAWithdrawalRequestReason
 *
 * @class UAWithdrawalRequestReason
 */
class UAWithdrawalRequestReason {
  /**
   * Creates an instance of UAWithdrawalRequestReason.
   * @param {*} json
   * @memberof UAWithdrawalRequestReason
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }
    }
  }
}

/**
 * Models the UAWithdrawalRequestReasonList
 *
 * @class UAWithdrawalRequestReasonList
 */
class UAWithdrawalRequestReasonList {
  /**
   * Creates an instance of UAWithdrawalRequestReasonList.
   * @param {*} json
   * @memberof UAWithdrawalRequestReasonList
   */
  constructor(json = null) {
    if (json) {
      // array - UAWithdrawalRequestReason
      if (json.data) {
        this.data = json.data.map(object => new UAWithdrawalRequestReason(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UAWithdrawalRequestReason(object));
      }
    }
  }
}

/**
 * Models the NewUASignature
 *
 * @class NewUASignature
 */
class NewUASignature {
  /**
   * Creates an instance of NewUASignature.
   * @param {*} json
   * @memberof NewUASignature
   */
  constructor(json = null) {
    if (json) {
      // string
      this.firstName = json.firstName;

      // string
      this.middleName = json.middleName;

      // string
      this.lastName = json.lastName;
    }
  }
}

/**
 * Models the NewUAWithdrawalRequest
 *
 * @class NewUAWithdrawalRequest
 */
class NewUAWithdrawalRequest {
  /**
   * Creates an instance of NewUAWithdrawalRequest.
   * @param {*} json
   * @memberof NewUAWithdrawalRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_id = json.application_id;

      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }

      // string
      this.reasonText = json.reasonText;

      // boolean
      this.electronicallySigned = json.electronicallySigned;

      // string
      this.application_program_id = json.application_program_id;

      // object - NewUASignature
      if (json.signature) {
        this.signature = new NewUASignature(json.signature);
      }
    }
  }
}

/**
 * Models the NewProgramDenialDtls
 *
 * @class NewProgramDenialDtls
 */
class NewProgramDenialDtls {
  /**
   * Creates an instance of NewProgramDenialDtls.
   * @param {*} json
   * @memberof NewProgramDenialDtls
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programDenialID = json.programDenialID;

      // string
      this.intakeProgramApplicationID = json.intakeProgramApplicationID;

      // string
      this.comments = json.comments;

      // string
      this.createdBy = json.createdBy;

      // string
      this.denialDate = json.denialDate;

      // object - CodeTableType
      if (json.reason) {
        this.reason = new CodeTableType(json.reason);
      }

      // integer
      this.versionNo = json.versionNo;
    }
  }
}

/**
 * Models the NewDenyProgramDetails
 *
 * @class NewDenyProgramDetails
 */
class NewDenyProgramDetails {
  /**
   * Creates an instance of NewDenyProgramDetails.
   * @param {*} json
   * @memberof NewDenyProgramDetails
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.intakeProgramVersionNo = json.intakeProgramVersionNo;

      // integer
      this.nfFormDescription = json.nfFormDescription;

      // string
      this.nfSystemDenialReasonCode = json.nfSystemDenialReasonCode;

      // string
      this.nfSystemDenialReasonDesc = json.nfSystemDenialReasonDesc;

      // object - NewProgramDenialDtls
      if (json.denialDtls) {
        this.denialDtls = new NewProgramDenialDtls(json.denialDtls);
      }
    }
  }
}

/**
 * Models the NewWithdrawalRequestDtls
 *
 * @class NewWithdrawalRequestDtls
 */
class NewWithdrawalRequestDtls {
  /**
   * Creates an instance of NewWithdrawalRequestDtls.
   * @param {*} json
   * @memberof NewWithdrawalRequestDtls
   */
  constructor(json = null) {
    if (json) {
      // string
      this.withdrawalRequestID = json.withdrawalRequestID;

      // string
      this.intakeProgramApplicationID = json.intakeProgramApplicationID;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.resolvedDateTime = json.resolvedDateTime;

      // object - CodeTableType
      if (json.withdrawalRequestReason) {
        this.withdrawalRequestReason = new CodeTableType(json.withdrawalRequestReason);
      }

      // string
      this.denialReason = json.denialReason;

      // string
      this.comments = json.comments;

      // object - CodeTableType
      if (json.requestMethod) {
        this.requestMethod = new CodeTableType(json.requestMethod);
      }

      // string
      this.otherWReqReason = json.otherWReqReason;

      // string
      this.firstName = json.firstName;

      // string
      this.middleName = json.middleName;

      // string
      this.surname = json.surname;

      // string
      this.withdrawnBy = json.withdrawnBy;

      // integer
      this.versionNo = json.versionNo;
    }
  }
}

/**
 * Models the NewProgramWithdrawalDetails
 *
 * @class NewProgramWithdrawalDetails
 */
class NewProgramWithdrawalDetails {
  /**
   * Creates an instance of NewProgramWithdrawalDetails.
   * @param {*} json
   * @memberof NewProgramWithdrawalDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.username = json.username;

      // boolean
      this.programWithdrawnOnlineInd = json.programWithdrawnOnlineInd;

      // string
      this.clientFullName = json.clientFullName;

      // string
      this.withdrawalDate = json.withdrawalDate;

      // integer
      this.nfFormDescription = json.nfFormDescription;

      // boolean
      this.nfClientSpecificWithdrawalInd = json.nfClientSpecificWithdrawalInd;

      // object - NewWithdrawalRequestDtls
      if (json.dtls) {
        this.dtls = new NewWithdrawalRequestDtls(json.dtls);
      }
    }
  }
}

/**
 * Models the NewNFDenyWithdrawApplicantDetails
 *
 * @class NewNFDenyWithdrawApplicantDetails
 */
class NewNFDenyWithdrawApplicantDetails {
  /**
   * Creates an instance of NewNFDenyWithdrawApplicantDetails.
   * @param {*} json
   * @memberof NewNFDenyWithdrawApplicantDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.caseID = json.caseID;

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.applicationCaseID = json.applicationCaseID;

      // boolean
      this.electronicSigInd = json.electronicSigInd;

      // object - NewDenyProgramDetails
      if (json.denialDtls) {
        this.denialDtls = new NewDenyProgramDetails(json.denialDtls);
      }

      // object - NewProgramWithdrawalDetails
      if (json.withdrawalDtls) {
        this.withdrawalDtls = new NewProgramWithdrawalDetails(json.withdrawalDtls);
      }
    }
  }
}

/**
 * Models the NFAttachmentGroupDtlsList
 *
 * @class NFAttachmentGroupDtlsList
 */
class NFAttachmentGroupDtlsList {
  /**
   * Creates an instance of NFAttachmentGroupDtlsList.
   * @param {*} json
   * @memberof NFAttachmentGroupDtlsList
   */
  constructor(json = null) {
    if (json) {
      // string
      this.attachmentID = json.attachmentID;

      // array - NFSubmittedApplicationDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFSubmittedApplicationDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFSubmittedApplicationDetails(object));
      }
    }
  }
}

/**
 * Models the NFSubmittedAppDtlsListResponse
 *
 * @class NFSubmittedAppDtlsListResponse
 */
class NFSubmittedAppDtlsListResponse {
  /**
   * Creates an instance of NFSubmittedAppDtlsListResponse.
   * @param {*} json
   * @memberof NFSubmittedAppDtlsListResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isLinkedUserInd = json.isLinkedUserInd;

      // boolean
      this.isDelinkedUserInd = json.isDelinkedUserInd;

      // array - NFAttachmentGroupDtlsList
      if (json.listDtls) {
        this.listDtls = json.listDtls.map(object => new NFAttachmentGroupDtlsList(object));
      } else if (json.constructor === Array) {
        this.listDtls = json.map(object => new NFAttachmentGroupDtlsList(object));
      }
    }
  }
}

/**
 * Models the NewNFSaveAndExitKey
 *
 * @class NewNFSaveAndExitKey
 */
class NewNFSaveAndExitKey {
  /**
   * Creates an instance of NewNFSaveAndExitKey.
   * @param {*} json
   * @memberof NewNFSaveAndExitKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;

      // string
      this.lastIEGPageName = json.lastIEGPageName;
    }
  }
}

/**
 * Models the NewNFSaveAndExitResponseResponse
 *
 * @class NewNFSaveAndExitResponseResponse
 */
class NewNFSaveAndExitResponseResponse {
  /**
   * Creates an instance of NewNFSaveAndExitResponseResponse.
   * @param {*} json
   * @memberof NewNFSaveAndExitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

export default {
  NewUAMotivationType,

  NewUAMotivationIEGKeyResponse,

  NFMotivationTypeResponse,

  NewUAMotivationIEGKey,

  NewNFMotivationFinishResponseResponse,

  UADraftApplicationProgram,

  UADraftApplication,

  UADraftApplicationList,

  InprogressApplicationDetails,

  NFInprogressApplicationDetails,

  SubmittedApplicationDetails,

  NFSubmittedApplicationDetails,

  NFExistingApplicationDetailsResponse,

  CEReadESignatureDetails,

  NFCEReadSignatureDetailsResponse,

  NewCESignAndSubmit,

  NewNFCESignAndSubmit,

  NewCESignAndSubmitResponseResponse,

  NewErrorResponse,

  NewNFCESignAndSubmitResponseResponse,

  CodeTableType,

  UAMotivation,

  UAMotivationType,

  NFMotivationDetails,

  CESignAndSubmitResponse,

  Error,

  NFCESignAndSubmitResponse,

  UAWithdrawalRequestReason,

  UAWithdrawalRequestReasonList,

  NewUASignature,

  NewUAWithdrawalRequest,

  NewProgramDenialDtls,

  NewDenyProgramDetails,

  NewWithdrawalRequestDtls,

  NewProgramWithdrawalDetails,

  NewNFDenyWithdrawApplicantDetails,

  NFAttachmentGroupDtlsList,

  NFSubmittedAppDtlsListResponse,

  NewNFSaveAndExitKey,

  NewNFSaveAndExitResponseResponse,
};
