/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_CITIZEN_WORKSPACE_DOC_UPLOAD_INFO_MSGS } from './actionTypes';

/**
 * Updates the details in the 'NFDocUploadBannerMsgs' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenWorkspaceDocUploadInfoMsgsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_DOC_UPLOAD_INFO_MSGS);

/**
 * Updates the indicator of creation of details in the 'NFDocUploadBannerMsgs' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenWorkspaceDocUploadInfoMsgsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_CITIZEN_WORKSPACE_DOC_UPLOAD_INFO_MSGS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenWorkspaceDocUploadInfoMsgsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_CITIZEN_WORKSPACE_DOC_UPLOAD_INFO_MSGS);

const reducers = combineReducers({
  fetchNfCitizenWorkspaceDocUploadInfoMsgs: fetchNfCitizenWorkspaceDocUploadInfoMsgsReducer,

  isFetchingFetchNfCitizenWorkspaceDocUploadInfoMsgs: isFetchingFetchNfCitizenWorkspaceDocUploadInfoMsgsReducer,
  fetchNfCitizenWorkspaceDocUploadInfoMsgsError: fetchNfCitizenWorkspaceDocUploadInfoMsgsErrorReducer,
});

export default { reducers };
