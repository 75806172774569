/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import actions from './actions';

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_counter/{nfCounterTypeCode}
 *
 * postNfCounterByNfCounterTypeCode - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfCounterByNfCounterTypeCode = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_counter/{nfCounterTypeCode}
   *
   * @param {string} nfCounterTypeCode.
   * @param {NewNFCounterRequest} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfCounterByNfCounterTypeCode = useCallback(
    (nfCounterTypeCode, newNFCounterRequest) => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfCounterByNfCounterTypeCode(
          dispatch,
          nfCounterTypeCode,
          newNFCounterRequest,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfCounterByNfCounterTypeCode,
  };
};

export default {
  usePostNfCounterByNfCounterTypeCode,
};
