/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/nf_coc/start_coc_script
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfCocStartCocScriptUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_coc/start_coc_script`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCOCFormIdResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFCOCConfigResponse from api:
 * /v1/ua/nf_coc/apply_for_snap_recert
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocApplyForSnapRecertUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/apply_for_snap_recert`,
    models.NFCOCConfigResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_coc/finish_coc_recert
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfCocFinishCocRecertUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_coc/finish_coc_recert`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCOCFinishResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_coc/signandsubmit_coc_recert
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfCocSignandsubmitCocRecertUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_coc/signandsubmit_coc_recert`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCOCSignAndSubmitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFCOCSignAndSubmitResponse from api:
 * /v1/ua/nf_coc/coc_recert_confirmation_details/{cocInstanceID}
 *
 * @param {string} cocInstanceID.
 * @param {any} callback function to call once this function has finished
 */
const getNfCocCocRecertConfirmationDetailsByCocInstanceIDUtil = (cocInstanceID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/coc_recert_confirmation_details/${cocInstanceID}`,
    models.NFCOCSignAndSubmitResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/setup_start_motivation
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationSetupStartMotivationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/setup_start_motivation`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFFNSSNAPRenewalsAndCOCDetailsResponse from api:
 * /v1/ua/nf_coc/list_submitted_renewals
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocListSubmittedRenewalsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/list_submitted_renewals`,
    models.NFFNSSNAPRenewalsAndCOCDetailsResponse
  );
};

export {
  postNfCocStartCocScriptUtil,
  fetchNfCocApplyForSnapRecertUtil,
  postNfCocFinishCocRecertUtil,
  postNfCocSignandsubmitCocRecertUtil,
  getNfCocCocRecertConfirmationDetailsByCocInstanceIDUtil,
  postNfMotivationSetupStartMotivationUtil,
  fetchNfCocListSubmittedRenewalsUtil,
};
