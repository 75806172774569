/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFMFASession.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_NFMFA_GET_MFA_QUESTIONS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMFASESSION/POST_NFMFA_GET_MFA_QUESTIONS`
);

const POST_NFMFA_SEND_MFA_CODE = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMFASESSION/POST_NFMFA_SEND_MFA_CODE`
);

const POST_NFMFA_RESEND_MFA_CODE = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFMFASESSION/POST_NFMFA_RESEND_MFA_CODE`
);

export { POST_NFMFA_GET_MFA_QUESTIONS, POST_NFMFA_SEND_MFA_CODE, POST_NFMFA_RESEND_MFA_CODE };
