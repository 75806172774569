/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFDeemedNewBornMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED`
);

const FETCH_NF_MOTIVATION_DEEMED_NEW_BORN = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_MOTIVATION_DEEMED_NEW_BORN`
);

const POST_MOTIVATION_SETUP_AND_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/POST_MOTIVATION_SETUP_AND_START`
);

const GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID`
);

const POST_NF_MOTIVATION_FINISH_MOTIVATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/POST_NF_MOTIVATION_FINISH_MOTIVATION`
);

const GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID`
);

const GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID`
);

const POST_NF_MOTIVATION_SIGN_AND_SUBMIT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/POST_NF_MOTIVATION_SIGN_AND_SUBMIT`
);

const DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID = ReduxUtils.generateActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID`,
  'DELETE'
);

const FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED`
);

const POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS`
);

const FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS`
);

const FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS`
);

const FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFDEEMEDNEWBORNMOTIVATION/FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES`
);

export {
  FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED,
  FETCH_NF_MOTIVATION_DEEMED_NEW_BORN,
  POST_MOTIVATION_SETUP_AND_START,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED,
  POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES,
};
