/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux';
import { CoreReduxActions, CoreReduxReducers, AuthenticationActionTypes } from '@spm/core';
import { UAReduxReducers } from '@spm/universal-access';
import { IEGReduxReducers } from '@spm/intelligent-evidence-gathering';
import { UAGeneratedReduxReducers } from '../modules/generated';

import {
  nfUpdateEmailReducer, nfToggleEmailReducer, nfSecureInboxReducer, nfIsFetchingSecureInboxDataReducer, nfIsFetchingUpdateEmailReducer, nfGetPrimaryContactDetailsReducer, nfIsFetchingGetEmailReducer, nfIsFetchingConfirmEmailReducer, nfConfirmEmailReducer,
  nfMarkCommunicationToSendByMailReducer,
  nfSubscribeToENoticesReducer,
  nfUnSubscribeFromENoticesReducer,
  nfIsFetchingMarkCommunicationToSendByMailReducer,
  nfIsFetchingSubscribeToENoticesReducer,
  nfIsFetchingUnSubscribeFromENoticesReducer,
  nfIsSubscribedToENoticesReducer,
  nfIsFetchingIsSubscribedToENoticesReducer,
  nfToggleSubscriptionReducer,
  nfConfirmENoticesSubscriptionReducer,
  nfIsFetchingConfirmENoticesSubscriptionReducer,
} from '../features/NFSecureInbox/nfReducers';

const AppReducers = {
  nfSecureInboxReducer,
  nfIsFetchingSecureInboxDataReducer,
  nfToggleEmailReducer,
  nfUpdateEmailReducer,
  nfIsFetchingUpdateEmailReducer,
  nfGetPrimaryContactDetailsReducer,
  nfIsFetchingGetEmailReducer,
  nfIsFetchingConfirmEmailReducer,
  nfConfirmEmailReducer,
  nfMarkCommunicationToSendByMailReducer,
  nfSubscribeToENoticesReducer,
  nfUnSubscribeFromENoticesReducer,
  nfIsFetchingMarkCommunicationToSendByMailReducer,
  nfIsFetchingSubscribeToENoticesReducer,
  nfIsFetchingUnSubscribeFromENoticesReducer,
  nfIsSubscribedToENoticesReducer,
  nfIsFetchingIsSubscribedToENoticesReducer,
  nfToggleSubscriptionReducer,
  nfConfirmENoticesSubscriptionReducer,
  nfIsFetchingConfirmENoticesSubscriptionReducer,
};

/**
 * Combines the App reducers with those provided by the universal-access package
 */
const appReducer = combineReducers({
  ...AppReducers,
  ...CoreReduxReducers,
  ...UAReduxReducers,
  ...IEGReduxReducers,
  ...UAGeneratedReduxReducers,
});

/**
 * Returns the rootReducer for the Redux store.
 * @param {*} state
 * @param {*} action
 */
const rootReducer = (state, action = { type: 'unknown' }) => {
  // If a logout action was dispatched, delete the entire contents of the store.
  // This is important for security to ensure that user data is not left in the
  // store and accessible through the developer tools after a user has logged
  // out.

  // If a logout action was dispatched, delete the entire contents of the store.
  // This is important for security to ensure that user data is not left in the
  // store and accessible through the Browser tools after a user has logged
  // out.
  if (action.type === AuthenticationActionTypes.LOGOUT) {
    const resetAction = CoreReduxActions.resetConfiguration(
      state.UAIntlConfig,
      state.UAUserPreferences
    );

    // eslint-disable-next-line no-param-reassign
    state = undefined;
    return rootReducer(state, resetAction);
  }
  return appReducer(state, action);
};

export default rootReducer;
