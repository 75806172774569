/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFSecureMessagesInfo' store object.
 * @namespace NFSecureMessagesInfoSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFSecureMessagesInfo';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static fetchNfTextMessageSubscriptionuaGetPersonInformation = state =>
    state[selectors.moduleIdentifier].fetchNfTextMessageSubscriptionuaGetPersonInformation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static isFetchingFetchNfTextMessageSubscriptionuaGetPersonInformation = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfTextMessageSubscriptionuaGetPersonInformation
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static fetchNfTextMessageSubscriptionuaGetPersonInformationError = state =>
    state[selectors.moduleIdentifier].fetchNfTextMessageSubscriptionuaGetPersonInformationError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount = state =>
    state[selectors.moduleIdentifier].fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static isFetchingFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount = state =>
    state[selectors.moduleIdentifier]
      .isFetchingFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountError = state =>
    state[selectors.moduleIdentifier].fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaCreateTextMessageSubscription = state =>
    state[selectors.moduleIdentifier].postNfTextMessageSubscriptionuaCreateTextMessageSubscription
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static isFetchingPostNfTextMessageSubscriptionuaCreateTextMessageSubscription = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfTextMessageSubscriptionuaCreateTextMessageSubscription.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionError = state =>
    state[selectors.moduleIdentifier]
      .postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaUpdateTextMessageSubscription = state =>
    state[selectors.moduleIdentifier].postNfTextMessageSubscriptionuaUpdateTextMessageSubscription
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static isFetchingPostNfTextMessageSubscriptionuaUpdateTextMessageSubscription = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfTextMessageSubscriptionuaUpdateTextMessageSubscription.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionError = state =>
    state[selectors.moduleIdentifier]
      .postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaUnsubscribeTextMessage = state =>
    state[selectors.moduleIdentifier].postNfTextMessageSubscriptionuaUnsubscribeTextMessage.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static isFetchingPostNfTextMessageSubscriptionuaUnsubscribeTextMessage = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfTextMessageSubscriptionuaUnsubscribeTextMessage.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSecureMessagesInfoSelectors
   */
  static postNfTextMessageSubscriptionuaUnsubscribeTextMessageError = state =>
    state[selectors.moduleIdentifier].postNfTextMessageSubscriptionuaUnsubscribeTextMessageError
      .payload;
}
