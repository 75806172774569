/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const Paths = {
  NF_MFA_SESSION: {
    NFLEARNMORE: '/nfLearnMore',
    NFMFARESENDCODE: '/nfResendCode',
    NFMFAREDIRECT: '/nfRedirect',
    NFMFASUCCESS: '/nfMFASuccess',
    NFMFAQUESTIONS: '/nfMFAQuestions',
    NFMFACONFIRMCODE: '/nfMFAConfirmCode',
    NFMFAINCORRECTLOGIN: '/nfMFAIncorrectLogin',
    NFMFAERROR: '/nfMFAError',
    NFMFAREMEMBERDEVICE: '/nfMFARememberDevice',
    NFMFATEMPORARILYLOCKED: '/nfMFATemporarilyLocked',
    NFMFAACCOUNTLOCKED: '/nfMFAAccountLocked',
    NFMFAACCOUNTDELINKED: '/nfMFAAccountDelinked',
    NFMFAUNABLELOGIN: '/nfUnableToLogin',
    NFMFAAUTONEWBORNLOCKED:'/nfMFAAutoNewBornLocked'
  },
  NFCREATENCIDACCOUNT: '/nfCreateNCIDAccount',
  NFACCOUNTOPTIONS: '/nfAccountOptions',
  NFCOMPLETE: '/nfComplete',
  NFLOGIN: '/nfLogin',
  NFLOGOUT: '/nfLogout',
  SAMPLE_APPLICATION: {
    ROOT: '/sample-application',
    OVERVIEW: '/sample-application/overview',
    FORM: '/sample-application/form',
    CONFIRMATION: '/sample-application/confirmation'
  },
  NFACCOUNTHOME: '/account',
  NFSIGNUPHOMEPAGE: '/signuphomepage',
  BENEFITS: '/benefits',
  NFYOURAPPLICATIONS: '/your-applications',
  NFYOURREFERRALS: '/your-referrals',
  NFARCHIVEDREFERRALS: '/archived-referrals',
  PAYMENTS: {
    ROOT: '/payments',
    DETAILS: '/payments/details'
  },
  APPEALS: {
    ROOT: '/appeals',
    FORM: '/appeals/form/new',
    RESUME: '/appeals/form',
    OVERVIEW: '/appeals/overview',
    SUBMITTED: '/appeals/submitted'
  },
  NOTICES: {
    ROOT: '/notices',
    DETAILS: '/notices/details'
  },
  LIFE_EVENTS: {
    ROOT: '/life-events',
    FORM: '/life-events/form/new',
    RESUME: '/life-events/form',
    HISTORY: '/life-events/history',
    OVERVIEW: '/life-events/overview'
  },
  DOCUPLOAD: '/docUpload',
  NFDOCUMENTTOUPLOAD: '/nfDocumentToUpload',
  NFDOCUMENTTOUPLOADQANDA: '/nfDocumentUploadQAndA',
  NFSECUREINBOX: '/nfSecureInbox',
  NFSECUREINBOXQANDA: '/nfSecureInboxQandA',
  NFCASEWORKERINFO: '/nfCaseworkerInfo',
  NFUPDATE_SECONDARY_CONTACT: '/NFAccountSettings/UpdateSecondaryContact',
  NFADD_SECONDARY_CONTACT: '/NFAccountSettings/AddSecondaryContact',
  NFAPPEALS: {
    ROOT: '/nfAppeals',
    FORM: '/nfAppeals/form/new',
    RESUME: '/nfAppeals/form',
    SUBMITTED: '/nfAppeals/submitted'
  },
  NF_APPLY_FOR_ASSISTANCE_MOTIVATION: {
    OVERVIEW: '/nf-apply-for-assistance/overview',
    FORM: '/nf-apply-for-assistance/form',
    SIGN_AND_SUBMIT: '/nf-apply-for-assistance/sign-and-submit',
    CONFIRMATION: '/nf-apply-for-assistance/confirmation'
  },
  NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION: {
    NFACCOUNTOPTIONS: '/nf-apply-for-assistance-anonymous/nfAccountOptions',
    OVERVIEW: '/nf-apply-for-assistance-anonymous/overview',
    FORM: '/nf-apply-for-assistance-anonymous/form',
    SIGN_AND_SUBMIT: '/nf-apply-for-assistance-anonymous/sign-and-submit',
    CONFIRMATION: '/nf-apply-for-assistance-anonymous/confirmation',
    EXIT_WARNING: '/nf-apply-for-assistance-anonymous/exit-warning'
  },
  NF_LIEAP: {
    NFACCOUNTOPTIONS: '/nflieap/nfAccountOptions',
    FORM: '/nflieap/form',
    SIGN_AND_SUBMIT: '/nflieap/sign-and-submit',
    CONFIRMATION: '/nflieap/confirmation',
    EXIT_WARNING: '/nflieap/exit-warning',
    ANONYMOUS_FORM: '/nflieap/anonymous-form'
  },
  HOME: '/',
  ACCOUNT: '/account',
  LOGIN: '/login',
  APPLY: '/apply',
  APPLICATION_OVERVIEW: '/application-overview',
  NFACCOUNTSETTINGS: '/NFAccountSettings',
  NFUPDATE_PRIMARY_CONTACT: '/NFAccountSettings/updatePrimaryContact',
  NFCONFIRM_PRIMARY_SECURITY_CODE: '/NFAccountSettings/securitycode',
  NFCONFIRM_SECONDARY_SECURITY_CODE: '/NFAccountSettings/secondarysecuritycode',
  NFRIDP: '/nfridp/qa',
  NFRIDPQAIEG: '/nf-apply-ridp/qa',
  NFSTANDALONERIDP: '/nfUpgradeAccount',
  NF_STANDALONE_RIDP_MOTIVATION: {
    FORM: '/nf-standalone-ridp/form',
    FINISH_PAGE: '/account'
  },
  NF_COC_MOTIVATION: {
    FORM: '/nf-change-of-circumstance/form',
    SIGN_AND_SUBMIT: '/nf-change-of-circumstance/sign-and-submit',
    CONFIRMATION: '/nf-change-of-circumstance/confirmation'
  },
  NF_PRESUMPTIVE_MOTIVATION: {
    FORM: '/nf-presumptive/form',
    FINISH_PAGE: '/account',
    CONFIRMATION: '/nf-presumptive/confirmation'
  },
  NF_APPLICATION_PENDING: '/nf_application_pending',
  MY_INFO: '/nf-my-information',
  UPDATE_INFO: '/nf-update-my-information',
  UPDATE_INFO_FNS_OR_SNAP_COC: '/nf-fns-or-snap-coc',
  NFENOTICES: '/nf-enotices',
  NFENOTICESQANDA: '/nf-enotices-FAQ',
  NFTEXTANDSECUREMESSAGINGQANDA: '/nf-textAndSecure-FAQ',
  NFENOTICESLISTCOMM: {
    HOME: '/nf-enotices-comm',
    DETAIL: '/nf-enotices-comm/:commId',
    ID: '/nf-enotices-comm/:id'
  },
  NFFAQ: '/nf-FAQ',
  NFLANDINGPAGELEARNMORE: '/nf-landing-page-learn-more',
  NFAPPLYINGONBEHALFOFSOMEONE: '/nf-applying-on-behalf-of-someone',
  NFEPASSDISCLAIMER: '/nf-epass-disclaimer',
  NFFNSINFOFACTSHEET: '/nf-fns-info-fact-sheet',
  NFBROWSERSUPPORTVERSION: '/nf-browser-supported-versions',
  NFNCDHHS_ALERTS_OPTIN: '/nf-ncdhhs-alerts-opt-in',
  NFNCDHHS_ALERTS_UPDATE: '/nf-ncdhhs-alerts-update',
  NFLIEAP: '/nf-lieap',
  NFDISASTER_APPLY: '/nf-apply-for-disaster',
  NF_APPLY_FOR_COVID_TESTING_MOTIVATION: {/*
    OVERVIEW: '/nf-apply-for-covid-testing/overview',
    FORM: '/nf-apply-for-covid-testing/form',
    SIGN_AND_SUBMIT: '/nf-apply-for-covid-testing/sign-and-submit',
    CONFIRMATION: '/nf-apply-for-covid-testing/confirmation',
    EXIT_WARNING: '/nf-apply-for-covid-testing/exit-warning',*/
  },
  NF_APPLY_FOR_MXP_MOTIVATION: {
    OVERVIEW: '/nf-apply-for-medicaid-expansion/overview',
  },
  NF_DEEMED_NEW_BORN_MOTIVATION: {
    FORM: '/nf-submit-auto-new-born-assistance/form',
    CONFIRMATION: '/nf-submit-auto-new-born-assistance/confirmation',
    SIGN_AND_SUBMIT: '/nf-submit-auto-new-born-assistance/sign-and-submit',
  },
  NF_FNS_OR_SNAP_BENEFITS:'/nf-fns-or-snap-benefits',
  NF_FNS_OR_SNAP_RECERT_MOTIVATION: {
    FORM: '/nf-renew-fns-or-snap-recert/form',
    SIGN_AND_SUBMIT: '/nf-renew-fns-or-snap-recert/sign-and-submit',
    CONFIRMATION: '/nf-renew-fns-or-snap-recert/confirmation'
  },
  NF_SNAP_COC_MOTIVATION:{
    FORM: '/nf-snap-coc/form',
    SIGN_AND_SUBMIT: '/nf-snap-coc/sign-and-submit',
    CONFIRMATION: '/nf-snap-coc/confirmation'
  },
  NF_FNS_COC_MOTIVATION:{
    FORM: '/nf-fns-coc/form',
    SIGN_AND_SUBMIT: '/nf-fns-coc/sign-and-submit',
    CONFIRMATION: '/nf-fns-coc/confirmation'
  },
  NF_SECURE_MSG_OPT_IN: '/nf-secure-message-opt-in',
  NF_SECURE_MSG_UPDATE: '/nf-secure-message-update',
  NFSECUREMESSAGES: '/nf-secure-messages',
  NF_INTERMEDIATE_COC_OVERVIEW: '/nf-intermediate-coc-overview',
  NF_FNS_OR_SNAP_ONLINE_RECERT_FAQ: '/nf-fns-or-snap-online-recert-FAQ'
};

/**
 * Provides access the the URLs that are mapped to each page via the route configuration.
 */
export default Paths;
